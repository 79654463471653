import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CreditReferenceService } from '../../providers/credit-reference.service';
import { MatSnackBar } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';

import { default as _rollupMoment } from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ConfirmationDialog } from '../addmember/addmember.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
const moment = _rollupMoment || _moment;

export interface CreditIndexData {
  "refComponents": any,
  "indexType": string,
  "indexName": string,
  "indexFamily": string,
  "indexCompany": string,
  "series": string,
  "version": string,
  "firstCoupnDate": number,
  "maturityTenor": string,
  "indexFactor": number,
  "coupon": number,
  "currency": string,
  "contracts": any,
  "futureContracts": any,
  "trancheContracts": any,
  "spread": boolean
}


@Component({
  selector: 'app-creditindex',
  templateUrl: './creditindex.component.html',
  styleUrls: ['./creditindex.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CreditindexComponent implements OnInit {

  displayedColumns: string[] = ['indexType', 'indexName', 'indexFamily', 'indexCompany', 'maturityTenor', 'currency', 'action'];
  dataSource: MatTableDataSource<CreditIndexData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  addCreditIndex: FormGroup;

  AuctionDate = new Date();

  NotificationDate = new Date();

  selectedItems = [];
  selectedItems1 = [];

  selectedItems2 = [];
  selectedItems3 = [];
  selectedRefcomponents: any = [];
  seldfutContracts: any = [];
  seldContracts: any = [];
  seldtrancheContract: any = [];
  dropdownSettings = {};

  refComponents = [
    { view: 'Senior Secured', value: 'senior_secure' },
    { view: 'Senior Unsecured', value: 'senior_unsecure' },
    { view: 'Subordinated', value: 'subordinated' }
  ]

  referenceEntity = [
    { view: '3M Co', value: '3m_co' },
    { view: 'American Express Co', value: 'american_express_co' },
    { view: 'Apple Inc', value: 'apple_inc' },
    { view: 'Boeing Co', value: 'boeing_co' },
    { view: 'Caterpillar Inc', value: 'caterpillar_inc' },
    { view: 'Chevron Corp', value: 'chevron_corp' },
    { view: 'Cisco Systems Inc', value: 'cisco_systems_inc' },
    { view: 'Coca-Cola Co', value: 'coca_cola_co' },
    { view: 'E I du Pont de Nemours and Co', value: 'e_i_du_pont_de_nemours_and_co' },
    { view: 'Exxon Mobil Corp', value: 'exxon_mobil_corp' },
    { view: 'General Electric Co', value: 'general_electric_co' },
    { view: 'Goldman Sachs Group Inc', value: 'goldman_sachs_group_inc' },
    { view: 'Home Depot Inc', value: 'home_depot_inc' },
    { view: 'Intel Corp', value: 'intel_corp' },
    { view: 'International Business Machines Corp', value: 'international_business_machines_corp' },
    { view: 'Johnson & Johnson', value: 'johnson_and_johnson' },
    { view: 'JPMorgan Chase & Co', value: 'jpmorgan_chase_and_co' },
    { view: "McDonald's Corp", value: 'mcdonalds_corp' },
    { view: 'Merck & Co Inc', value: 'merck_and_co_inc' },
    { view: 'Microsoft Corp', value: 'microsoft_corp' },
    { view: 'Nike Inc', value: 'nike_inc' },
    { view: 'Pfizer Inc', value: 'pfizer_inc' },
    { view: 'Procter & Gamble Co', value: 'procter_and_gamble_co' },
    { view: 'Travelers Companies Inc', value: 'travelers_companies_inc' },
    { view: 'United Technologies Corp', value: 'united_technologies_corp' },
    { view: 'Verizon Communications Inc', value: 'verizon_communications_inc' },
    { view: 'Wal-Mart Stores Inc', value: 'walmart_stores_inc' },
    { view: 'Walt Disney Co', value: 'walt_disney_co' }
  ]
  selected = 0;

  creditrefData = [];

  pageType = 'new';

  creditindexObj;

  constructor(public formBuilder: FormBuilder, public matSnackBar: MatSnackBar, public creditReferenceService: CreditReferenceService, public dialog: MatDialog, public datePipe: DatePipe) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'view',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.getReferenceData()

    this.addCreditIndex = this.formBuilder.group({
      type: ['', Validators.required],
      first_coupon_date: [this.AuctionDate, Validators.required],
      name: ['CDX NA IG S6', Validators.required],
      maturity: [this.NotificationDate, Validators.required],
      family: ['IG', Validators.required],
      tenor: ['5', Validators.required],
      company: ['CDX NA', Validators.required],
      currency: ['', Validators.required],
      series: ['6', Validators.required],
      version: ['11', Validators.required],
      refcomponent: [[], Validators.required],
      futureContracts: [[], Validators.required],
      contracts: [[], Validators.required],
      tranchecontracts: [[], Validators.required],
      coupon: ['100', Validators.required],
      factor: ['1.0', Validators.required],


      is_sprad: [true, Validators.required]
    })

  }

  getReferenceData() {
    this.creditReferenceService.getCreditIndex().subscribe(res => {
      if (res.length > 0) {
        this.creditrefData = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.creditrefData = [];
        this.dataSource = new MatTableDataSource(this.creditrefData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // onItemSelect(ev) {
  //   this.selectedItems.push(ev.value);
  // }

  // onSelectAll(ev) {

  // }

  // onItemSelect1(ev) {
  //   console.log(ev)
  //   this.selectedItems1.push(ev.value);

  // }

  // onSelectAll1(ev) {

  // }


  // onItemSelect2(ev) {
  //   this.selectedItems2.push(ev.value);

  // }

  // onSelectAll2(ev) {

  // }


  // onItemSelect3(ev) {
  //   this.selectedItems3.push(ev.value);

  // }

  // onSelectAll3(ev) {

  // }

  changeTab(ev) {
    // console.log(ev)
    this.selected = ev;
  }


  savecreditindex() {
    this.seldfutContracts = [];
    this.selectedRefcomponents = [];
    this.seldContracts = [];
    this.seldtrancheContract = [];
    this.selectedItems.forEach(eachref => {
      this.selectedRefcomponents.push(eachref.value)
    });

    this.selectedItems1.forEach(eachfut => {
      this.seldfutContracts.push(eachfut.value)
    })

    this.selectedItems2.forEach(eachItem => {
      this.seldContracts.push(eachItem.value)
    })

    this.selectedItems3.forEach(eachItm => {
      this.seldtrancheContract.push(eachItm.value)
    })

    if (this.addCreditIndex.valid) {
      let reqObj = {
        "contracts": this.seldContracts,
        "coupon": this.addCreditIndex.value.coupon,
        "currency": this.addCreditIndex.value.currency,
        "firstCoupnDate": this.datePipe.transform(this.addCreditIndex.value.first_coupon_date, 'MM-dd-yyyy'),
        "futureContracts": this.seldfutContracts,
        "indexCompany": this.addCreditIndex.value.company,
        "indexFactor": this.addCreditIndex.value.factor,
        "indexFamily": this.addCreditIndex.value.family,
        "indexName": this.addCreditIndex.value.name,
        "indexType": this.addCreditIndex.value.type,
        "maturityDate":this.datePipe.transform(this.addCreditIndex.value.maturity, 'MM-dd-yyyy'),
        "maturityTenor": this.addCreditIndex.value.tenor,
        "refComponents": this.selectedRefcomponents,
        "series": this.addCreditIndex.value.series,
        "spread": this.addCreditIndex.value.is_sprad,
        "trancheContracts": this.seldtrancheContract,
        "version": this.addCreditIndex.value.version
      }

      this.creditReferenceService.addCreditIndex(reqObj).subscribe(res => {
        if (res.status == 'ok') {
          this.matSnackBar.open('Credit Index added successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.getReferenceData()
          this.fromGroupDirective.resetForm();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  deletecredit(credit) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.creditReferenceService.deleteCreditRefData(credit.indexId).subscribe(res => {

        })
      }
    });
  }

  upcontracts: any = [];
  refComp: any = [];
  futContract: any = [];
  trancheContract: any = [];
  updatecreditindex() {
    this.upcontracts = [];
    this.refComp = [];
    this.futContract = [];
    this.trancheContract = [];
    this.selectedItems2.forEach(element => {
      if (!element.value) {
        this.upcontracts.push(element)
      } else {
        this.upcontracts.push(element.value)
      }
    });

    this.selectedItems.forEach(element => {
      if (!element.value) {
        this.refComp.push(element)
      } else {
        this.refComp.push(element.value)
      }
    });

    this.selectedItems1.forEach(element => {
      if (!element.value) {
        this.futContract.push(element)
      } else {
        this.futContract.push(element.value)
      }
    });

    this.selectedItems3.forEach(element => {
      if (!element.value) {
        this.trancheContract.push(element)
      } else {
        this.trancheContract.push(element.value)
      }
    });


    if (this.addCreditIndex.valid) {
      let reqObj = {
        "contracts": this.upcontracts,
        "coupon": this.addCreditIndex.value.coupon,
        "currency": this.addCreditIndex.value.currency,
        "firstCoupnDate": this.datePipe.transform(this.addCreditIndex.value.first_coupon_date, 'MM-dd-yyyy'),
        "futureContracts": this.futContract,
        "indexCompany": this.addCreditIndex.value.company,
        "indexFactor": this.addCreditIndex.value.factor,
        "indexFamily": this.addCreditIndex.value.family,
        "indexName": this.addCreditIndex.value.name,
        "indexType": this.addCreditIndex.value.type,
        "maturityDate":this.datePipe.transform(this.addCreditIndex.value.maturity, 'MM-dd-yyyy'),
        "maturityTenor": this.addCreditIndex.value.tenor,
        "refComponents": this.refComp,
        "series": this.addCreditIndex.value.series,
        "spread": this.addCreditIndex.value.is_sprad,
        "trancheContracts": this.trancheContract,
        "version": this.addCreditIndex.value.version,
        "indexId": this.creditindexObj.indexId
      }

      this.creditReferenceService.editCreditIndex(reqObj).subscribe(res => {
        if (res.status == 'ok') {
          this.matSnackBar.open('Credit Index updated successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.getReferenceData()
          this.pageType="new";
          this.fromGroupDirective.resetForm();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  editcreditindex(creditindexObj) {
    console.log(creditindexObj)
    this.selected = 0;
    this.pageType = 'edit';
    this.creditindexObj = creditindexObj;
    let ref = this.creditindexObj.refComponents
    let futConts = this.creditindexObj.futureContracts
    let Conts = this.creditindexObj.contracts
    let tranConts = this.creditindexObj.trancheContracts
    this.addCreditIndex = this.formBuilder.group({
      type: [creditindexObj.indexType, Validators.required],
      first_coupon_date: [moment(creditindexObj.firstCoupnDate)],
      name: [creditindexObj.indexName],
      maturity: [moment(creditindexObj.maturityDate)],
      family: [creditindexObj.indexFamily],
      tenor: [creditindexObj.maturityTenor, Validators.required],
      company: [creditindexObj.indexCompany],
      currency: [creditindexObj.currency],
      series: [creditindexObj.series],
      version: [creditindexObj.version],
      coupon: [creditindexObj.coupon],
      refcomponent: [this.addCreditIndex.get('refcomponent').setValue(ref)],
      futureContracts: [this.addCreditIndex.get('futureContracts').setValue(futConts)],
      contracts: [this.addCreditIndex.get('contracts').setValue(Conts)],
      tranchecontracts: [this.addCreditIndex.get('tranchecontracts').setValue(tranConts)],
      factor: [creditindexObj.indexFactor],
      is_sprad: [creditindexObj.spread]
    })

  }



}
