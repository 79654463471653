import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RecoverylockService } from '../../providers/recoverylock.service';
import { MatSnackBar } from '@angular/material';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdsService } from '../../providers/cds.service'

import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

import { default as _rollupMoment } from 'moment';
import { CreditReferenceService } from 'src/providers/credit-reference.service';

const moment = _rollupMoment || _moment;

export interface RecoveryData {
  id: string;
  memberId: string;
  instrument: any;
  risk: any;
  deal: any;
  fee: any;
}

@Component({
  selector: 'app-cds',
  templateUrl: './cds.component.html',
  styleUrls: ['./cds.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CdsComponent implements OnInit {

  imgstyle="ForImageIcon"

  displayedColumns: string[] = ['dealid', 'creditSeniority', 'paymentDelay', 'buySell', 'action'];
  dataSource: MatTableDataSource<RecoveryData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  instrumentForm: FormGroup;

  dealForm: FormGroup;

  feeForm: FormGroup;

  coupon = 0;

  tradespread = 0;

  qty = 0;


  currency = 'usd';

  selected = 0;

  startDate = new Date();

  recoveryLockList = [];

  viewDeal;

  view_more: boolean = false;

  showForm: boolean = true;

  todayDate = new Date();

  searchid;

  referenceEntity = [
    { view: 'CDX.NA.HY 33 1', value: 'CDX.NA.HY_33_1' },
    { view: 'CDX.NA.HY.B 33 1', value: 'CDX.NA.HY.B_33_1' },
    { view: 'CDX.NA.HY.BB 33 1', value: 'CDX.NA.HY.BB_33_1' },
    { view: 'CDX.EM 32 1', value: 'CDX.EM_32_1' },
    { view: 'CDX.EM.DIVERSIFIED 12 4', value: 'CDX.EM.DIVERSIFIED_12_4' },
    { view: 'CDX.NA.IG 33 1', value: 'CDX.NA.IG_33_1' },
    { view: 'CDX.NA.IG.HVOL 33 1', value: 'CDX.NA.IG.HVOL_33_1' },
    { view: 'CDX.NA.XO 11 4', value: 'CDX.NA.XO_11_4' },
    { view: 'iTraxx Europe 32 1', value: 'iTraxx_Europe_32_1' },
    { view: 'iTraxx Europe HiVol 20 1', value: 'iTraxx_Europe_HiVol 20 1' },
    { view: 'iTraxx Europe Crossover 32 1', value: 'iTraxx_Europe_Crossover_32_1' },
    { view: 'iTraxx Japan 32 1', value: 'iTraxx_Japan_32_1' },
    { view: 'iTraxx Australia 32 1', value: 'iTraxx_Australia_32_1' },
    { view: 'iTraxx Asia ex-Japan IG 32 1', value: 'iTraxx_Asia_ex-Japan_IG_32_1' },
    { view: 'iTraxx SDI-75 4 3', value: 'iTraxx_SDI-75_4_3' },
    { view: 'iTraxx SovX Western Europe 8 1', value: 'iTraxx_SovX_Western_Europe_8_1' },
    { view: 'iTraxx SovX CEEMEA 11 2', value: 'iTraxx_SovX_CEEMEA_11_2' },
    { view: "iTraxx SovX Global Liquid Investment Grade 14 1", value: 'iTraxx_SovX_Global_Liquid_Investment_Grade_14_1' },
    { view: 'iTraxx SovX G7 14 1', value: 'iTraxx_SovX_G7_14_1' },
    { view: 'iTraxx SovX BRIC 14 1', value: 'iTraxx_SovX_BRIC_14_1' },
    { view: 'iTraxx SovX Asia Pacific 10 1', value: 'iTraxx_SovX Asia_Pacific_10_1' },
    { view: 'LCDX.NA 21 5', value: 'LCDX.NA_21_5' },
    { view: 'iTraxx LevX Senior 6 18', value: 'iTraxx_LevX_Senior_6_18' },
    { view: 'iTraxx LevX Subordinated 3 21', value: 'iTraxx_LevX_Subordinated_3_21' },
    { view: 'MCDX.NA 33 1', value: 'MCDX.NA_33_1' }
  ]
  matTerm: any;
  pageType = 'new';
  creditIndexData: any = [];
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public formbuilder: FormBuilder, public recoveryService: RecoverylockService, public matSnackBar: MatSnackBar, public datePipe: DatePipe, public cdsservice: CdsService, public creditrefenceService: CreditReferenceService) { }

  ngOnInit() {
    this.getAllcreditIndexes();
    // console.log(this.startDate.getFullYear())
    var valuationDate;

    valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.startDate.setDate(this.startDate.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 2)
    }

    this.instrumentForm = this.formbuilder.group({



      referenceEntity: ['', Validators.required],
      seniority: ['senior_unsecure', Validators.required],
      restructuring: ['nr', Validators.required],
      currency: ['usd', Validators.required],
      startDate: [moment(this.startDate), Validators.required],
      maturityterm: [''],
      maturity: ['', Validators.required],
      paymentDelay: ['quaterly', Validators.required],
      coupon: ['500', Validators.required],
      index_factor: ['1'],
      dateRole: ['modified_following', Validators.required],
      dayCount: ['act/360', Validators.required],
      quantity: ['5000000', Validators.required],
      buySell: ['buy', Validators.required],
      // hasFixedRec: ['40%', Validators.required],
      tradespread: ['500', Validators.required],
      upfrontfee: ['', Validators.required],
      upfrontamount: ['', Validators.required],
      valuationDate: [this.todayDate, Validators.required],
      cr01: [''],
      ir01: [''],
      mtm: [''],
      parRate: [''],

    })

    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })



    this.getrecovery();
    this.quantityadded('');
    this.selectbuysell({ value: 'buy' });
  }

  getAllcreditIndexes() {
    this.creditrefenceService.getCreditIndex().subscribe(res => {
      this.creditIndexData = res
    })
  }


  // defFormValues() {
  //   var valuationDate;

  //   valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

  //   if (valuationDate == 'Saturday') {
  //     this.startDate.setDate(this.startDate.getDate() + 3);
  //     // console.log(this.instrumentForm.value.startDate)
  //   } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
  //     // console.log(valuationDate)
  //     this.startDate.setDate(this.startDate.getDate() + 4)
  //   } else {
  //     // console.log(valuationDate)
  //     this.startDate.setDate(this.startDate.getDate() + 2)
  //   }

  //   this.instrumentForm = this.formbuilder.group({



  //     referenceEntity: [''],
  //     seniority: ['senior_unsecure'],
  //     restructuring: ['nr'],
  //     currency: ['usd'],
  //     startDate: [moment(this.startDate)],
  //     maturityterm: [''],
  //     maturity: [''],
  //     paymentDelay: ['quaterly'],
  //     coupon: ['500'],
  //     index_factor: ['1'],
  //     dateRole: ['modified_following'],
  //     dayCount: ['act/360'],
  //     quantity: ['5000000'],
  //     buySell: ['buy'],
  //     // hasFixedRec: ['40%', Validators.required],
  //     tradespread: ['500'],
  //     upfrontfee: [''],
  //     upfrontamount: [''],
  //     valuationDate: [this.todayDate],
  //     cr01: [''],
  //     ir01: [''],
  //     mtm: [''],
  //     parRate: [''],

  //   })

  //   this.dealForm = this.formbuilder.group({
  //     tradeBook: ['book1'],
  //     counterParty: ['cpt1'],
  //     tradeDate: [this.todayDate],
  //     clearingHouse: [''],
  //     tradeStatus: ['booked']
  //   })

  //   this.feeForm = this.formbuilder.group({
  //     broker: ['broker1'],
  //     currency: ['usd'],
  //     feeAmount: [''],
  //     feeDate: [moment(this.startDate)]
  //   })

  //   this.selectbuysell({ value: 'buy' });
  // }



  creditIndexName: any;
  oncdSelection(i) {
    console.log(i)
    this.creditIndexName = i.indexCompany + ' ' + i.indexName + ' ' + i.version + ' ' + 'S' + ' ' + i.series
  }


  OnStartDateChange(evt) {
    let selctDate = evt._d.getFullYear() + parseInt(this.matTerm)
    let finaldate = this.datePipe.transform(evt._d, 'MM/dd') + '/' + selctDate;
    this.instrumentForm.controls['maturity'].setValue(moment(finaldate))
    this.startDate = evt._d;
  }

  nextStep() {
    this.selected = 0;
    if (this.instrumentForm.valid) {
      this.selected = 2;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  maturityChange(ev) {
    this.matTerm = ev.value;
    let maturity_date = this.startDate.getFullYear() + parseInt(ev.value);

    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;

    this.instrumentForm.controls['maturity'].setValue(moment(finaldate))
  }

  selectbuysell(ev) {
    if (ev.value == 'buy') {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue((parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
      }, 100);
    } else {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue('-' + (parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'));
      }, 100);
    }

    // function formatMoney(number) {
    //   return number.toLocaleString('en-US', { currency: 'USD' });
    // }
    // if (ev.value == 'buy') {
    //   // console.log('buy  this.instrumentForm.value.quantity' + formatMoney(Math.abs(this.instrumentForm.value.quantity)))
    //   if (this.instrumentForm.value.quantity.includes(',')) {
    //     //   console.log('but includes' + this.instrumentForm.value.quantity)
    //     // console.log('buy includes' + this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(this.instrumentForm.value.quantity.split('-')[1])
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(formatMoney(Math.abs(this.instrumentForm.value.quantity)))
    //   }
    // } else {
    //   if (this.instrumentForm.value.quantity.includes(',')) {
    //     // console.log(this.instrumentForm.value.quantity.replace (/^/,'-'))
    //     this.instrumentForm.controls['quantity'].setValue(this.instrumentForm.value.quantity.replace(/^/, '-'));
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(formatMoney(-Math.abs(this.instrumentForm.value.quantity)));
    //   }
  // }


}


selectCredit(ev) {
  if (ev.value == 'stem') {
    this.instrumentForm.controls['restructuring'].setValue('mm');
    this.instrumentForm.controls['coupon'].setValue('100');
  } else if (ev.value == 'stac') {
    this.instrumentForm.controls['restructuring'].setValue('nr');
    this.instrumentForm.controls['coupon'].setValue('100');
  } else if (ev.value == 'snac') {
    this.instrumentForm.controls['restructuring'].setValue('mr');
    this.instrumentForm.controls['coupon'].setValue('500');
  }
}

quantityadded(event) {
  this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
  this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity.replace(/,/g, '')) / 10000)
}

gotofee() {

  if (!this.instrumentForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (!this.dealForm.valid) {
    this.matSnackBar.open('Please Enter All Fields', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (this.instrumentForm.valid && this.dealForm.valid) {
    this.selected = 3;
  } else {
    this.matSnackBar.open('Please Enter All Fields', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  }
}

cancelBtn() {
  this.selected = 0;
  this.ngOnInit();
}


addquantity(event) {
  console.log('yyyyyyyyyyyyyyyyy' + this.instrumentForm.value.quantity.replace(/,/g, ''))
  let val = this.instrumentForm.value.quantity.replace(/,/g, '')
  this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
  this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * val) / 10000)
}

quantityadded1(ev) {
  console.log('dsfdsfsd')
  this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
}

buySellValue: any;
search() {
  function formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }



  if (this.searchid) {
    this.cdsservice.getCdsIndex(this.searchid).subscribe(res => {
      if (res) {
        this.recoveryLockList = res;
        this.viewDeal = this.recoveryLockList;
        this.pageType = 'edit';
        if (this.viewDeal.deal.buySell == 'buy') {
          this.buySellValue = formatMoney(Math.abs(this.viewDeal.deal.instrument.quantity))
        } else {
          this.buySellValue = formatMoney(-Math.abs(this.viewDeal.deal.instrument.quantity));
        }
        this.instrumentForm = this.formbuilder.group({
          buySell: [this.viewDeal.deal.buySell],
          coupon: [JSON.stringify(this.viewDeal.deal.instrument.coupon)],
          index_factor: ['1'],
          // creditTemplate: [this.viewDeal.deal.instrument.style],
          referenceEntity: [this.viewDeal.creditDefaultSwap.creditName],
          seniority: [this.viewDeal.creditDefaultSwap.creditSeniority],
          restructuring: [this.viewDeal.creditDefaultSwap.creditRestructuring],
          currency: [this.viewDeal.deal.instrument.currency],
          startDate: [moment(this.viewDeal.deal.instrument.startDate)],
          maturity: [moment(this.viewDeal.deal.instrument.maturityDate)],
          paymentDelay: [this.viewDeal.deal.instrument.pmtFrequency],
          dateRole: [this.viewDeal.deal.instrument.dateRole],
          dayCount: [this.viewDeal.deal.instrument.dayCount],
          quantity: [this.buySellValue],
          maturityterm: [''],
          tradespread: [this.viewDeal.deal.instrument.price, Validators.required],
          upfrontfee: [this.viewDeal.creditDefaultSwap.upfrontFee, Validators.required],
          upfrontamount: [this.viewDeal.creditDefaultSwap.upfrontAmount, Validators.required],
          valuationDate: [this.todayDate],
          cr01: [''],
          ir01: [''],
          mtm: [''],
          parRate: ['']
        })

        this.dealForm = this.formbuilder.group({
          tradeBook: [this.viewDeal.deal.tradeBook],
          counterParty: [this.viewDeal.deal.otherBook],
          tradeDate: [moment(this.viewDeal.deal.tradeDate)],
          clearingHouse: [this.viewDeal.deal.clearingHouse, Validators.required],
          tradeStatus: [this.viewDeal.deal.tradeStatus]
        })

        this.feeForm = this.formbuilder.group({
          broker: [this.viewDeal.deal.fee.broker],
          currency: [this.viewDeal.deal.fee.currency],
          feeAmount: [this.viewDeal.deal.fee.feeAmount, Validators.required],
          feeDate: [moment(this.viewDeal.deal.fee.feeDate)]
        })
      } else {
        this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    })
  } else {
    this.matSnackBar.open('Please enter Deal ID', 'OK', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  }

}

couponChange(ev) {
  this.instrumentForm.controls['tradespread'].setValue(ev.value);
  this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
  this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
}

showAll() {
  this.viewDeal = '';
  this.showForm = false;
}

showform() {
  this.showForm = true;
}

view(dealObj) {
  this.viewDeal = dealObj;
}

getrecovery() {
  // if(this.searchid){
  // this.recoveryService.getRecoveryLock(this.searchid).subscribe(res=>{
  //   if(res){
  //     this.recoveryLockList = res;
  //     this.dataSource = new MatTableDataSource(res);
  //     this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
  //   }
  // })
  // }

}
newStr: any;
saveRecovery() {


  var myquantity = this.instrumentForm.value.quantity;
  this.newStr = myquantity.replace(/,/g, '');
  if (!this.instrumentForm.valid && !this.dealForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (!this.instrumentForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (!this.dealForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Deals', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {

    let reqObj = {

      "cdsbasket": {
        "creditNames": [
          "string"
        ],
        "factors": [
          0
        ],
        "weights": [
          0
        ]
      },

      "creditDefaultSwap": {
        "creditAccruedStyle": "string",
        "creditId": "123",
        // "creditName": this.instrumentForm.value.referenceEntity,
        "creditName": this.creditIndexName,
        "creditRestructuring": this.instrumentForm.value.restructuring,
        "creditSeniority": this.instrumentForm.value.seniority,
        "hasFixedRec": this.instrumentForm.value.hasFixedRec,
        "protectionEndDate": "2019-11-18",
        "protectionStartDate": "2019-11-18",
        "upfrontAmount": this.instrumentForm.value.upfrontfee,
        "upfrontFee": this.instrumentForm.value.upfrontamount,
      },
      "deal": {
        "buySell": this.instrumentForm.value.buySell,
        "clearingHouse": this.dealForm.value.clearingHouse,
        "fee":
        {
          "broker": this.feeForm.value.broker,
          "currency": this.feeForm.value.currency,
          "feeAmount": this.feeForm.value.feeAmount,
          "feeDate": this.feeForm.value.feeDate
        },
        "instrument":
        {
          "code": "string",
          "coupon": this.instrumentForm.value.coupon,
          "csaTerm": "string",
          "currency": this.instrumentForm.value.currency,
          "dayCount": this.instrumentForm.value.dayCount,
          "dateRole": this.instrumentForm.value.dateRole,
          "hasCustomCouponSchedule": "string",
          "holidayCalendars": "string",

          "instrumentName": "string",
          "instrumentType": "string",
          "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
          "paymentDelay": "string",
          "pmtFrequency": this.instrumentForm.value.paymentDelay,
          "price": this.instrumentForm.value.tradespread,
          "quantity": this.newStr,
          "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
          "style": this.instrumentForm.value.creditTemplate,
        },
        "otherBook": this.dealForm.value.counterParty,
        "settlementLocation": "string",
        "tradeBook": this.dealForm.value.tradeBook,
        "tradeDate": this.dealForm.value.tradeDate,
        "tradeStatus": this.dealForm.value.tradeStatus
      },

      "memberId": "123"
    }

    this.cdsservice.cdsIndex(reqObj).subscribe(res => {
      // console.log(res)
      if (res.status == 'ok') {
        this.matSnackBar.open('CDS added successfully. Deal ID is ' + res.ID, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        })
        this.selected = 0;
        this.ngOnInit();
        // this.defFormValues();
        this.fromGroupDirective.resetForm();
        this.ngOnInit()
      } else {
        this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    })
  } else {
    this.matSnackBar.open('Please Enter Mandatory Fields', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  }
}


alphakeyPress(ev) {
  // ev.target.value = ev.target.value.toLocaleString()
  if (this.instrumentForm.value.buySell == 'sell') {
    setTimeout(() => {
      ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
    }, 100);
  } else {
    setTimeout(() => {
      ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
    }, 100);
  }
  // console.log(ev)

  // console.log((parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
  // console.log(ev)
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(ev.charCode);

  if (!pattern.test(inputChar)) {
    // invalid character, prevent input
    ev.preventDefault();
  }
}


x = function rplaceQuantity() {
  var myquanty = this.instrumentForm.value.quantity;
  return myquanty.replace(/,/g, '');
}


canceldeal() {
  if (this.searchid) {
    let reqObj = {

      "cdsbasket": {
        "creditNames": [
          "string"
        ],
        "factors": [
          0
        ],
        "weights": [
          0
        ]
      },

      "creditDefaultSwap": {
        "creditAccruedStyle": "string",
        "creditId": "123",
        "creditName": this.instrumentForm.value.referenceEntity,
        "creditRestructuring": this.instrumentForm.value.restructuring,
        "creditSeniority": this.instrumentForm.value.seniority,
        // "hasFixedRec": this.instrumentForm.value.hasFixedRec,
        "protectionEndDate": "2019-11-18",
        "protectionStartDate": "2019-11-18",
        "upfrontAmount": this.instrumentForm.value.upfrontfee,
        "upfrontFee": this.instrumentForm.value.upfrontamount,
      },
      "deal": {
        "buySell": this.instrumentForm.value.buySell,
        "clearingHouse": this.dealForm.value.clearingHouse,
        "fee":
        {
          "broker": this.feeForm.value.broker,
          "currency": this.feeForm.value.currency,
          "feeAmount": this.feeForm.value.feeAmount,
          "feeDate": this.feeForm.value.feeDate
        },
        "instrument":
        {
          "code": "string",
          "coupon": this.instrumentForm.value.coupon,
          "csaTerm": "string",
          "currency": this.instrumentForm.value.currency,
          "dayCount": this.instrumentForm.value.dayCount,
          "dateRole": this.instrumentForm.value.dateRole,
          "hasCustomCouponSchedule": "string",
          "holidayCalendars": "string",

          "instrumentName": "string",
          "instrumentType": "string",
          "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
          "paymentDelay": "string",
          "pmtFrequency": this.instrumentForm.value.paymentDelay,
          "price": this.instrumentForm.value.tradespread,
          "quantity": this.x(),
          "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
          "style": this.instrumentForm.value.creditTemplate,
        },
        "otherBook": this.dealForm.value.counterParty,
        "settlementLocation": "string",
        "tradeBook": this.dealForm.value.tradeBook,
        "tradeDate": this.dealForm.value.tradeDate,
        "tradeStatus": 'cancelled'
      },
      "id": this.searchid,
      "memberId": "123"
    }

    this.cdsservice.cdsUpdate(reqObj).subscribe(res => {
      // console.log(res)
      if (res.status == 'ok') {
        this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        })
        this.searchid = '';
        this.pageType = 'new'
        this.fromGroupDirective.resetForm();
        this.ngOnInit()
      } else {
        this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    })
  } else {
    this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
      verticalPosition: 'top',
      duration: 3000,
      panelClass: 'snack-error'
    })
  }


}


update() {

  if (!this.instrumentForm.valid && !this.dealForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (!this.instrumentForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (!this.dealForm.valid) {
    this.matSnackBar.open('Please Enter All Fields In Deals', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {
    let reqObj = {

      "cdsbasket": {
        "creditNames": [
          "string"
        ],
        "factors": [
          0
        ],
        "weights": [
          0
        ]
      },

      "creditDefaultSwap": {
        "creditAccruedStyle": "string",
        "creditId": "123",
        "creditName": this.instrumentForm.value.referenceEntity,
        "creditRestructuring": this.instrumentForm.value.restructuring,
        "creditSeniority": this.instrumentForm.value.seniority,
        // "hasFixedRec": this.instrumentForm.value.hasFixedRec,
        "protectionEndDate": "2019-11-18",
        "protectionStartDate": "2019-11-18",
        "upfrontAmount": this.instrumentForm.value.upfrontfee,
        "upfrontFee": this.instrumentForm.value.upfrontamount,
      },
      "deal": {
        "buySell": this.instrumentForm.value.buySell,
        "clearingHouse": this.dealForm.value.clearingHouse,
        "fee":
        {
          "broker": this.feeForm.value.broker,
          "currency": this.feeForm.value.currency,
          "feeAmount": this.feeForm.value.feeAmount,
          "feeDate": this.feeForm.value.feeDate
        },
        "instrument":
        {
          "code": "string",
          "coupon": this.instrumentForm.value.coupon,
          "csaTerm": "string",
          "currency": this.instrumentForm.value.currency,
          "dayCount": this.instrumentForm.value.dayCount,
          "dateRole": this.instrumentForm.value.dateRole,
          "hasCustomCouponSchedule": "string",
          "holidayCalendars": "string",

          "instrumentName": "string",
          "instrumentType": "string",
          "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
          "paymentDelay": "string",
          "pmtFrequency": this.instrumentForm.value.paymentDelay,
          "price": this.instrumentForm.value.tradespread,
          "quantity": this.x(),
          "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
          "style": this.instrumentForm.value.creditTemplate,
        },
        "otherBook": this.dealForm.value.counterParty,
        "settlementLocation": "string",
        "tradeBook": this.dealForm.value.tradeBook,
        "tradeDate": this.dealForm.value.tradeDate,
        "tradeStatus": this.dealForm.value.tradeStatus
      },
      "id": this.searchid,
      "memberId": "123"
    }

    this.cdsservice.cdsUpdate(reqObj).subscribe(res => {
      // console.log(res)
      if (res.status == 'ok') {
        this.matSnackBar.open('CDS updated successfully', 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-success'
        })
        this.ngOnInit();
        this.pageType = "new"
        this.selected = 0;
        this.searchid = '';
        // this.defFormValues();
        // this.fromGroupDirective.resetForm();
        this.fromGroupDirective.resetForm();
        this.ngOnInit()
      } else {
        this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    })
  } else {
    this.matSnackBar.open('Please Enter All Fields', '', {
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'snack-error'
    })
  }
}

}
