import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { StaticDataService } from '../../providers/static-data.service';
import { DatePipe } from '@angular/common';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material';

import { ConfirmationDialog } from '../addmember/addmember.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


export interface BondData {
  "coupon": number;
  "currency": string;
  "bondId": string;
  "bondType": string;
  "bondName": string;
  "maturityTtenor": number;
  "country": string;
  "couponCurrency": string;
  "issuerCode": string;
  "issueDate": number;
  "maturityDate": number;
  "couponFrequency": string;
  "faceValue": number;
  "totalIssued": number;
  "dayCount": string;
  "announceDate": number;
  "auctionDate": number;
  "datedDate": number;
  "oddFirstCoupon": boolean;
  "firstCouponDate": number;
  "oddLastCoupon": boolean;
  "penultCpnDate": number;
  "settlementDays": number;
  "valueDays": number;
  "exdividendDays": number;
  "tickSize": string;
  "yieldMethod": string;
  "withholdingTax": number;
  "couponDateRoll": string;
  "couponHolidays": string;
  "rateIndex": string;
  "rateIndexSpread": number;
  "issuePrice": number;
  "redemptionPrice": number;
  "rollingDay": number;
  "resetInArrearsB": boolean;
  "capStrike": number;
  "floorStrike": number;
  "optionType": string;
  "floaterB": boolean;
  "fixedBb": boolean;
  "roundingUnit": number;
  "quoteType": string;
  "accrualRounding": number;
  "reconventionDate": number;
  "reconventionDC": string;
  "inactiveDate": number;
  "activeDate": number;
  "resetDays": number;
  "resetBusLagB": boolean;
  "prePaidB": boolean;
  "notionalIndex": string;
  "notlGuaranteed": number;
  "notlIndexValue": number;
  "cmpFrequency": string;
  "callDate": number;
  "assimilationDate": number;
  "assimPrdId": number;
  "targetId": number;
  "accDaycount": string;
  "defaultDate": number;
  "couponOffset": number;
}

@Component({
  selector: 'app-addbond',
  templateUrl: './addbond.component.html',
  styleUrls: ['./addbond.component.css']
})
export class AddbondComponent implements OnInit {

  displayedColumns: string[] = ['bondType', 'country', 'coupon', 'issuePrice', 'redemptionPrice', 'totalIssued', 'action'];
  dataSource: MatTableDataSource<BondData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;


  selected = 0;

  bondRefDataForm: FormGroup;

  referenceEntity = [
    { view: '3M Co', value: '3m_co' },
    { view: 'American Express Co', value: 'american_express_co' },
    { view: 'Apple Inc', value: 'apple_inc' },
    { view: 'Boeing Co', value: 'boeing_co' },
    { view: 'Caterpillar Inc', value: 'caterpillar_inc' },
    { view: 'Chevron Corp', value: 'chevron_corp' },
    { view: 'Cisco Systems Inc', value: 'cisco_systems_inc' },
    { view: 'Coca-Cola Co', value: 'coca_cola_co' },
    { view: 'E I du Pont de Nemours and Co', value: 'e_i_du_pont_de_nemours_and_co' },
    { view: 'Exxon Mobil Corp', value: 'exxon_mobil_corp' },
    { view: 'General Electric Co', value: 'general_electric_co' },
    { view: 'Goldman Sachs Group Inc', value: 'goldman_sachs_group_inc' },
    { view: 'Home Depot Inc', value: 'home_depot_inc' },
    { view: 'Intel Corp', value: 'intel_corp' },
    { view: 'International Business Machines Corp', value: 'international_business_machines_corp' },
    { view: 'Johnson & Johnson', value: 'johnson_and_johnson' },
    { view: 'JPMorgan Chase & Co', value: 'jpmorgan_chase_and_co' },
    { view: "McDonald's Corp", value: 'mcdonalds_corp' },
    { view: 'Merck & Co Inc', value: 'merck_and_co_inc' },
    { view: 'Microsoft Corp', value: 'microsoft_corp' },
    { view: 'Nike Inc', value: 'nike_inc' },
    { view: 'Pfizer Inc', value: 'pfizer_inc' },
    { view: 'Procter & Gamble Co', value: 'procter_and_gamble_co' },
    { view: 'Travelers Companies Inc', value: 'travelers_companies_inc' },
    { view: 'United Technologies Corp', value: 'united_technologies_corp' },
    { view: 'Verizon Communications Inc', value: 'verizon_communications_inc' },
    { view: 'Wal-Mart Stores Inc', value: 'walmart_stores_inc' },
    { view: 'Walt Disney Co', value: 'walt_disney_co' }
  ]

  bondData = [];

  pageType = 'new';

  bondObj;

  constructor(public formBuilder: FormBuilder, public staticDataService: StaticDataService, public matSnackBar: MatSnackBar, public dialog: MatDialog, public datePipe: DatePipe) { }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  deleteuser(userobj) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.staticDataService.delBond(userobj.bondId).subscribe(res => {

        })
      }
    });
  }

  changeTab(ev) {
    // console.log(ev)
    this.selected = ev;
  }

  editbond(bondObj) {
    this.selected = 0;
    this.bondObj = bondObj;
    this.pageType = 'edit';
    this.bondRefDataForm = this.formBuilder.group({
      bond_type: [bondObj.bondType, Validators.required],
      bond_desc: [bondObj.bondName],
      coupon: [bondObj.coupon, Validators.required],
      issued_date: [new Date(bondObj.issueDate)],
      dated_date: [new Date(bondObj.datedDate)],
      maturity: [new Date(bondObj.maturityDate)],
      issuer: [bondObj.issuerCode, Validators.required],
      country: [bondObj.country],
      issue_price: [bondObj.issuePrice],
      currency: [bondObj.currency],
      redeem_price: [bondObj.redemptionPrice],
      redeem_currency: [''],
      total_issued: [bondObj.totalIssued],
      face_value: [bondObj.faceValue],
      security: ['']
    })
  }

  ngOnInit() {
    this.bondRefDataForm = this.formBuilder.group({
      bond_type: ['', Validators.required],
      bond_desc: ['', Validators.required],
      coupon: ['', Validators.required],
      issued_date: ['', Validators.required],
      dated_date: ['', Validators.required],
      maturity: ['', Validators.required],
      issuer: ['', Validators.required],
      country: ['', Validators.required],
      issue_price: ['', Validators.required],
      currency: ['', Validators.required],
      redeem_price: ['', Validators.required],
      redeem_currency: ['', Validators.required],
      total_issued: ['', Validators.required],
      face_value: ['', Validators.required],
      security: ['', Validators.required]
    })
    this.getBondData()
  }


  updatebond() {
    let reqObj = {
      "accDaycount": "string",
      "accrualRounding": 0,
      "activeDate": "2019-12-19T13:31:40.449Z",
      "announceDate": "2019-12-19T13:31:40.449Z",
      "assimPrdId": 0,
      "assimilationDate": "2019-12-19T13:31:40.449Z",
      "auctionDate": "2019-12-19T13:31:40.449Z",
      "bondId": this.bondObj.bondId,
      "bondName": "string",
      "bondType": this.bondRefDataForm.value.bond_type,
      "callDate": "2019-12-19T13:31:40.449Z",
      "capStrike": 0,
      "cmpFrequency": "string",
      "country": this.bondRefDataForm.value.country,
      "coupon": this.bondRefDataForm.value.coupon,
      "couponCurrency": "string",
      "couponDateRoll": "string",
      "couponFrequency": "string",
      "couponHolidays": "string",
      "couponOffset": 0,
      "currency": this.bondRefDataForm.value.cuurency,
      "datedDate": this.datePipe.transform(this.bondRefDataForm.value.dated_date, 'MM-dd-yyyy'),
      "dayCount": "string",
      "defaultDate": "2019-12-19T13:31:40.449Z",
      "exdividendDays": 0,
      "faceValue": this.bondRefDataForm.value.face_value,
      "firstCouponDate": "2019-12-19T13:31:40.449Z",
      "fixedBb": true,
      "floaterB": true,
      "floorStrike": 0,
      "inactiveDate": "2019-12-19T13:31:40.449Z",
      "issueDate": this.datePipe.transform(this.bondRefDataForm.value.issued_date, 'MM-dd-yyyy'),
      "issuePrice": this.bondRefDataForm.value.issue_price,
      "issuerCode": this.bondRefDataForm.value.issuer,
      "maturityDate": this.datePipe.transform(this.bondRefDataForm.value.maturity, 'MM-dd-yyyy'),
      "maturityTtenor": 0,
      "notionalIndex": "string",
      "notlGuaranteed": 0,
      "notlIndexValue": 0,
      "oddFirstCoupon": true,
      "oddLastCoupon": true,
      "optionType": "string",
      "penultCpnDate": "2019-12-19T13:31:40.449Z",
      "prePaidB": true,
      "quoteType": "string",
      "rateIndex": "string",
      "rateIndexSpread": 0,
      "reconventionDC": "string",
      "reconventionDate": "2019-12-19T13:31:40.449Z",
      "redemptionPrice": this.bondRefDataForm.value.redeem_price,
      "resetBusLagB": true,
      "resetDays": 0,
      "resetInArrearsB": true,
      "rollingDay": 0,
      "roundingUnit": 0,
      "settlementDays": 0,
      "targetId": 0,
      "tickSize": "string",
      "totalIssued": this.bondRefDataForm.value.total_issued,
      "valueDays": 0,
      "withholdingTax": 0,
      "yieldMethod": "string"
    }
    this.staticDataService.editBond(reqObj).subscribe(res => {
      if (res || res.status == 'ok') {
        this.matSnackBar.open('Bond Ref Data updated successfully.', 'OK', {
          verticalPosition: 'top',

          panelClass: 'snack-success'
        })
        this.getBondData();
        this.fromGroupDirective.resetForm();
        this.pageType="new"
      } else {
        this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    })
  }

  addbond() {
    if (this.bondRefDataForm.valid) {
      let reqObj = {
        "accDaycount": "string",
        "accrualRounding": 0,
        "activeDate": "2019-12-19T13:31:40.449Z",
        "announceDate": "2019-12-19T13:31:40.449Z",
        "assimPrdId": 0,
        "assimilationDate": "2019-12-19T13:31:40.449Z",
        "auctionDate": "2019-12-19T13:31:40.449Z",

        "bondName": this.bondRefDataForm.value.bond_desc,
        "bondType": this.bondRefDataForm.value.bond_type,
        "callDate": "2019-12-19T13:31:40.449Z",
        "capStrike": 0,
        "cmpFrequency": "string",
        "country": this.bondRefDataForm.value.country,
        "coupon": this.bondRefDataForm.value.coupon,
        "couponCurrency": "string",
        "couponDateRoll": "string",
        "couponFrequency": "string",
        "couponHolidays": "string",
        "couponOffset": 0,
        "currency": this.bondRefDataForm.value.cuurency,
        "datedDate": this.datePipe.transform(this.bondRefDataForm.value.dated_date, 'MM-dd-yyyy'),
        "dayCount": "string",
        "defaultDate": "2019-12-19T13:31:40.449Z",
        "exdividendDays": 0,
        "faceValue": this.bondRefDataForm.value.face_value,
        "firstCouponDate": "2019-12-19T13:31:40.449Z",
        "fixedBb": true,
        "floaterB": true,
        "floorStrike": 0,
        "inactiveDate": "2019-12-19T13:31:40.449Z",
        "issueDate": this.datePipe.transform(this.bondRefDataForm.value.issued_date, 'MM-dd-yyyy'),
        "issuePrice": this.bondRefDataForm.value.issue_price,
        "issuerCode": this.bondRefDataForm.value.issuer,
        "maturityDate": this.datePipe.transform(this.bondRefDataForm.value.maturity, 'MM-dd-yyyy'),
        "maturityTtenor": 0,
        "notionalIndex": "string",
        "notlGuaranteed": 0,
        "notlIndexValue": 0,
        "oddFirstCoupon": true,
        "oddLastCoupon": true,
        "optionType": "string",
        "penultCpnDate": "2019-12-19T13:31:40.449Z",
        "prePaidB": true,
        "quoteType": "string",
        "rateIndex": "string",
        "rateIndexSpread": 0,
        "reconventionDC": "string",
        "reconventionDate": "2019-12-19T13:31:40.449Z",
        "redemptionPrice": this.bondRefDataForm.value.redeem_price,
        "resetBusLagB": true,
        "resetDays": 0,
        "resetInArrearsB": true,
        "rollingDay": 0,
        "roundingUnit": 0,
        "settlementDays": 0,
        "targetId": 0,
        "tickSize": "string",
        "totalIssued": this.bondRefDataForm.value.total_issued,
        "valueDays": 0,
        "withholdingTax": 0,
        "yieldMethod": "string"
      }
      this.staticDataService.addbond(reqObj).subscribe(res => {
        console.log(res)

        if (res || res.status == 'ok') {
          this.matSnackBar.open('Bond Ref Data added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          // this.ngOnInit();
          this.getBondData();
          this.fromGroupDirective.resetForm();

        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  getBondData() {
    this.staticDataService.getBond().subscribe(res => {
      if (res.length > 0) {
        this.bondData = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.bondData = [];
        this.dataSource = new MatTableDataSource(this.bondData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }



}
