import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bondalgo',
  templateUrl: './bondalgo.component.html',
  styleUrls: ['./bondalgo.component.css']
})
export class BondalgoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
