import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-midviewer',
  templateUrl: './midviewer.component.html',
  styleUrls: ['./midviewer.component.css']
})
export class MidviewerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
