import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  submenu = { "title": 'Dashboard', "img": '', "subpages": { "header": '', "links": [{ "title": '', "path": '' }] } };

  activePage;

  urlArr;

  sideNav: boolean = true;

  activeMainDashboard: boolean = true;

  sidemenuArr = [
    {
      "title": 'Credit Trading', 'img': 'assets/img/menu1.png',
      'subpages': {
        "header": 'Credit Trading',
        "links": [
          { "title": 'Recovery Lock', "path": 'recoverylock' },
          { "title": 'Quanto CDS', "path": 'qauntocds' },
          { "title": 'CDS (Credit Index)', "path": 'cds' },
          { "title": 'CDS (Single Name)', "path": 'cds_single' },
          { "title": 'Credit Index Option', "path": 'creditindexoption' },
          { "title": 'Total Return Swap', "path": 'returnswap' },
          { "title": 'Bond Trade', "path": 'bondtrade' },
          { "title": 'Credit Link Notes', "path": 'creditlinknotes' },
          { "title": 'Bond Option', "path": 'bondoption' }

        ]
      }
    },
    {
      "title": 'Bond Trading', 'img': 'assets/img/menu2.png',
      'subpages': {
        "header": 'Bonds',
        "links": [
          { "title": 'Bond basis Calculator', "path": 'bondbasis' },
          { "title": 'Bond Algo Trader', "path": 'bondalgotrader' },
          { "title": 'Bond Risk Calculator', "path": 'bondriskcalculator' },
          { "title": 'MidViewer', "path": 'midviewer' }

        ]
      }
    },
    {
      "title": 'Credit Trading Strategies', 'img': 'assets/img/menu4.png',
      'subpages': {
        "header": 'Credit Trading Strategies',
        "links": [
          { "title": 'Curve Trade', "path": 'curvetrade' },
          { "title": 'P&L Builder', "path": 'plbuilder' },
          { "title": 'Side Report Builder', "path": 'slidereport' },
          { "title": 'Single Name Index Basis', "path": 'singlenameindex' },
          { "title": 'Calculator', "path": '' },
        ]
      }
    },
    {
      "title": 'Credit Market Data', 'img': 'assets/img/menu5 1.png',
      'subpages': {
        "header": 'Credit Market Data',
        "links": [
          { "title": 'Market Data Blotter', "path": 'marketdatablotter' },
          { "title": 'Market Data Uploader', "path": 'marketdatauploader' }

        ]
      }
    },
    {
      "title": 'Market Data', 'img': 'assets/img/menu5.png',
      'subpages': {
        "header": 'Market Data',
        "links": [
          { "title": 'Market Data Report', "path": 'marketdatareport' },
          { "title": 'Market Data Upload', "path": 'marketdataupload' }

        ]
      }
    },
    {
      "title": 'Credit Reference Data', 'img': 'assets/img/menu3.png',
      'subpages': {
        "header": 'Credit Reference Data',
        "links": [
          { "title": 'Restructuring', "path": 'restructuring' },
          { "title": 'FTD Basket', "path": 'ftdbasket' },
          { "title": 'RefEntity', "path": 'refentity' },
          { "title": 'Tranches', "path": 'tranches' },
          { "title": 'Seniority', "path": 'seniority' },
          { "title": 'Credit Index', "path": 'creditindex' }
        ]
      }
    },
    {
      "title": 'Static data', 'img': 'assets/img/menu6.png',
      'subpages': {
        "header": 'Static data',
        "links": [
          { "title": 'Currency', "path": 'addcurrency' },
          // {"title":'Users',"path":'adduser'},
          { "title": 'User', "path": 'addmember' },
          { "title": 'Books/Accounts', "path": 'addbooks' },
          { "title": 'Legal Entities', "path": 'addlegalentity' },
          { "title": 'User Defaults', "path": 'adduserdefault' },
          { "title": 'Bond Ref Data', "path": 'addbond' }
        ]
      }
    },


    {
      "title": 'Reports', 'img': 'assets/img/menu7.png',
      'subpages': {
        "header": 'Reports',
        "links": [
          { "title": 'Risk Report', "path": 'riskreports' },
          { "title": 'Trade Report', "path": 'tradereport' },
          { "title": 'P&L Report', "path": 'plreport' }


        ]
      }
    }

  ]

  constructor(public router: Router) {
    if (localStorage.getItem('email') == undefined) {
      this.router.navigate(['/login'])
    }
  }

  ngOnInit() {

    this.urlArr = document.URL.split('/');
    if (this.urlArr[this.urlArr.length - 1] != 'dashboard') {
      this.activePage = this.urlArr[this.urlArr.length - 1];
      if (this.activePage == 'maindashboard') {
        this.activeMainDashboard = true;

      } else {
        this.activeMainDashboard = false;

      }
      this.sidemenuArr.forEach(sidemnu => {
        sidemnu.subpages.links.forEach(link => {
          if (link.path == this.activePage) {
            this.submenu = sidemnu;
          }
        })
      })
    }
    // this.submenu = this.sidemenuArr[0];

    // this.activePage = this.submenu.subpages.links[0].path;
  }

  closeNav(ev) {
    this.sideNav = ev;
    if (this.sideNav) {
      $(".sidebar").css({ 'width': '300px' });
      $(".mat-sidenav-content").css({ 'margin-left': '300px' })
    } else {
      $(".sidebar").css({ 'width': '62px' });
      $(".mat-sidenav-content").css({ 'margin-left': '62px' })
    }
  }

  showNav(ev) {
    if (!this.sideNav) {
      this.closeNav(ev)
    }
  }

  openSubMenu(submenu) {
    if (typeof (submenu) == 'string') {
      this.activeMainDashboard = true;

    } else {
      this.submenu = submenu;
      this.activeMainDashboard = false;
      this.activePage = submenu.subpages.links[0].path;
      this.router.navigate(['../dashboard/' + this.activePage]);
    }
  }

  renderSubPage(activePage) {
    this.activePage = activePage.path;
    // this.router.navigate([activePage.path]);
  }

}
