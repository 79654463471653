import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { CredittradingService } from 'src/providers/credittrading.service';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-plbuilder',
  templateUrl: './plbuilder.component.html',
  styleUrls: ['./plbuilder.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class PlbuilderComponent implements OnInit {
  plBuilderForm: FormGroup;
  pageType = 'new';
  newStr: any;
  searchid: any;
  idData: any;
  buySellValue: any;
  allPLData = [];
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public matSnackBar: MatSnackBar, public formbuilder: FormBuilder, public plBuilder: CredittradingService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.plBuilderForm = this.formbuilder.group({
      plcurveName: ['', Validators.required],
      buySell: ['buy', Validators.required],
      pltodaydate: [new Date(), Validators.required],
      plhorizndate: [new Date(), Validators.required],
      quantity: ['5,000,000', Validators.required],
      pltenor: ['', Validators.required]
    })
    this.getAllPLs();
  }


  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }


  selectbuysell(ev) {
    if (ev.value == 'buy') {
      setTimeout(() => {
        this.plBuilderForm.controls['quantity'].setValue((parseInt(this.plBuilderForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
      }, 100);
    } else {
      setTimeout(() => {
        this.plBuilderForm.controls['quantity'].setValue('-' + (parseInt(this.plBuilderForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'));
      }, 100);
    }
  }


  search() {
    function formatMoney(number) {
      return number.toLocaleString('en-US', { currency: 'USD' });
    }

    if (this.searchid) {
      this.plBuilder.getPlBulderById(this.searchid).subscribe(res => {
        if (res) {
          this.idData = res;
          this.pageType = 'update';
          if (this.idData.buyNSell == 'buy') {
            this.buySellValue = formatMoney(Math.abs(this.idData.notional))
          } else {
            this.buySellValue = formatMoney(-Math.abs(this.idData.notional));
          }
          this.plBuilderForm = this.formbuilder.group({
            plcurveName: [this.idData.curveName, Validators.required],
            buySell: [this.idData.buyNSell, Validators.required],
            pltodaydate: [moment(this.idData.today), Validators.required],
            plhorizndate: [moment(this.idData.horizon), Validators.required],
            quantity: [this.buySellValue, Validators.required],
            pltenor: [this.idData.tenor, Validators.required]

          })
        } else {
          this.matSnackBar.open('Please Enter valid  ID', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter Valid ID', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }



  alphakeyPress(ev) {
    if (this.plBuilderForm.value.buySell == 'sell') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    }

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }



  savePL() {
    if (this.plBuilderForm.valid) {
      var myquantity = this.plBuilderForm.value.quantity;
      this.newStr = myquantity.replace(/,/g, '');

      let reqObj = {
        "buyNSell": this.plBuilderForm.value.buySell,
        "curveName": this.plBuilderForm.value.plcurveName,
        "horizon": this.datePipe.transform(this.plBuilderForm.value.plhorizndate, 'MM-dd-yyyy'),
        "notional": this.newStr,
        "search": "string",
        "tenor": this.plBuilderForm.value.pltenor,
        "today": this.datePipe.transform(this.plBuilderForm.value.pltodaydate, 'MM-dd-yyyy'),
      }

      this.plBuilder.addPlBuilder(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Pl Builder Added Successfully. Pl Builder ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
        }

       this.fromGroupDirective.resetForm();
       this.ngOnInit();

      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  updatePl() {
    if (this.plBuilderForm.valid) {
      var myquantity = this.plBuilderForm.value.quantity;
      this.newStr = myquantity.replace(/,/g, '');

      let reqObj = {
        "buyNSell": this.plBuilderForm.value.buySell,
        "curveName": this.plBuilderForm.value.plcurveName,
        "horizon": this.datePipe.transform(this.plBuilderForm.value.plhorizndate, 'MM-dd-yyyy'),
        "id": this.searchid,
        "notional": this.newStr,
        "search": "string",
        "tenor": this.plBuilderForm.value.pltenor,
        "today": this.datePipe.transform(this.plBuilderForm.value.pltodaydate, 'MM-dd-yyyy'),
      }

      this.plBuilder.UpdatePlBuilder(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Pl Builder Updated Successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.searchid = '';
          this.pageType = 'new'
        }
        this.fromGroupDirective.resetForm();
        this.ngOnInit();
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  getAllPLs() {
    this.plBuilder.getAllPlBuilder().subscribe(res => {
      this.allPLData = res;
    })
  }
}
