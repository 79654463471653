import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  handleError(error) {
  //  console.log(error)
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error}`;
    } else {
      // server-side error
      errorMessage = error;
    }
  //  console.log(error)
    return throwError(errorMessage);
  }

  adduser(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'user/register',reqData, httpOptions)
    .pipe(map((res:any)=>res), catchError(this.handleError))
  }


  getUsers(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT+ 'user/getAll', httpOptions)
    .pipe(map((res:any)=>res))

  }

  addmember(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'member/create',reqData, httpOptions)
    .pipe(map((res:any)=>res), catchError(this.handleError))
  }

  getMembers(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT+ 'member/', httpOptions)
    .pipe(map((res:any)=>res))

  }

  deluser(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.delete(this.AppConfig.API_ENDPOINT+ 'member/delete/'+id+'/', httpOptions)
    .pipe(map((res:any)=>res))
  }

  resetPassword(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'user/changepassword',reqData, httpOptions)
    .pipe(map((res:any)=>res), catchError(this.handleError))
  }



  loginUser(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic YnJvd3Nlcjo='
      })
    }
    const formData = new URLSearchParams();
    formData.set('scope', 'ui');
    formData.set('grant_type', 'password');
    formData.set('username', reqData.email);
    formData.set('password', reqData.password);

    return this.http.post(this.AppConfig.USER_ENDPOINT+ 'auth/oauth/token', formData.toString(), httpOptions)
    .pipe(map((res:any)=>res), catchError(this.handleError))
  }


  forgotUserpswd(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'user/forgotpassword',reqData, httpOptions)
    .pipe(map((res:any)=>res), catchError(this.handleError))
  }


}
