import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { StaticDataService } from '../../providers/static-data.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material';

import { ConfirmationDialog } from '../addmember/addmember.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface LegalEntityData {
  "address": "string",
  "country": "string",
  "email": "string",
  "entityCode": "string",
  "entityId": "string",
  // "entityName": "string",
  "entityType": "string",
  "phone": "string",
  "region": "string"
}


@Component({
  selector: 'app-addlegalentity',
  templateUrl: './addlegalentity.component.html',
  styleUrls: ['./addlegalentity.component.css']
})
export class AddlegalentityComponent implements OnInit {

  displayedColumns: string[] = ['entityId', 'entityCode', 'entityType', 'email', 'phone', 'address', 'country', 'region', 'action'];
  dataSource: MatTableDataSource<LegalEntityData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  selected = 0;

  addLegalEntityForm: FormGroup;

  legalEntityData = [];

  pageType = 'new';

  legalObj;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;


  constructor(public formBuilder: FormBuilder, public staticData: StaticDataService, public matSnackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.addLegalEntityForm = this.formBuilder.group({
      address: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', Validators.required],
      entityCode: ['', Validators.required],
      entityId: ['', Validators.required],
      // entityName: ['', Validators.required],
      entityType: ['', Validators.required],
      phone: ['', Validators.required],
      region: ['', Validators.required],
      shortcode: ['']
    })
    this.getLegalEntity();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  addlegal() {
    console.log(this.addLegalEntityForm.value)
    if (this.addLegalEntityForm.valid) {
      let reqObj = {
        "address": this.addLegalEntityForm.value.address,
        "country": this.addLegalEntityForm.value.country,
        "email": this.addLegalEntityForm.value.email,
        "entityCode": this.addLegalEntityForm.value.entityCode,
        "entityId": this.addLegalEntityForm.value.entityId,
        // "entityName": this.addLegalEntityForm.value.entityName,
        "entityType": this.addLegalEntityForm.value.entityType,
        "phone": this.addLegalEntityForm.value.phone,
        "region": this.addLegalEntityForm.value.region,
        "id": '123'
      }

      this.staticData.addLegalEntity(reqObj).subscribe(res => {
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Legal Entity added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          // this.ngOnInit();
          this.fromGroupDirective.resetForm();
          this.getLegalEntity();

        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }

      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }


  }

  changeTab(ev) {
    // console.log(ev)
    this.selected = ev;
  }

  editlegal(legalObj) {
    console.log('legalObj' + JSON.stringify(legalObj))
    this.legalObj = legalObj;
    this.pageType = 'edit';
    this.selected = 0;
    this.addLegalEntityForm = this.formBuilder.group({
      address: [legalObj.address, Validators.required],
      country: [legalObj.country, Validators.required],
      email: [legalObj.email, Validators.required],
      entityCode: [legalObj.entityCode, Validators.required],
      entityId: [legalObj.entityId, Validators.required],
      // entityName: [legalObj.entityName, Validators.required],
      entityType: [legalObj.entityType, Validators.required],
      phone: [legalObj.phone, Validators.required],
      region: [legalObj.region, Validators.required],
      shortcode: ['']
    })
  }

  updatelegal() {
    let reqObj = {
      "address": this.addLegalEntityForm.value.address,
      "country": this.addLegalEntityForm.value.country,
      "email": this.addLegalEntityForm.value.email,
      "entityCode": this.addLegalEntityForm.value.entityCode,
      "entityId": this.addLegalEntityForm.value.entityId,
      // "entityName": this.addLegalEntityForm.value.entityName,
      "entityType": this.addLegalEntityForm.value.entityType,
      "phone": this.addLegalEntityForm.value.phone,
      "region": this.addLegalEntityForm.value.region,
    }

    this.staticData.ediLegalEntity(reqObj).subscribe(res => {
      if (res || res.status == 'ok') {
        this.matSnackBar.open('Legal Entity updated successfully. ', 'OK', {
          verticalPosition: 'top',

          panelClass: 'snack-success'
        })
        this.fromGroupDirective.resetForm();
        this.pageType = "new";
        this.getLegalEntity();

      } else {
        this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }

    })
  }

  deleteentity(entity) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.staticData.delLegalEntity(entity.entityId).subscribe(res => {

        })
      }
    });
  }


  getLegalEntity() {
    this.staticData.getLegalEntity().subscribe(res => {
      if (res.length > 0) {
        this.legalEntityData = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.legalEntityData = [];
        this.dataSource = new MatTableDataSource(this.legalEntityData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }
}
