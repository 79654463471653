import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { StaticDataService } from '../../providers/static-data.service';
import { MatSnackBar } from '@angular/material';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


import { ConfirmationDialog } from '../addmember/addmember.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface BooksData {
  "accountingBook": string,
  "baseCurrency": string,
  "bookId": string,
  "bookName": string,
  "eodTime": string,
  "holidays": string,
  "legalEntityCode": string,
  "region": string
}


@Component({
  selector: 'app-addbooks',
  templateUrl: './addbooks.component.html',
  styleUrls: ['./addbooks.component.css']
})
export class AddbooksComponent implements OnInit {

  displayedColumns: string[] = ['accountingBook', 'baseCurrency', 'bookId', 'bookName', 'eodTime', 'legalEntityCode', 'region', 'action'];
  dataSource: MatTableDataSource<BooksData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  addBookForm: FormGroup;

  time = { hour: 13, minute: 30 };

  selectedItems = [];


  dropdownSettings = {};

  selected = 0;

  refComponents = [
    { view: 'Holiday 1', value: 'holi1' },
    { view: 'Holiday 2', value: 'holi2' },
    { view: 'Holiday 3', value: 'holi3' },
    { view: 'Holiday 4', value: 'holi4' }];

  booksData = [];

  pageType = 'new';

  constructor(public formBuilder: FormBuilder, public staticData: StaticDataService, public matSnackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'view',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.addBookForm = this.formBuilder.group({
      book_id: ['', Validators.required],
      book_name: ['', Validators.required],
      account_book: ['', Validators.required],
      legal_entity: ['', Validators.required],
      region: ['', Validators.required],
      base_currency: ['', Validators.required],
      eod: ['', Validators.required],
      holidays: [[], Validators.required]
    })
    this.getBooks();
  }
  onItemSelect(ev) {
    this.selectedItems.push(ev.value)
  }

  onSelectAll(ev) {

  }

  getBooks() {
    this.staticData.getBook().subscribe(res => {
      if (res.length > 0) {
        this.booksData = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.booksData = [];
        this.dataSource = new MatTableDataSource(this.booksData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deletebook(entity) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.staticData.delBook(entity.bookId).subscribe(res => {

        })
      }
    });
  }


  changeTab(ev) {
    // console.log(ev)
    this.selected = ev;
  }

  editbook(bookObj) {
    this.selected = 0;
    this.pageType = 'edit';
    let ccc =  JSON.parse(bookObj.holidays);
    this.addBookForm = this.formBuilder.group({
      book_id: [bookObj.bookId, Validators.required],
      book_name: [bookObj.bookName, Validators.required],
      account_book: [bookObj.accountingBook, Validators.required],
      legal_entity: [bookObj.legalEntityCode, Validators.required],
      region: [bookObj.region, Validators.required],
      base_currency: [bookObj.baseCurrency],
      eod: [bookObj.eodTime],
      holidays: [this.addBookForm.get('holidays').setValue(ccc)]
    })

  }

  update_book() {
    if (this.addBookForm.valid) {
      let reqObj = {
        "accountingBook": this.addBookForm.value.account_book,
        "baseCurrency": this.addBookForm.value.base_currency,
        "bookId": this.addBookForm.value.book_id,
        "bookName": this.addBookForm.value.book_name,
        "eodTime": this.addBookForm.value.eod,
        "holidays": JSON.stringify(this.selectedItems),
        "legalEntityCode": this.addBookForm.value.legal_entity,
        "region": this.addBookForm.value.region
      }

      this.staticData.editBook(reqObj).subscribe(res => {
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Book/Accounts updated successfully.', 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          // this.ngOnInit();
          this.getBooks();
          this.fromGroupDirective.resetForm();
          this.pageType="new";
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }

      })
    } else {
      this.matSnackBar.open('Please Enter Mandatory fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  add_book() {
    if (this.addBookForm.valid) {
      let reqObj = {
        "accountingBook": this.addBookForm.value.account_book,
        "baseCurrency": this.addBookForm.value.base_currency,
        "bookId": this.addBookForm.value.book_id,
        "bookName": this.addBookForm.value.book_name,
        "eodTime": this.addBookForm.value.eod,
        "holidays": JSON.stringify(this.selectedItems),
        "legalEntityCode": this.addBookForm.value.legal_entity,
        "region": this.addBookForm.value.region
      }

      this.staticData.addBook(reqObj).subscribe(res => {
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Book/Accounts added successfully.  ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',
            panelClass: 'snack-success'
          })
          this.fromGroupDirective.resetForm();
          this.getBooks();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter Mandatory Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }
}
