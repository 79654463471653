import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../pages/login/login.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AnalyticsComponent } from '../pages/analytics/analytics.component';
import { CdsComponent } from '../pages/cds/cds.component';
import { QuantocdsComponent } from '../pages/quantocds/quantocds.component';
import { CdssingleComponent } from '../pages/cdssingle/cdssingle.component';
import { CreditindexoptionComponent } from '../pages/creditindexoption/creditindexoption.component';
import { ReturnswapComponent } from '../pages/returnswap/returnswap.component';
import { CreditlinknotesComponent } from '../pages/creditlinknotes/creditlinknotes.component';

import { BondtradeComponent } from '../pages/bondtrade/bondtrade.component';
import { BondbasisComponent } from '../pages/bondbasis/bondbasis.component';
import { BondalgoComponent } from '../pages/bondalgo/bondalgo.component';
import { BondriskComponent } from '../pages/bondrisk/bondrisk.component';
import { MidviewerComponent } from '../pages/midviewer/midviewer.component';
import { CurvetradeComponent } from '../pages/curvetrade/curvetrade.component';
import { PlbuilderComponent } from '../pages/plbuilder/plbuilder.component';
import { SlidereportComponent } from '../pages/slidereport/slidereport.component';
import { SinglenameComponent } from '../pages/singlename/singlename.component';
import { BlotterComponent } from '../pages/blotter/blotter.component';
import { UploaderComponent } from '../pages/uploader/uploader.component';
import { RestructuringComponent } from '../pages/restructuring/restructuring.component';
import { FtdComponent } from '../pages/ftd/ftd.component';
import { RefentityComponent } from '../pages/refentity/refentity.component';
import { TranchesComponent } from '../pages/tranches/tranches.component';
import { SeniorityComponent } from '../pages/seniority/seniority.component';
import { CreditindexComponent } from '../pages/creditindex/creditindex.component';
import { AddcurrencyComponent } from '../pages/addcurrency/addcurrency.component';
import { AdduserComponent } from '../pages/adduser/adduser.component';
import { AddbooksComponent } from '../pages/addbooks/addbooks.component';
import { AddlegalentityComponent } from '../pages/addlegalentity/addlegalentity.component';
import { AdduserdefaultComponent } from '../pages/adduserdefault/adduserdefault.component';
import { AddbondComponent } from '../pages/addbond/addbond.component';
import { PlreportComponent } from '../pages/plreport/plreport.component';
import { TradereportComponent } from '../pages/tradereport/tradereport.component';
import { ReportsComponent } from 'src/pages/reports/reports.component';
import { MaindashboardComponent } from 'src/pages/maindashboard/maindashboard.component';
import { AddmemberComponent } from '../pages/addmember/addmember.component';

import { ResetPasswordComponent } from '../pages/reset-password/reset-password.component';

import { ChangepasswordComponent } from '../pages/changepassword/changepassword.component';

import { MarketdatareportComponent } from '../pages/marketdatareport/marketdatareport.component';
import { MarketdatauploadComponent } from '../pages/marketdataupload/marketdataupload.component';

import { BondoptionComponent } from '../pages/bondoption/bondoption.component';



const routes : Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'resetpassword', component:ResetPasswordComponent},
  {path:'dashboard',component:DashboardComponent,
children:[
  {path:'',redirectTo:'maindashboard',pathMatch:'full'},
  {path:'changepassword',component:ChangepasswordComponent },
  {path:'marketdatareport',component:MarketdatareportComponent },
  {path:'marketdataupload',component:MarketdatauploadComponent },
  {path:'maindashboard',component:MaindashboardComponent },
  {path:'recoverylock',component:AnalyticsComponent },
  {path:'cds',component:CdsComponent},
  {path:'qauntocds',component:QuantocdsComponent},
  {path:'cds_single',component:CdssingleComponent},
  {path:'creditindexoption',component:CreditindexoptionComponent},
  {path:'returnswap',component:ReturnswapComponent},
  {path:'creditlinknotes',component:CreditlinknotesComponent},
  {path:'bondtrade',component:BondtradeComponent},

  {path:'bondbasis',component:BondbasisComponent},
  {path:'bondalgotrader',component:BondalgoComponent},
  {path:'bondriskcalculator',component:BondriskComponent},
  {path:'midviewer',component:MidviewerComponent},

  {path:'curvetrade',component:CurvetradeComponent},
  {path:'plbuilder',component:PlbuilderComponent},
  {path:'slidereport',component:SlidereportComponent},
  {path:'singlenameindex',component:SinglenameComponent},

  {path:'marketdatablotter',component:BlotterComponent},
  {path:'marketdatauploader',component:UploaderComponent},

  {path:'restructuring',component:RestructuringComponent},
  {path:'ftdbasket',component:FtdComponent},
  {path:'refentity',component:RefentityComponent},
  {path:'tranches',component:TranchesComponent},
  {path:'seniority',component:SeniorityComponent},
  {path:'creditindex',component:CreditindexComponent},

  {path:'addcurrency',component:AddcurrencyComponent},
  {path:'adduser',component:AdduserComponent},
  {path:'addmember',component:AddmemberComponent},
  {path:'addbooks',component:AddbooksComponent},
  {path:'addlegalentity',component:AddlegalentityComponent},
  {path:'adduserdefault',component:AdduserdefaultComponent},
  {path:'addbond',component:AddbondComponent},

  {path:'riskreports',component:ReportsComponent},
  {path:'plreport',component:PlreportComponent},
  {path:'tradereport',component:TradereportComponent},

  {path:'bondoption',component:BondoptionComponent}


]},
  {path:'login',component:LoginComponent},
  
   ]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports:[RouterModule]
})
export class AppRoutingModule { }
