import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

export interface RiskReportData {
  trade_id:string;
book:string;
  restructuring:string;
  seniority:string;
  notional:string;
 upfront_fee:string;
trade_spread:string;
  

}

@Component({
  selector: 'app-tradereport',
  templateUrl: './tradereport.component.html',
  styleUrls: ['./tradereport.component.css']
})
export class TradereportComponent implements OnInit {
  displayedColumns: string[] = ['trade_id', 'book', 'restructuring',  'seniority',  'notional', 'trade_spread', 'upfront_fee'];
dataSource: MatTableDataSource<RiskReportData>;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

tradeReportArray = [
  {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges1', 'notional':'GolManSach', 'upfront_fee':'100USD',
   'trade_spread':'45',  },
  {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges2', 'notional':'GolManSach', 'upfront_fee':'50USD',
   'trade_spread':'45',},
  {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges3', 'notional':'GolManSach', 'upfront_fee':'90USD',
 'trade_spread':'45', },
  {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges4', 'notional':'GolManSach', 'upfront_fee':'100USD',
 'trade_spread':'45',  },
 {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges1', 'notional':'GolManSach', 'upfront_fee':'100USD',
 'trade_spread':'45',  },
{'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges2', 'notional':'GolManSach', 'upfront_fee':'50USD',
 'trade_spread':'45',},
{'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges3', 'notional':'GolManSach', 'upfront_fee':'90USD',
'trade_spread':'45', },
{'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'trade_id':'Leshedges4', 'notional':'GolManSach', 'upfront_fee':'100USD',
'trade_spread':'45',  }
]

  constructor() {
    this.dataSource = new MatTableDataSource(this.tradeReportArray);
   }
   ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
