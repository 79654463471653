import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';

import { RecoverylockService } from '../../providers/recoverylock.service';
import { MatSnackBar } from '@angular/material';
import { StaticDataService } from 'src/providers/static-data.service';

@Component({
  selector: 'app-bondoption',
  templateUrl: './bondoption.component.html',
  styleUrls: ['./bondoption.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BondoptionComponent implements OnInit {
  imgstyle="ForImageIcon"

  refComponents = [
    { view: 'Holiday 1', value: 'senior_secure' },
    { view: 'Holiday 2', value: 'senior_unsecure' },
    { view: 'Holiday 3', value: 'subordinated' }
  ];


  dealForm: FormGroup;

  feeForm: FormGroup;

  bondOptionForm: FormGroup;

  startDate = new Date();

  todayDate = new Date();
  bondOptionData;
  dropdownSettings = {};
  selectedItems = [];
  selectedItems1 = [];
  selectedItems2 = [];
  selected = 0;
  searchid;
  pageType = 'new';
  allBondsData: any = [];
  exerciseValue: boolean = false;
  expiryminDate = new Date(new Date().setDate(new Date().getDate() + 1));
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public formbuilder: FormBuilder, public recoverylockService: RecoverylockService, public datePipe: DatePipe, public matSnackBar: MatSnackBar, public staticDataService: StaticDataService, public cdRef: ChangeDetectorRef) { }



  alphakeyPress(ev) {
    if (ev.key == '-' || ev.target.value.charAt(0) == '-') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    }



    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }


  getAllBonds() {
    this.staticDataService.getBond().subscribe(res => {
      this.allBondsData = res
    })
  }


  onBondSelection(i) {
    console.log(i)
  }






  gotofee() {
    if (!this.bondOptionForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.dealForm.valid && this.bondOptionForm.valid) {
      this.selected = 3;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  nextStep() {
    this.selected = 0;
    if (this.bondOptionForm.valid) {
      this.selected = 2;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }



  x = function rplaceQuantity() {
    var myquanty = this.bondOptionForm.value.notional;
    return myquanty.replace(/,/g, '');
  }



  canceldeal() {
    if (this.searchid) {
      let reqObj = {
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": this.bondOptionForm.value.strike,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": this.bondOptionForm.value.holidayCalendars,
            "instrumentName": this.bondOptionForm.value.underlying,
            "instrumentType": "string",
            "maturityDate": "2020-01-02T07:13:28.374Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            "quantity": this.x(),
            "startDate": "2020-01-02T07:13:28.374Z",
            "style": "string",
            "Settlement Date": this.bondOptionForm.value.settledate
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": 'cancelled'
        },
        "id": this.searchid,
        "memberId": "123",
        "option": {
          "exersizeDate": "2020-01-02T07:13:28.374Z",
          "exersizeType": this.bondOptionForm.value.exercisetype,
          "expiryDate": this.datePipe.transform(this.bondOptionForm.value.expirydate, 'MM-dd-yyyy'),
          "expiryTime": this.bondOptionForm.value.expirytime,
          "expiryTimeZone": this.bondOptionForm.value.timezone,
          "firstExersizeDate": this.datePipe.transform(this.bondOptionForm.value.exerciseddate, 'MM-dd-yyyy'),
          "optionStyle": this.bondOptionForm.value.style,
          "optionType": this.bondOptionForm.value.optiontype,
          "premium": 0,
          "settlementType": this.bondOptionForm.value.deliverytype
        }
      }
      this.recoverylockService.updateBondOption(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          // this.ngOnInit();
          this.pageType = "new";
          this.searchid = '';
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'snack-error'
      })
    }


  }


  cancelBtn() {
    this.selected = 0;
    this.ngOnInit();
  }


  update() {
    if (!this.dealForm.valid && !this.bondOptionForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.bondOptionForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.dealForm.valid && this.bondOptionForm.valid && this.feeForm.valid) {
      let reqObj = {
        "autoExercise": this.exerciseValue,
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": this.bondOptionForm.value.strike,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": JSON.stringify(this.selectedItems),
            "instrumentName": this.bondOptionForm.value.underlying,
            "instrumentType": "string",
            "maturityDate": "2020-01-02T07:13:28.374Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            "quantity": this.x(),
            "startDate": "2020-01-02T07:13:28.374Z",
            "style": "string",
            "Settlement Date": this.bondOptionForm.value.settledate
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },
        "id": this.searchid,
        "memberId": "123",
        "option": {
          "exersizeDate": "2020-01-02T07:13:28.374Z",
          "exersizeType": this.bondOptionForm.value.exercisetype,
          "expiryDate": this.datePipe.transform(this.bondOptionForm.value.expirydate, 'MM-dd-yyyy'),
          "expiryTime": this.bondOptionForm.value.expirytime,
          "expiryTimeZone": this.bondOptionForm.value.timezone,
          "firstExersizeDate": this.datePipe.transform(this.bondOptionForm.value.exerciseddate, 'MM-dd-yyyy'),
          "optionStyle": this.bondOptionForm.value.style,
          "optionType": this.bondOptionForm.value.optiontype,
          "premium": 0,
          "settlementType": this.bondOptionForm.value.deliverytype
        }
      }
      this.recoverylockService.updateBondOption(reqObj).subscribe(res => {
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Bond Option updated successfully.', 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          this.selected = 0;
          this.pageType = "new";
          this.ngOnInit();
          this.searchid = '';
          this.cdRef.detectChanges();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }


  showOptions(evt) {
    this.exerciseValue = evt.checked;
  }

  search() {
    if (this.searchid) {

      this.recoverylockService.getBondOption(this.searchid).subscribe(res => {
        if (res) {
          this.bondOptionData = res;
          let ccc = JSON.parse(this.bondOptionData.deal.instrument.holidayCalendars)
          let checkIcon = JSON.parse(this.bondOptionData.autoExercise)
          this.pageType = 'edit';
          this.bondOptionForm = this.formbuilder.group({
            underlying: [this.bondOptionData.deal.instrument.instrumentName, Validators.required],
            optiontype: [this.bondOptionData.option.optionType, Validators.required],
            style: [this.bondOptionData.option.optionStyle, Validators.required],
            exercisetype: [this.bondOptionData.option.exersizeType],
            deliverytype: [this.bondOptionData.option.settlementType],
            strike: [this.bondOptionData.deal.instrument.coupon, Validators.required],
            notional: [this.formatMoney(this.bondOptionData.deal.instrument.quantity)],
            expirydate: [moment(this.bondOptionData.option.expiryDate), Validators.required],
            settledate: [moment(this.bondOptionData.deal.instrument.SettlementDate)],
            exerciseddate: [moment(this.bondOptionData.option.firstExersizeDate)],
            expirytime: [this.bondOptionData.option.expiryTime, Validators.required],
            timezone: [this.bondOptionData.option.expiryTimeZone],
            holidays: [this.bondOptionForm.get('holidays').setValue(ccc)],
            autoexercise: [checkIcon],
            ir01: [''],
            npv: [''],
            cr01: ['']
          })

          this.dealForm = this.formbuilder.group({
            tradeBook: [this.bondOptionData.deal.tradeBook],
            counterParty: [this.bondOptionData.deal.otherBook],
            tradeDate: [moment(this.bondOptionData.deal.tradeDate)],
            clearingHouse: [this.bondOptionData.deal.clearingHouse, Validators.required],
            tradeStatus: [this.bondOptionData.deal.tradeStatus]
          })

          this.feeForm = this.formbuilder.group({
            broker: [this.bondOptionData.deal.fee.broker],
            currency: [this.bondOptionData.deal.fee.currency],
            feeAmount: [this.bondOptionData.deal.fee.feeAmount, Validators.required],
            feeDate: [moment(this.bondOptionData.deal.fee.feeDate)]
          })
        } else {
          this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  insert() {
    if (!this.dealForm.valid && !this.bondOptionForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.bondOptionForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.dealForm.valid && this.feeForm.valid && this.bondOptionForm.valid) {
      let reqObj = {
        "autoExercise": this.exerciseValue,
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": this.bondOptionForm.value.strike,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": JSON.stringify(this.selectedItems),
            "instrumentName": this.bondOptionForm.value.underlying,
            "instrumentType": "string",
            "maturityDate": "2020-01-02T07:13:28.374Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            "quantity": this.x(),
            "startDate": "2020-01-02T07:13:28.374Z",
            "style": "string",
            "Settlement Date": this.bondOptionForm.value.settledate
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },

        "memberId": "123",
        "option": {
          "exersizeDate": "2020-02-04T19:57:04.851Z",
          "exersizeType": this.bondOptionForm.value.exercisetype,
          "expiryDate": this.datePipe.transform(this.bondOptionForm.value.expirydate, 'MM-dd-yyyy'),
          "expiryTime": this.bondOptionForm.value.expirytime,
          "expiryTimeZone": this.bondOptionForm.value.timezone,
          "firstExersizeDate": this.datePipe.transform(this.bondOptionForm.value.exerciseddate, 'MM-dd-yyyy'),
          "optionStyle": this.bondOptionForm.value.style,
          "optionType": this.bondOptionForm.value.optiontype,
          "premium": 0,
          "settlementType": this.bondOptionForm.value.deliverytype
        }
      }
      this.recoverylockService.insertBondOption(reqObj).subscribe(res => {
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Bond Option added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          this.selected = 0;
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }




  ngOnInit() {
    console.log(this.expiryminDate)
    this.getAllBonds();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'view',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.bondOptionForm = this.formbuilder.group({
      underlying: ['', Validators.required],
      optiontype: ['', Validators.required],
      style: ['', Validators.required],
      exercisetype: ['', Validators.required],
      deliverytype: ['', Validators.required],
      strike: ['', Validators.required],
      notional: ['', Validators.required],
      expirydate: ['', Validators.required],
      settledate: ['', Validators.required],
      exerciseddate: ['', Validators.required],
      // holidays: [''],
      expirytime: ['', Validators.required],
      timezone: ['', Validators.required],
      holidays: [[], Validators.required],
      autoexercise: [false],
      ir01: [''],
      npv: [''],
      cr01: ['']
    })

    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })

    console.log(this.exerciseValue)
  }



  OnSettleDateChange(event) {
    this.expiryminDate = new Date(event._d.setDate(event._d.getDate() + 1))
  }

  onItemSelect(ev) {
    console.log(ev)

  }

  onSelectAll(ev) {
    console.log(ev)

  }


}
