import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './app.config';
@Injectable({
  providedIn: 'root'
})
export class CredittradingService {

  constructor(public AppConfig: AppConfig, public http: HttpClient, ) { }



  handleError(error) {
    //  console.log(error)
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error}`;
    } else {
      // server-side error
      errorMessage = error;
    }
    //  console.log(error)
    return throwError(errorMessage);
  }

  createCurveTrading(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'curveTrade/add', reqData, httpOptions)
      .pipe(map((res: any) => res), catchError(this.handleError))
  }




  getAllPlBuilder() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'PLBuilder/', httpOptions)
      .pipe(map((res: any) => res), catchError(this.handleError))
  }


  addPlBuilder(plData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'PLBuilder/add', plData, httpOptions)
      .pipe(map((res: any) => res), catchError(this.handleError))
  }

  getPlBulderById(plid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'PLBuilder/' + plid, httpOptions)
      .pipe(map((res: any) => res), catchError(this.handleError))
  }


  UpdatePlBuilder(plupdateData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'PLBuilder/update', plupdateData, httpOptions)
      .pipe(map((res: any) => res), catchError(this.handleError))
  }
}
