import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UserService } from '../../providers/user.service';
import { MatSnackBar } from '@angular/material';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface UserData {
  id: string;
  email: string;
  password: string;
  confirmPassword: string;
  active:boolean;
  status:string;
  changePassword:boolean;
  roles:any;
  username:string;
}

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

    displayedColumns: string[] = ['id', 'email', 'username', 'status', 'active', 'action'];
    dataSource: MatTableDataSource<UserData>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

  addMemberForm:FormGroup;

  rolesArray;

  @ViewChild(FormGroupDirective) formGroupDirective:FormGroupDirective;

  constructor(public formBuilder:FormBuilder, public userService:UserService, public matSnackBar:MatSnackBar) { }

  ngOnInit() {
    this.addMemberForm = this.formBuilder.group({
      active:[false],
      changePassword:[false],
      confirmPassword:[''],
      email:[''],
      id:[''],
      password:[''],
      role:['']

    })
    this.getUsers();
  }

  edituser(userObj){

  }

  getUsers(){
    this.userService.getUsers().subscribe(res=>{
      console.log(res)
      if(res.length > 0){
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
      }
    })
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  register(){
    this.rolesArray = [];

if(this.addMemberForm.value.role == 'admin'){
  this.rolesArray =  [
    {
      "id":'1',
      "name":'Admin',
      "privileges": [
        {
          "id": 0,
          "name": "Credit Trading",
          "roles": [
          
          ]
        },
        {
          "id": 1,
          "name": "Trading",
          "roles": [
         
          ]
        },
        {
          "id": 2,
          "name": "Bond Trading",
          "roles": [
         
          ]
        },
        {
          "id": 3,
          "name": "Algo Trading",
          "roles": [
           
          ]
        },
        {
          "id": 4,
          "name": "Reference Data",
          "roles": [
          
          ]
        },
        {
          "id": 5,
          "name": "Create Role / Custome Role",
          "roles": [
          
          ]
        },
        {
          "id": 6,
          "name": "Dashboard",
          "roles": [
          
          ]
        },
        {
          "id": 7,
          "name": "Reports",
          "roles": [
          
          ]
        },
        {
          "id": 8,
          "name": "Assign role to update user part",
          "roles": [
          
          ]
        }
      ]
    }
  ]
}

let reqObj = {
  "active": this.addMemberForm.value.active,
  "changePassword": true,
  "confirmPassword": this.addMemberForm.value.confirmPassword,
  "email": this.addMemberForm.value.email,
  "id": this.addMemberForm.value.id,
  "password": this.addMemberForm.value.password,
  "roles": this.rolesArray,
  "status": "test"
}

this.userService.adduser(reqObj).subscribe(res=>{
  // console.log(res)
  if(res.status == 'ok'){
this.matSnackBar.open('User added Successfully', 'OK',{
  verticalPosition:'top',
  duration:2000,
  panelClass:'snack-success'
})
this.formGroupDirective.resetForm();
this.getUsers();
  }else{
    this.matSnackBar.open(res.message, 'OK',{
      verticalPosition:'top',
      duration:2000,
      panelClass:'snack-error'
    })
  }
},error=>{
  // console.log(error)
  this.matSnackBar.open(error.error.errors[0].defaultMessage, 'OK', {
    verticalPosition: 'top',
    duration        : 3000,
    panelClass: 'snack-error'
});
})

  }

}
