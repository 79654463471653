import { Component, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-forgotpswddialog',
  templateUrl: './forgotpswddialog.component.html',
  styleUrls: ['./forgotpswddialog.component.css']
})
export class ForgotpswddialogComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  forgotPswdForm: FormGroup
  constructor(public formBuilder: FormBuilder, public dialogRef: MatDialogRef<LoginComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  name: any;

  ngOnInit() {
    this.forgotPswdForm = this.formBuilder.group({
      email: ['', Validators.required],
    })
  }

  save() {
    if (this.forgotPswdForm.valid) {
      const data = this.forgotPswdForm.value;
      this.submitClicked.emit(data);
      this.dialogRef.close();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
