import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restructuring',
  templateUrl: './restructuring.component.html',
  styleUrls: ['./restructuring.component.css']
})
export class RestructuringComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
