import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UserService } from 'src/providers/user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  changePswdForm: FormGroup;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;
  constructor(public formbuilder: FormBuilder, public changePswdservice: UserService, public matSnackBar: MatSnackBar, public router: Router) { }

  ngOnInit() {
    console.log(localStorage.getItem('email'))
    this.changePswdForm = this.formbuilder.group({
      email: [{
        value: localStorage.getItem('email'),
        disabled: true
      },],
      newpswd: ['', Validators.required],
      confirmpswd: ['', Validators.required],
    })
  }


  changePswd() {
    if (this.changePswdForm.valid) {

      if (this.changePswdForm.value.newpswd !== this.changePswdForm.value.confirmpswd) {
        this.matSnackBar.open('Password & Confirm Password Not Matches', '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      } else {
        let reqObj = {
          "email": localStorage.getItem('email'),
          "confirmPassword": this.changePswdForm.value.newpswd,
          "password": this.changePswdForm.value.confirmpswd
        }
        this.changePswdservice.resetPassword(reqObj).subscribe(res => {
          if (res.status == 'ok') {
            this.fromGroupDirective.resetForm();
            this.matSnackBar.open('Password Changed Successfully', '', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-success'
            })
            this.router.navigate(['/login'])
            localStorage.clear();
          } else {
            this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'snack-error'
            })
          }
        })
      }
    } else {
      this.matSnackBar.open('Please Enter Mandatory Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

}
