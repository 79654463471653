import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RecoverylockService } from '../../providers/recoverylock.service';
import { MatSnackBar } from '@angular/material';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdsService } from '../../providers/cds.service';
import { CreditIndexoptionService } from '../../providers/credit-indexoption.service'

import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

import { default as _rollupMoment } from 'moment';
import { CreditReferenceService } from 'src/providers/credit-reference.service';

const moment = _rollupMoment || _moment;

export interface RecoveryData {
  id: string;
  memberId: string;
  instrument: any;
  risk: any;
  deal: any;
  fee: any;
}

@Component({
  selector: 'app-creditindexoption',
  templateUrl: './creditindexoption.component.html',
  styleUrls: ['./creditindexoption.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CreditindexoptionComponent implements OnInit {

  imgstyle="ForImageIcon"

  displayedColumns: string[] = ['dealid', 'creditSeniority', 'paymentDelay', 'buySell', 'action'];
  dataSource: MatTableDataSource<RecoveryData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  instrumentForm: FormGroup;

  dealForm: FormGroup;

  feeForm: FormGroup;

  coupon = 0;

  tradespread = 0;

  qty = 0;


  currency = 'usd';

  selected = 0;

  startDate = new Date();

  recoveryLockList = [];

  viewDeal;

  view_more: boolean = false;

  showForm: boolean = true;

  todayDate = new Date();
  matTerm: any;

  searchid;

  referenceEntity = [
    { view: 'CDX.NA.HY 33 1', value: 'CDX.NA.HY_33_1' },
    { view: 'CDX.NA.HY.B 33 1', value: 'CDX.NA.HY.B_33_1' },
    { view: 'CDX.NA.HY.BB 33 1', value: 'CDX.NA.HY.BB_33_1' },
    { view: 'CDX.EM 32 1', value: 'CDX.EM_32_1' },
    { view: 'CDX.EM.DIVERSIFIED 12 4', value: 'CDX.EM.DIVERSIFIED_12_4' },
    { view: 'CDX.NA.IG 33 1', value: 'CDX.NA.IG_33_1' },
    { view: 'CDX.NA.IG.HVOL 33 1', value: 'CDX.NA.IG.HVOL_33_1' },
    { view: 'CDX.NA.XO 11 4', value: 'CDX.NA.XO_11_4' },
    { view: 'iTraxx Europe 32 1', value: 'iTraxx_Europe_32_1' },
    { view: 'iTraxx Europe HiVol 20 1', value: 'iTraxx_Europe_HiVol 20 1' },
    { view: 'iTraxx Europe Crossover 32 1', value: 'iTraxx_Europe_Crossover_32_1' },
    { view: 'iTraxx Japan 32 1', value: 'iTraxx_Japan_32_1' },
    { view: 'iTraxx Australia 32 1', value: 'iTraxx_Australia_32_1' },
    { view: 'iTraxx Asia ex-Japan IG 32 1', value: 'iTraxx_Asia_ex-Japan_IG_32_1' },
    { view: 'iTraxx SDI-75 4 3', value: 'iTraxx_SDI-75_4_3' },
    { view: 'iTraxx SovX Western Europe 8 1', value: 'iTraxx_SovX_Western_Europe_8_1' },
    { view: 'iTraxx SovX CEEMEA 11 2', value: 'iTraxx_SovX_CEEMEA_11_2' },
    { view: "iTraxx SovX Global Liquid Investment Grade 14 1", value: 'iTraxx_SovX_Global_Liquid_Investment_Grade_14_1' },
    { view: 'iTraxx SovX G7 14 1', value: 'iTraxx_SovX_G7_14_1' },
    { view: 'iTraxx SovX BRIC 14 1', value: 'iTraxx_SovX_BRIC_14_1' },
    { view: 'iTraxx SovX Asia Pacific 10 1', value: 'iTraxx_SovX Asia_Pacific_10_1' },
    { view: 'LCDX.NA 21 5', value: 'LCDX.NA_21_5' },
    { view: 'iTraxx LevX Senior 6 18', value: 'iTraxx_LevX_Senior_6_18' },
    { view: 'iTraxx LevX Subordinated 3 21', value: 'iTraxx_LevX_Subordinated_3_21' },
    { view: 'MCDX.NA 33 1', value: 'MCDX.NA_33_1' }
  ]

  pageType = 'new';
  creditIndexData: any = [];
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public formbuilder: FormBuilder, public recoveryService: RecoverylockService,
    public matSnackBar: MatSnackBar, public datePipe: DatePipe, public cdsservice: CdsService,
    public creditOption: CreditIndexoptionService, public creditrefenceService: CreditReferenceService) { }

  quantityadded1(ev) {
    console.log('dsfdsfsd')
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
  }

  alphakeyPress(ev) {
    // ev.target.value = ev.target.value.toLocaleString()
    if (this.instrumentForm.value.buySell == 'sell') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    }
    // console.log(ev)

    // console.log((parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
    // console.log(ev)
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }


  OnStartDateChange(evt) {
    let selctDate = evt._d.getFullYear() + parseInt(this.matTerm)
    let finaldate = this.datePipe.transform(evt._d, 'MM/dd') + '/' + selctDate;
    this.instrumentForm.controls['maturity'].setValue(moment(finaldate))
    this.startDate = evt._d;
  }


  maturityChange(ev) {
    // console.log(ev)
    this.matTerm = ev.value;
    let maturity_date = this.startDate.getFullYear() + parseInt(ev.value);

    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;

    this.instrumentForm.controls['maturity'].setValue(moment(finaldate))
  }




  ngOnInit() {
    this.getAllcreditIndexes();
    // console.log(this.startDate.getFullYear())
    var valuationDate;

    valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.startDate.setDate(this.startDate.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 2)
    }

    let maturity_date = this.startDate.getFullYear() + 5;


    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;



    this.instrumentForm = this.formbuilder.group({

      referenceEntity: ['', Validators.required],
      seniority: ['senior_unsecure', Validators.required],
      restructuring: ['nr', Validators.required],
      currency: ['usd', Validators.required],
      option_type: ['call', Validators.required],
      startDate: [moment(this.startDate), Validators.required],
      maturityterm: ['', Validators.required],
      maturity: ['', Validators.required],
      paymentDelay: ['quaterly', Validators.required],
      coupon: ['500', Validators.required],
      index_factor: ['1'],
      dateRole: ['modified_following', Validators.required],
      dayCount: ['act/360', Validators.required],
      quantity: ['5000000', Validators.required],
      buySell: ['buy', Validators.required],
      // hasFixedRec: ['40%', Validators.required],
      tradespread: ['500', Validators.required],
      upfrontfee: ['', Validators.required],
      upfrontamount: ['', Validators.required],
      valuationDate: [this.todayDate, Validators.required],
      cr01: [''],
      ir01: [''],
      mtm: [''],
      parRate: [''],

    })

    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })




    this.quantityadded('');
    this.selectbuysell({ value: 'buy' });
  }


  // defFormValues() {
  //   var valuationDate;

  //   valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

  //   if (valuationDate == 'Saturday') {
  //     this.startDate.setDate(this.startDate.getDate() + 3);
  //     // console.log(this.instrumentForm.value.startDate)
  //   } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
  //     // console.log(valuationDate)
  //     this.startDate.setDate(this.startDate.getDate() + 4)
  //   } else {
  //     // console.log(valuationDate)
  //     this.startDate.setDate(this.startDate.getDate() + 2)
  //   }

  //   let maturity_date = this.startDate.getFullYear() + 5;


  //   let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;



  //   this.instrumentForm = this.formbuilder.group({

  //     referenceEntity: [''],
  //     seniority: ['senior_unsecure'],
  //     restructuring: ['nr'],
  //     currency: ['usd'],
  //     option_type: ['call'],
  //     startDate: [moment(this.startDate)],
  //     maturityterm: [''],
  //     maturity: [''],
  //     paymentDelay: ['quaterly'],
  //     coupon: ['500'],
  //     index_factor: ['1'],
  //     dateRole: ['modified_following'],
  //     dayCount: ['act/360'],
  //     quantity: ['5000000'],
  //     buySell: ['buy'],
  //     // hasFixedRec: ['40%', Validators.required],
  //     tradespread: ['500'],
  //     upfrontfee: [''],
  //     upfrontamount: [''],
  //     valuationDate: [this.todayDate],
  //     cr01: [''],
  //     ir01: [''],
  //     mtm: [''],
  //     parRate: [''],

  //   })

  //   this.dealForm = this.formbuilder.group({
  //     tradeBook: ['book1'],
  //     counterParty: ['cpt1'],
  //     tradeDate: [this.todayDate],
  //     clearingHouse: [''],
  //     tradeStatus: ['booked']
  //   })

  //   this.feeForm = this.formbuilder.group({
  //     broker: ['broker1'],
  //     currency: ['usd'],
  //     feeAmount: [''],
  //     feeDate: [moment(this.startDate)]
  //   })

  //   this.selectbuysell({ value: 'buy' });
  // }

  nextStep() {
    this.selected = 0;
    if (this.instrumentForm.valid) {
      this.selected = 2;

    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }




  selectbuysell(ev) {

    if (ev.value == 'buy') {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue((parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
      }, 100);
    } else {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue('-' + (parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'));
      }, 100);
    }
    // if (ev.value == 'buy') {
    //   // console.log('buy  this.instrumentForm.value.quantity' + formatMoney(Math.abs(this.instrumentForm.value.quantity)))
    //   if (this.instrumentForm.value.quantity.includes(',')) {
    //     //   console.log('but includes' + this.instrumentForm.value.quantity)
    //     // console.log('buy includes' + this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(this.instrumentForm.value.quantity.split('-')[1])
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(this.formatMoney(Math.abs(this.instrumentForm.value.quantity)))
    //   }
    // } else {
    //   if (this.instrumentForm.value.quantity.includes(',')) {
    //     // console.log(this.instrumentForm.value.quantity.replace (/^/,'-'))
    //     this.instrumentForm.controls['quantity'].setValue(this.instrumentForm.value.quantity.replace(/^/, '-'));
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.instrumentForm.controls['quantity'].setValue(this.formatMoney(-Math.abs(this.instrumentForm.value.quantity)));
    //   }
    // }
  }

  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }


  selectCredit(ev) {
    if (ev.value == 'stem') {
      this.instrumentForm.controls['restructuring'].setValue('mm');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'stac') {
      this.instrumentForm.controls['restructuring'].setValue('nr');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'snac') {
      this.instrumentForm.controls['restructuring'].setValue('mr');
      this.instrumentForm.controls['coupon'].setValue('500');
    }
  }

  quantityadded(event) {
    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity.replace(/,/g, '')) / 10000)
  }

  gotofee() {
    if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid) {
      this.selected = 3;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  addquantity(event) {
    let val = this.instrumentForm.value.quantity.replace(/,/g, '')
    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * val) / 10000)
  }

  buySellValue: any;

  search() {


    function formatMoney(number) {
      return number.toLocaleString('en-US', { currency: 'USD' });
    }





    if (this.searchid) {
      this.creditOption.ceditindexoptionget(this.searchid).subscribe(res => {
        if (res) {
          this.recoveryLockList = res;
          this.viewDeal = this.recoveryLockList;
          this.pageType = 'edit';


          if (this.viewDeal.cdsindex.deal.buySell == 'buy') {
            this.buySellValue = formatMoney(Math.abs(this.viewDeal.cdsindex.deal.instrument.quantity))
          } else {
            this.buySellValue = formatMoney(-Math.abs(this.viewDeal.cdsindex.deal.instrument.quantity));
          }





          this.instrumentForm = this.formbuilder.group({
            buySell: [this.viewDeal.cdsindex.deal.buySell],
            coupon: [JSON.stringify(this.viewDeal.cdsindex.deal.instrument.coupon)],
            index_factor: ['1'],
            option_type: ['call', Validators.required],
            // creditTemplate: [this.viewDeal.deal.instrument.style],
            referenceEntity: [this.viewDeal.cdsindex.creditDefaultSwap.creditName],
            seniority: [this.viewDeal.cdsindex.creditDefaultSwap.creditSeniority],
            restructuring: [this.viewDeal.cdsindex.creditDefaultSwap.creditRestructuring],
            currency: [this.viewDeal.cdsindex.deal.instrument.currency],
            startDate: [moment(this.viewDeal.cdsindex.deal.instrument.startDate)],
            maturity: [moment(this.viewDeal.cdsindex.deal.instrument.maturityDate)],
            paymentDelay: [this.viewDeal.cdsindex.deal.instrument.pmtFrequency],
            dateRole: [this.viewDeal.cdsindex.deal.instrument.dateRole],
            dayCount: [this.viewDeal.cdsindex.deal.instrument.dayCount],
            quantity: [this.buySellValue, Validators.required],
            maturityterm: [''],
            tradespread: [this.viewDeal.cdsindex.deal.instrument.price, Validators.required],
            upfrontfee: [this.viewDeal.cdsindex.creditDefaultSwap.upfrontFee, Validators.required],
            upfrontamount: [this.viewDeal.cdsindex.creditDefaultSwap.upfrontAmount, Validators.required],
            valuationDate: [this.todayDate],
            cr01: [''],
            ir01: [''],
            mtm: [''],
            parRate: ['']
          })

          this.dealForm = this.formbuilder.group({
            tradeBook: [this.viewDeal.cdsindex.deal.tradeBook],
            counterParty: [this.viewDeal.cdsindex.deal.otherBook, Validators.required],
            tradeDate: [moment(this.viewDeal.cdsindex.deal.tradeDate)],
            clearingHouse: [this.viewDeal.cdsindex.deal.clearingHouse],
            tradeStatus: [this.viewDeal.cdsindex.deal.tradeStatus]
          })

          this.feeForm = this.formbuilder.group({
            broker: [this.viewDeal.cdsindex.deal.fee.broker],
            currency: [this.viewDeal.cdsindex.deal.fee.currency],
            feeAmount: [this.viewDeal.cdsindex.deal.fee.feeAmount, Validators.required],
            feeDate: [moment(this.viewDeal.cdsindex.deal.fee.feeDate)]
          })
        } else {
          this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  showAll() {
    this.viewDeal = '';
    this.showForm = false;
  }

  showform() {
    this.showForm = true;
  }

  view(dealObj) {
    this.viewDeal = dealObj;
  }




  couponChange(ev) {
    this.instrumentForm.controls['tradespread'].setValue(ev.value);
    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
  }

  getAllcreditIndexes() {
    this.creditrefenceService.getCreditIndex().subscribe(res => {
      this.creditIndexData = res
      console.log(this.creditIndexData)
    })
  }

  creditIndexName: any;
  oncdSelection(i) {
    console.log(i)
    this.creditIndexName = i.indexCompany + ' ' + i.indexName + ' ' + i.version + ' ' + 'S' + ' ' + i.series
    console.log(this.creditIndexName)
  }
  newStr: any;
  saveRecovery() {
    var myquantity = this.instrumentForm.value.quantity;
    this.newStr = myquantity.replace(/,/g, '');

    if (!this.instrumentForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deals', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj = {

        "cdsindex": {
          "cdsbasket": {
            "creditNames": [
              "string"
            ],
            "factors": [
              0
            ],
            "weights": [
              0
            ]
          },
          "creditDefaultSwap": {
            "creditAccruedStyle": "string",
            "creditId": "string",
            // "creditName": this.instrumentForm.value.referenceEntity,
            "creditName": this.creditIndexName,
            "creditRestructuring": this.instrumentForm.value.restructuring,
            "creditSeniority": this.instrumentForm.value.seniority,
            "hasFixedRec": this.instrumentForm.value.hasFixedRec,
            "protectionEndDate": "2019-12-04T13:33:40.379Z",
            "protectionStartDate": "2019-12-04T13:33:40.379Z",
            "upfrontAmount": this.instrumentForm.value.upfrontfee,
            "upfrontFee": this.instrumentForm.value.upfrontamount,
          },
          "deal": {
            "buySell": this.instrumentForm.value.buySell,
            "clearingHouse": this.dealForm.value.clearingHouse,
            "fee": {
              "broker": this.feeForm.value.broker,
              "currency": this.feeForm.value.currency,
              "feeAmount": this.feeForm.value.feeAmount,
              "feeDate": this.feeForm.value.feeDate
            },
            "instrument": {

              "code": "string",
              "coupon": this.instrumentForm.value.coupon,
              "csaTerm": "string",
              "currency": this.instrumentForm.value.currency,
              "dayCount": this.instrumentForm.value.dayCount,
              "dateRole": this.instrumentForm.value.dateRole,
              "hasCustomCouponSchedule": "string",
              "holidayCalendars": "string",

              "instrumentName": "string",
              "instrumentType": "string",
              "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
              "paymentDelay": "string",
              "pmtFrequency": this.instrumentForm.value.paymentDelay,
              "price": this.instrumentForm.value.tradespread,
              "quantity": this.newStr,
              "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
              "style": this.instrumentForm.value.creditTemplate,

            },
            "otherBook": this.dealForm.value.counterParty,
            "settlementLocation": "string",
            "tradeBook": this.dealForm.value.tradeBook,
            "tradeDate": this.dealForm.value.tradeDate,
            "tradeStatus": this.dealForm.value.tradeStatus
          },

          "indexName": "123",
          "memberId": "123"
        },

      }



      this.creditOption.ceditindexoption(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Credit Index Option added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.selected = 0;
          this.fromGroupDirective.resetForm();
          this.ngOnInit();

        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  x = function rplaceQuantity() {
    var myquanty = this.instrumentForm.value.quantity;
    return myquanty.replace(/,/g, '');
  }

  canceldeal() {
    if (this.searchid) {
      let reqObj = {


        "cdsindex": {
          "cdsbasket": {
            "creditNames": [
              "string"
            ],
            "factors": [
              0
            ],
            "weights": [
              0
            ]
          },
          "creditDefaultSwap": {
            "creditAccruedStyle": "string",
            "creditId": "string",
            "creditName": this.instrumentForm.value.referenceEntity,
            "creditRestructuring": this.instrumentForm.value.restructuring,
            "creditSeniority": this.instrumentForm.value.seniority,
            "hasFixedRec": this.instrumentForm.value.hasFixedRec,
            "protectionEndDate": "2019-12-04T13:33:40.379Z",
            "protectionStartDate": "2019-12-04T13:33:40.379Z",
            "upfrontAmount": this.instrumentForm.value.upfrontfee,
            "upfrontFee": this.instrumentForm.value.upfrontamount,
          },
          "deal": {
            "buySell": this.instrumentForm.value.buySell,
            "clearingHouse": this.dealForm.value.clearingHouse,
            "fee": {
              "broker": this.feeForm.value.broker,
              "currency": this.feeForm.value.currency,
              "feeAmount": this.feeForm.value.feeAmount,
              "feeDate": this.feeForm.value.feeDate
            },
            "instrument": {

              "code": "string",
              "coupon": this.instrumentForm.value.coupon,
              "csaTerm": "string",
              "currency": this.instrumentForm.value.currency,
              "dayCount": this.instrumentForm.value.dayCount,
              "dateRole": this.instrumentForm.value.dateRole,
              "hasCustomCouponSchedule": "string",
              "holidayCalendars": "string",

              "instrumentName": "string",
              "instrumentType": "string",
              "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
              "paymentDelay": "string",
              "pmtFrequency": this.instrumentForm.value.paymentDelay,
              "price": this.instrumentForm.value.tradespread,
              "quantity": this.x(),
              "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
              "style": this.instrumentForm.value.creditTemplate,

            },
            "otherBook": this.dealForm.value.counterParty,
            "settlementLocation": "string",
            "tradeBook": this.dealForm.value.tradeBook,
            "tradeDate": this.dealForm.value.tradeDate,
            "tradeStatus": 'cancelled'
          },
          "id": this.searchid,
          "indexName": "123",
          "memberId": "123"
        },
        "id": this.searchid,
      }

      this.creditOption.creditindexoptionUpdate(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.searchid = '';
          this.pageType = 'new'
          this.fromGroupDirective.resetForm();
          this.ngOnInit();
          // this.ngOnInit();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'snack-error'
      })
    }


  }

  cancelBtn() {
    this.selected = 0;
    this.ngOnInit();
  }



  update() {
    if (!this.instrumentForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deals', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj = {


        "cdsindex": {
          "cdsbasket": {
            "creditNames": [
              "string"
            ],
            "factors": [
              0
            ],
            "weights": [
              0
            ]
          },
          "creditDefaultSwap": {
            "creditAccruedStyle": "string",
            "creditId": "string",
            "creditName": this.instrumentForm.value.referenceEntity,
            "creditRestructuring": this.instrumentForm.value.restructuring,
            "creditSeniority": this.instrumentForm.value.seniority,
            "hasFixedRec": this.instrumentForm.value.hasFixedRec,
            "protectionEndDate": "2019-12-04T13:33:40.379Z",
            "protectionStartDate": "2019-12-04T13:33:40.379Z",
            "upfrontAmount": this.instrumentForm.value.upfrontfee,
            "upfrontFee": this.instrumentForm.value.upfrontamount,
          },
          "deal": {
            "buySell": this.instrumentForm.value.buySell,
            "clearingHouse": this.dealForm.value.clearingHouse,
            "fee": {
              "broker": this.feeForm.value.broker,
              "currency": this.feeForm.value.currency,
              "feeAmount": this.feeForm.value.feeAmount,
              "feeDate": this.feeForm.value.feeDate
            },
            "instrument": {

              "code": "string",
              "coupon": this.instrumentForm.value.coupon,
              "csaTerm": "string",
              "currency": this.instrumentForm.value.currency,
              "dayCount": this.instrumentForm.value.dayCount,
              "dateRole": this.instrumentForm.value.dateRole,
              "hasCustomCouponSchedule": "string",
              "holidayCalendars": "string",

              "instrumentName": "string",
              "instrumentType": "string",
              "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
              "paymentDelay": "string",
              "pmtFrequency": this.instrumentForm.value.paymentDelay,
              "price": this.instrumentForm.value.tradespread,
              "quantity": this.x(),
              "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
              "style": this.instrumentForm.value.creditTemplate,

            },
            "otherBook": this.dealForm.value.counterParty,
            "settlementLocation": "string",
            "tradeBook": this.dealForm.value.tradeBook,
            "tradeDate": this.dealForm.value.tradeDate,
            "tradeStatus": this.dealForm.value.tradeStatus
          },
          "id": this.searchid,
          "indexName": "123",
          "memberId": "123"
        },
        "id": this.searchid,
      }

      this.creditOption.creditindexoptionUpdate(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Credit Index Option updated successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.pageType = "new"
          this.selected = 0;
          this.searchid = '';
          this.fromGroupDirective.resetForm();
          this.ngOnInit();
          // this.fromGroupDirective.resetForm();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

}
