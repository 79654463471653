import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { CreditReferenceService } from 'src/providers/credit-reference.service';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { default as _rollupMoment } from 'moment';
import { CredittradingService } from 'src/providers/credittrading.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-curvetrade',
  templateUrl: './curvetrade.component.html',
  styleUrls: ['./curvetrade.component.css'],
  providers: [
    // {CurrencyPipe},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CurvetradeComponent implements OnInit {

  todaycurveAnalysis: FormGroup;
  horizonforward: FormGroup;
  getSpreadTab: boolean = false;
  refentitiyData: any = [];
  instrumentForm: FormGroup;
  startDate = new Date();
  curverisk: FormGroup
  carryAnalysis: FormGroup;
  todayTradeLevelsForm: FormGroup;
  plNumber: FormGroup;
  selected = 0;
  curveanalysisForm: FormGroup;
  todaytradeForm: FormGroup;
  todaytradedurationForm: FormGroup;
  curveDetailsTable = false;
  curveTradingTable = false;

  displayedColumns: string[] = ['mip', 'recovery', 'Tenor', 'maturity', 'spreads', 'upfronts', 'Include', 'EqSpread', 'EqSpreadiff', 'UpfrontDiff', 'Strikes', 'Upfronts',
    'CDSWFee',
    'DV01',
    'Annuity',
    'ProbDefault',
    'MarginalProb',
    'Carry',
    'Par',
    'Slide',
    'PL'];

  displayedColumns1: string[] = ['buy', 'fee', 'position', 'tenure', 'maturity', 'probdef', 'upfronts', 'prob', 'exspread', 'margprob'];

  displayedColumns2: string[] = ['date1', 'date2', 'date3', 'date4'];

  displayedColumns3: string[] = ['maturity', 'date1', 'date2', 'date4'];

  displayedColumns4: string[] = ['buysell', 'tenor', 'maturity', 'fee', 'upfront', 'eqspread', 'probDef', 'MargProb', 'dvo1']

  displayedColumns5: string[] = ['dVO1', 'annuity', 'probDefault', 'marginalProb']

  displayedColumns6: string[] = ['cary', 'par', 'annuit', 'slid', 'pl']

  displayedColumns7: string[] = ['tenor', 'maturity', 'spread', 'upfrnt', 'lcds', 'action']
  displayedColumns8: string[] = ['crdtname', 'cpn', 'ticker', 'recry', 'tenr', 'matrity', 'spreads', 'eqsprd', 'eqsprddif', 'strke', 'upfornt', 'CDSWFee', 'action']



  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  dataSource1 = new MatTableDataSource<CurveTrade>(curveTrade);

  dataSource2 = new MatTableDataSource<GraphBasedTable>(GraphTable);

  dataSource3 = new MatTableDataSource<CurveBuySell>(CurveBuy);

  dataSource4 = new MatTableDataSource<todaytrade>(todayTrade)

  datasource5 = new MatTableDataSource<curverisk>(curveRisk)

  datasource6 = new MatTableDataSource<caryAnalysis>(carryanalysis)
  datasource7 = new MatTableDataSource<horizon>(Horizon)
  datasource8 = new MatTableDataSource<todaycurve>(Todaycurve)


  @ViewChild(MatPaginator) paginator: MatPaginator;

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: '#500e8e',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }


  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;



  public randomize(): void {
    const lineChartData: ChartDataSets[] = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      lineChartData[i] = { data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        lineChartData[i].data[j] = Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
      }
    }
    this.lineChartData = lineChartData;
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public hideOne() {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }





  constructor(public formbuilder: FormBuilder, public matSnackBar: MatSnackBar, public creditrefenceService: CreditReferenceService, public datePipe: DatePipe, public curveTrading: CredittradingService) { }

  ngOnInit() {
    this.getReferencenties();
    this.dataSource.paginator = this.paginator;
    this.dataSource1.paginator = this.paginator;
    this.dataSource2.paginator = this.paginator;
    this.dataSource3.paginator = this.paginator;
    this.datasource5.paginator = this.paginator;
    this.datasource6.paginator = this.paginator;
    this.datasource7.paginator = this.paginator;



    var valuationDate;

    valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.startDate.setDate(this.startDate.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 2)
    }

    let maturity_date = this.startDate.getFullYear() + 5;
    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;
    console.log(finaldate)

    this.instrumentForm = this.formbuilder.group({
      coupon: ['500', Validators.required],
      creditTemplate: ['snac', Validators.required],
      refEntity: ['', Validators.required],
      senioiry: ['senior_unsecure', Validators.required],
      restructing: ['', Validators.required],
      // currncy: ['usd', Validators.required],
      maturity_Date: [moment(finaldate), Validators.required],
      start_Date: [this.startDate, Validators.required],
      // paymentFreq: ['option3', Validators.required],
      notional: ['10,000,000', Validators.required]
    })

    this.todaycurveAnalysis = this.formbuilder.group({
      creditName: ['', Validators.required],
      coupon: ['', Validators.required],
      rcovery: ['50%', Validators.required],
      ticker: ['', Validators.required],
      tenor: ['', Validators.required],
      maturity_date: [moment(finaldate), Validators.required],
      spredas: ['', Validators.required],
      strikes: ['500', Validators.required],
      upfornt: ['', Validators.required],
      eqspread: ['', Validators.required],
      eqspreddiff: ['', Validators.required],
      upforntdiff: ['', Validators.required],
      todayCurveCDSWFee: ['', Validators.required],
      todayCurveUpfront: ['', Validators.required],
      todayCurveRecovery: ['40%', Validators.required]
    })


    this.curveanalysisForm = this.formbuilder.group({
      curveItraxSearch: ['', Validators.required],
      curveName: ['', Validators.required],
      curveAnalyCurrency: ['usd', Validators.required],
      curveId: ['', Validators.required],
      immdates: ['', Validators.required],
      curveAnaltodaydtae: [this.startDate, Validators.required],
      curveAnalhorizndtae: [moment(finaldate), Validators.required],
      curveAnalnotional: ['10,000,000', Validators.required],
      curveAnalrecry: ['50%', Validators.required],
      indexvalue: [false]
    })



    this.todaytradeForm = this.formbuilder.group({
      strategy: ['', Validators.required],
      todaytradebuysell: ['', Validators.required],
      todaytradeTenor: ['', Validators.required],
      todaytradematurity_Date: [moment(finaldate), Validators.required],
      todaytradedurationbuysell: ['', Validators.required],
      todaytradedurationTenor: ['', Validators.required],
      todaytradedurationmatur: [moment(finaldate), Validators.required],
    })

    this.todaytradedurationForm = this.formbuilder.group({
      todaytradedurationbuysell: ['', Validators.required],
      todaytradedurationTenor: ['', Validators.required],
      todaytradedurationmatur: [moment(finaldate), Validators.required],
    })

    this.curverisk = this.formbuilder.group({
      dvo1: ['', Validators.required],
      annuity: ['', Validators.required],
      probdefault: ['', Validators.required],
      marginalProb: ['', Validators.required],
    })


    this.horizonforward = this.formbuilder.group({
      horizoncds: ['', Validators.required],
      horizontenor: ['', Validators.required],
      horizonmaturity_date: [moment(finaldate), Validators.required],
      horizonspreda: ['', Validators.required],
      horizonupfornts: ['', Validators.required],
    })

    this.carryAnalysis = this.formbuilder.group({
      carry: ['', Validators.required],
      par: ['', Validators.required],
      analysisAnnuity: ['', Validators.required],
      slide: ['', Validators.required],
      analysispL: ['', Validators.required],
    })

    this.todayTradeLevelsForm = this.formbuilder.group({
      todaybuySell: ['', Validators.required],
      todayTenor: ['', Validators.required],
      todaymaturityDate: [moment(finaldate), Validators.required],
      todayFee: ['', Validators.required],
      todayUpfront: ['', Validators.required],
      todayPosition: ['', Validators.required],
      todayEqSpread: ['', Validators.required],
      todayProbDef: ['', Validators.required],
      todayMargProb: ['', Validators.required],
      todayDVO1: ['', Validators.required],
    })

    this.plNumber = this.formbuilder.group({
      plNumberUpfront: ['', Validators.required],
      plNumberCarry: ['', Validators.required],
      plNumberSpread: ['', Validators.required],
      plNumberMtM: ['', Validators.required],
      plNumberPL: ['', Validators.required],
    })
    this.selectCredit({ value: 'snac' })
  }

  // next(evt) {
  //   console.log(evt)
  //   if (evt == 'todaycurve') {
  //     this.selected = 1;
  //   } else if (evt == 'CurveRisk') {
  //     this.selected = 2;
  //   } else if (evt == 'carryanalysis') {
  //     this.selected = 3;
  //   }
  // }


  getSprdCUrveDetls() {
    if (this.instrumentForm.valid) {
      this.curveDetailsTable = true;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  curveTradRun() {
    if (this.todaytradeForm.valid) {
      this.curveTradingTable = true;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }






  alphakeyPress(ev) {
    // ev.target.value = ev.target.value.toLocaleString()
    if (this.instrumentForm.value.buySell == 'sell') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    }
    // console.log(ev)

    // console.log((parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
    // console.log(ev)
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }







  // back(evt) {
  //   console.log(evt)
  //   if (evt == 'CurveRisk') {
  //     this.selected = 0;
  //   } else if (evt == 'carryanalysis') {
  //     this.selected = 1;
  //   } else if (evt == 'horizonfrwd') {
  //     this.selected = 2;
  //   }
  // }


  selectCredit(ev) {
    if (ev.value == 'stem') {
      this.instrumentForm.controls['restructing'].setValue('mm');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'stac') {
      this.instrumentForm.controls['restructing'].setValue('nr');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'snac') {
      this.instrumentForm.controls['restructing'].setValue('mr');
      this.instrumentForm.controls['coupon'].setValue('500');
    }
  }




  submitHorizn() {
    if (this.horizonforward.valid) {

    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  creditNameChnage(event) {
    if (event.value == 'snac') {
      this.todaycurveAnalysis.controls['coupon'].setValue('500');
    }

    if (event.value == 'stem') {
      this.todaycurveAnalysis.controls['coupon'].setValue('100');
    }

    if (event.value == 'stac') {
      this.todaycurveAnalysis.controls['coupon'].setValue('100');
    }
  }


  getSpread() {
    console.log(this.curveanalysisForm.value.indexvalue)
    if (this.curveanalysisForm.valid) {
      this.getSpreadTab = true;
      //   let Obj = {
      //     "IMMDates": this.datePipe.transform(this.curveanalysisForm.value.immdates, 'MM-dd-yyyy') 
      //     "currency": this.curveanalysisForm.value.curveAnalyCurrency,
      //     "curveName": this.curveanalysisForm.value.curveName,
      //     "horizonDate":this.datePipe.transform(this.curveanalysisForm.value.curveAnalhorizndtae, 'MM-dd-yyyy')
      //     "immdates": this.curveanalysisForm.value.immdates,
      //     "index": this.curveanalysisForm.value.indexvalue,
      //     "ltraxSearch": this.curveanalysisForm.value.curveItraxSearch,
      //     "notional": this.curveanalysisForm.value.curveAnalnotional,
      //     "recovery": this.curveanalysisForm.value.curveAnalrecry,
      //     "todayDate": this.datePipe.transform(this.curveanalysisForm.value.curveAnaltodaydtae, 'MM-dd-yyyy')
      //   }
      //   this.curveTrading.createCurveTrading(Obj).subscribe(res => {
      //     if (res || res.status == 'ok') {
      //       this.matSnackBar.open('Curve Analysis Created Successfully ', '', {
      //         verticalPosition: 'top',
      //         duration: 2000,
      //         panelClass: 'snack-success'
      //       })
      //     }
      //   })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
    // }
  }




  getReferencenties() {
    this.creditrefenceService.getRefEntity().subscribe(res => {
      // console.log(res.refEntityName.sort())
      res.sort(function (a, b) {
        return a.refEntityName == b.refEntityName ? 0 : +(a.refEntityName > b.refEntityName) || -1;
      });
      this.refentitiyData = res
      // console.log(this.refentitiyData)
    })
  }




  addCurveTrade() {

  }



}





export interface PeriodicElement {
  recovery: string;
  mip: number;
  Tenor: string;
  maturity: string;
  spreads: string;
  upfronts: string;
  Include: string;
  EqSpread: number;
  EqSpreadiff: number,
  UpfrontDiff: string;
  Strikes: number;
  Upfronts: string;
  CDSWFee: number;
  DV01: number;
  ProbDefault: string,
  MarginalProb: string;
  Carry: string;
  Par: string;
  Annuity: string;
  Slide: string;
  PL: string;
}

export interface CurveTrade {
  buy: string;
  fee: number;
  position: number;
  tenure: string;
  maturity: string;
  probdef: string;
  upfronts: string;
  prob: string;
  exspread: string;
  margprob: string;
}

export interface CurveBuySell {
  maturity: string;
  date1: number;
  date2: number;
  date4: number;

}


export interface todaytrade {
  buysell: string;
  tenor: number;
  maturity: string;
  fee: string;
  upfront: string;
  eqspread: string;
  probDef: string;
  MargProb: string;
  dvo1: string;
}

export interface curverisk {
  dVO1: string,
  annuity: string,
  probDefault: string,
  marginalProb: string
}


export interface GraphBasedTable {
  date1: number;
  date2: number;
  date3: number;
  date4: number;
}
export interface caryAnalysis {
  cary: string,
  par: string,
  annuit: string,
  slid: string, pl: string,
}


export interface horizon {
  tenor: string,
  maturity: string,
  spread: string,
  upfrnt: string,
  lcds: string,
}

export interface todaycurve {
  crdtname: string,
  cpn: string,
  ticker: string,
  recry: string,
  tenr: string,
  matrity: string,
  spreads: string,
  eqsprd: string,
  eqsprddif: string,
  strke: string,
  upfornt: string,
  CDSWFee: string
}


const CurveBuy: CurveBuySell[] = [
  { maturity: '21/03/2019', date1: 0, date2: 34, date4: 76 },
  { maturity: '21/03/2019', date1: 0, date2: 34, date4: 76 },
]


const todayTrade: todaytrade[] = [
  { buysell: 'Buy', tenor: 5, maturity: '21/03/2019', fee: '50', upfront: '2500', eqspread: '200', probDef: '20%', MargProb: '50%', dvo1: '10' },
  { buysell: 'Sell', tenor: 4, maturity: '21/03/2019', fee: '50', upfront: '250', eqspread: '20', probDef: '30%', MargProb: '40%', dvo1: '10' },
]

const curveRisk: curverisk[] = [
  { dVO1: '0', annuity: '10', probDefault: '15', marginalProb: '25' }
]

const carryanalysis: caryAnalysis[] = [
  { cary: '0', par: '10', annuit: '15', slid: '25', pl: '10' }

]

const Todaycurve: todaycurve[] = [
  {
    crdtname: 'STEM',
    cpn: '100',
    ticker: '200',
    recry: '50%',
    tenr: '5Y',
    matrity: '2020-02-20',
    spreads: '10',
    eqsprd: '12',
    eqsprddif: '10',
    strke: '500',
    upfornt: '20',
    CDSWFee: '250'
  }
]

const Horizon: horizon[] = [
  {
    tenor: '7Y',
    maturity: '2020-19-02',
    spread: '20',
    upfrnt: '500',
    lcds: '25',
  }
]



const GraphTable: GraphBasedTable[] = [
  { date1: 70, date2: 46, date3: 78, date4: 98 },
  { date1: 70, date2: 46, date3: 78, date4: 98 },
  { date1: 70, date2: 46, date3: 78, date4: 98 },
  { date1: 70, date2: 46, date3: 78, date4: 98 },
  { date1: 70, date2: 46, date3: 78, date4: 98 }
]

const ELEMENT_DATA: PeriodicElement[] = [
  {
    mip: 1, recovery: '50%', Tenor: '5Y', maturity: '19/03/2019', spreads: '85.30', upfronts: '12', Include: 'yes', EqSpread: 20, EqSpreadiff: 20, UpfrontDiff: '20', Strikes: 500, Upfronts: '200',
    CDSWFee: 20,
    DV01: 15,
    Annuity: '25',
    ProbDefault: '210',
    MarginalProb: '15',
    Carry: '150',
    Par: '100',
    Slide: '100',
    PL: '15',
  },
];

const curveTrade: CurveTrade[] = [
  { buy: 'Buy', fee: 80.30, position: 1, tenure: '0M', maturity: '19/03/2019', probdef: '85.30', upfronts: '0%', exspread: '85.20', margprob: '2.3%', prob: '3.7%' },
  { buy: 'Sell', fee: 75.30, position: 1, tenure: '3M', maturity: '02/03/2019', probdef: '85.20', upfronts: '0%', exspread: '85.20', margprob: '', prob: '2.5%' },
  { buy: 'Buy', fee: 70.23, position: 1, tenure: '6M', maturity: '19/03/2019', probdef: '85.20', upfronts: '0%', exspread: '85.20', margprob: '3.9%', prob: '23.5%' },
  { buy: 'Sell', fee: 85.9, position: 1, tenure: '9M', maturity: '11/03/2019', probdef: '45.20', upfronts: '0%', exspread: '85.20', margprob: '', prob: '1.3%' }
]
