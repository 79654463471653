import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UserService } from 'src/providers/user.service';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ForgotpswddialogComponent } from '../forgotpswddialog/forgotpswddialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean = false;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public router: Router, public formbuilder: FormBuilder, public userService: UserService, public matSnackBar: MatSnackBar, private dialog: MatDialog,) { }

  ngOnInit() {
    this.loginForm = this.formbuilder.group({
      Email: ['', Validators.required],
      pswd: ['', Validators.required],
    });
  }


  // gotomaindashboard() {
  //   this.router.navigate([])
  // }

  login() {

    if (this.loginForm.valid) {
      let reqObj = {
        "email": this.loginForm.value.Email,

        "password": this.loginForm.value.pswd
      }
      this.userService.loginUser(reqObj).subscribe(res => {
        console.log(res);
        if (res.access_token) {
          this.fromGroupDirective.resetForm();
          this.router.navigate(['../dashboard/maindashboard'])
          this.matSnackBar.open('Login Success', '', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          localStorage.setItem("email", reqObj.email);
        }
      }, err => {
        // if (err.error.code == "PMF") {
        this.matSnackBar.open("Invalid Credentials", '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
        // } else if (err.error.errors[0].code == 'Email') {
        //   this.matSnackBar.open(err.error.errors[0].defaultMessage, '', {
        //     verticalPosition: 'top',
        //     duration: 2000,
        //     panelClass: 'snack-error'
        //   })
        // }
      })
    } else {
      if (this.loginForm.value.Email == '' && this.loginForm.value.pswd == '') {
        this.matSnackBar.open('Please Enter Email & Password', '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      } else if (this.loginForm.value.Email == '') {
        this.matSnackBar.open('Please Enter Email', '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      } else if (this.loginForm.value.pswd == '') {
        this.matSnackBar.open('Please Enter Password', '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      }
    }
  }


  forgotPassword() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(ForgotpswddialogComponent, {
        width: '350px',  disableClose: false
        // data: { dialogTitle: title, dialogText: text },
      })
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      });

        const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe(result => {
          console.log(result)
          let reqObj = result;
          this.userService.forgotUserpswd(reqObj).subscribe(res => {
            if (res.status == 'ok') {
              this.fromGroupDirective.resetForm();

              this.matSnackBar.open('Mail Sent To Your Email Successfully Please Check It To Reset Password', '', {
                verticalPosition: 'top',
                duration: 2000,
                panelClass: 'snack-success'
              })
            }
          }, err => {

          })
        // dialogSubmitSubscription.unsubscribe();
      });
    }


}
