import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class CreditReferenceService {

  constructor(public http:HttpClient, public appConfig:AppConfig) { }

addCreditIndex(reqObj){
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.http.post(this.appConfig.API_ENDPOINT+ 'ReferenceData/CreditIndexRefData/addCreditIndexRefData', reqObj, httpOptions)
    .pipe(map((res:any)=>res))
  }

  getCreditIndex(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.get(this.appConfig.API_ENDPOINT+ 'ReferenceData/getAllCreditIndexRefData', httpOptions)
      .pipe(map((res:any)=>res))
  }

  deleteCreditRefData(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.delete(this.appConfig.API_ENDPOINT+ 'ReferenceData/CreditIndexRefData/delete/'+id, httpOptions)
      .pipe(map((res:any)=>res))
  }

  editCreditIndex(reqObj){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(this.appConfig.API_ENDPOINT+ 'ReferenceData/CreditIndexRefData/updateCreditIndexRefData', reqObj, httpOptions)
      .pipe(map((res:any)=>res))
    }

  addRefEntity(reqObj){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(this.appConfig.API_ENDPOINT+ 'ReferenceData/RefEntity/addRefEntity', reqObj, httpOptions)
      .pipe(map((res:any)=>res))
    }

    getRefEntity(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.http.get(this.appConfig.API_ENDPOINT+ 'ReferenceData/getAllRefEntity',  httpOptions)
        .pipe(map((res:any)=>res))
    }

    editRefEntity(reqObj){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.http.post(this.appConfig.API_ENDPOINT+ 'ReferenceData/RefEntity/updateRefEntity', reqObj, httpOptions)
        .pipe(map((res:any)=>res))
      }   

      deleterefEntity(id){
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
        return this.http.delete(this.appConfig.API_ENDPOINT+ 'ReferenceData/RefEntity/deleteRefEntity/'+id, httpOptions)
          .pipe(map((res:any)=>res))
      }

}
