import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class CdsService {

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  cdsSingle(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'CDS/add',reqData, httpOptions)
    .pipe(map((res:any)=>res))
  }


  cdsIndex(reqData){
    const httpOptions={
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'CDSIndex/add', reqData, httpOptions)
    .pipe(map((res:any)=>res))
  }


  getCdsIndex(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'CDSIndex/'+id, httpOptions)
    .pipe(map((res:any)=>res))
  }

  cdsUpdate(reqData){
    const httpOptions = {
      headers: new HttpHeaders ({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'CDSIndex/update',reqData, httpOptions )

    .pipe(map((res:any)=>res))
  }

  getCdsSingle(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'CDS/'+id, httpOptions)
    .pipe(map((res:any)=>res))
  }

  cdsSingleUpdate(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'CDS/update',reqData, httpOptions)
    .pipe(map((res:any)=>res))
  }
 

}
