import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UserService } from '../../providers/user.service';
import { MatSnackBar } from '@angular/material';


import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface MemberData {
  id: string;
  userId: string;
    email: string;
    location: string;
    status: string;
    workphone: string;
    cellphone: string;
    homephone: string;
    currency: string;
    book: string;
    accountType: string;
    region:string;
    portfolio: string;
}

@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.css']
})
export class AddmemberComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'email', 'location', 'cellphone', 'currency', 'book', 'accountType', 'region','portfolio'];
  dataSource: MatTableDataSource<MemberData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  addMemberForm:FormGroup;

  @ViewChild(FormGroupDirective) formGroupDirective:FormGroupDirective;

  constructor(public formBuilder:FormBuilder, public userService:UserService, public matSnackBar:MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.addMemberForm = this.formBuilder.group({
      accountType: ['', Validators.required],
      book: ['', Validators.required],
      cellphone: ['', Validators.compose([Validators.pattern('^[6-9]{1}[0-9]{9}$'), Validators.required])],
      currency: ['', Validators.required],
      email: ['', Validators.compose([Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})*'), Validators.required])],
      homephone: [''],
      location: ['', Validators.required],
      portfolio: ['', Validators.required],
      region: ['', Validators.required],
      status: [false, Validators.required],
      userId: ['', Validators.required],
      workphone: [''],
      userRole:['', Validators.required],
      userGroup:['', Validators.required]
    })
    this.getMembers();
  }



  deleteuser(userobj){
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
     if (result=='yes'){
    this.userService.deluser(userobj.id).subscribe(res=>{
      
    })
     }
    });
  }

  getMembers(){
    this.userService.getMembers().subscribe(res=>{
      if(res.length > 0){
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
      }
    })
  }

  addmember(){
if(this.addMemberForm.valid){
  let reqObj = {
    accountType: this.addMemberForm.value.accountType,
    book: this.addMemberForm.value.book,
    cellphone: this.addMemberForm.value.cellphone,
    currency:this.addMemberForm.value.currency,
    email:this.addMemberForm.value.email,
    homephone:this.addMemberForm.value.homephone,
    location:this.addMemberForm.value.location,
    portfolio:this.addMemberForm.value.portfolio,
    region: this.addMemberForm.value.region,
    status:this.addMemberForm.value.status,
    userId: this.addMemberForm.value.userId,
    workphone: this.addMemberForm.value.workphone,
    userRole:this.addMemberForm.value.userRole,
    userGroup:this.addMemberForm.value.userGroup
  }
  
  this.userService.addmember(reqObj).subscribe(res=>{
    // console.log(res)
    if(res.status == 'ok'){
      this.matSnackBar.open('Member added successfully.','OK',{
        verticalPosition:'top',
        duration:3000,
        panelClass:'snack-success'
      })
      this.formGroupDirective.resetForm();
      this.getMembers();
    }else if(res.code == 'UAE'){
      this.matSnackBar.open(res.message,'OK',{
        verticalPosition:'top',
        duration:3000,
        panelClass:'snack-error'
      })
    }
  },error=>{
    // console.log(error)
    this.matSnackBar.open(error.error.errors[0].defaultMessage, 'OK', {
      verticalPosition: 'top',
      duration        : 3000,
      panelClass: 'snack-error'
  });
  })
}else{
  this.matSnackBar.open('Please enter all fields','OK',{
    verticalPosition:'top',
    duration:3000,
    panelClass:'snack-error'
  })
}


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'delete_table.html',
})
export class ConfirmationDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete_row (){
    this.dialogRef.close('yes');
  }

}