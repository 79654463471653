import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  addBook(reqObj){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/book/addBook', reqObj, httpOptions)
      .pipe(map((res:any)=>res))
    }

    editBook(reqObj){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/book/updateBook', reqObj, httpOptions)
        .pipe(map((res:any)=>res))
      }
  

    getBook(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.http.get(this.AppConfig.API_ENDPOINT+ 'staticData/getAllBook',  httpOptions)
        .pipe(map((res:any)=>res))
      }

      addbond(reqObj){
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
        return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/bondRefData/addBondRefData', reqObj, httpOptions)
          .pipe(map((res:any)=>res))
        }

        editBond(reqObj){
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          }
          return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/bondRefData/updateBondRefData', reqObj, httpOptions)
            .pipe(map((res:any)=>res))
          }

        getBond(){
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          }
          return this.http.get(this.AppConfig.API_ENDPOINT+ 'staticData/getAllBondRefData',  httpOptions)
            .pipe(map((res:any)=>res))
        }


        addLegalEntity(reqObj){
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          }
          return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/legalEntity/addLegalEntity', reqObj, httpOptions)
            .pipe(map((res:any)=>res))
          }

          ediLegalEntity(reqObj){
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post(this.AppConfig.API_ENDPOINT+ 'staticData/legalEntity/updateLegalEntity', reqObj, httpOptions)
              .pipe(map((res:any)=>res))
            }

          getLegalEntity(){
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.get(this.AppConfig.API_ENDPOINT+ 'staticData/getAllLegalEntity',  httpOptions)
              .pipe(map((res:any)=>res))
          }

          delBond(id){
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.delete(this.AppConfig.API_ENDPOINT+ 'staticData/bondRefData/deleteBondRefData/'+id)
              .pipe(map((res:any)=>res))
          }

          delBook(id){
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.delete(this.AppConfig.API_ENDPOINT+ 'staticData/book/deleteBook/'+id, httpOptions)
              .pipe(map((res:any)=>res))
          }

          delLegalEntity(id){
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.delete(this.AppConfig.API_ENDPOINT+ 'staticData/legalEntity/deleteLegalEntity/'+id, httpOptions)
              .pipe(map((res:any)=>res))
          }
  

}
