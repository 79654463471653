import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RecoverylockService } from '../../providers/recoverylock.service';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';

import { default as _rollupMoment } from 'moment';

import { MatSnackBar } from '@angular/material';
import { StaticDataService } from 'src/providers/static-data.service';


const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-bondtrade',
  templateUrl: './bondtrade.component.html',
  styleUrls: ['./bondtrade.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BondtradeComponent implements OnInit {

  imgstyle="ForImageIcon"

  addBondTradeForm: FormGroup;
  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  selected = 0;

  dealForm: FormGroup;

  feeForm: FormGroup;

  todayDate = new Date();

  startDate = new Date();

  searchid;

  recoveryLockList;

  viewDeal;

  pageType = 'new';

  constructor(public formBuilder: FormBuilder, public matSnackBar: MatSnackBar,public datePipe: DatePipe, public recoveryService: RecoverylockService, public staticDataService: StaticDataService) { }
  arry: any = [];

  nextStep() {
    console.log(this.addBondTradeForm.value)
    this.selected = 0;
    if (this.addBondTradeForm.valid) {
      this.selected = 2;

    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }


  alphakeyPress(ev) {
    console.log(this.buySellVal)
    // ev.target.value = ev.target.value.toLocaleString()
    if (this.buySellVal == 'sell') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    }

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }


  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }


  buySellVal: any;
  buyValue: any;

  selectbuysell(evt) {
    this.buySellVal = evt;

    if (this.buySellVal == 'buy') {
      setTimeout(() => {
        this.addBondTradeForm.controls['quantity'].setValue((parseInt(this.addBondTradeForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
      }, 100);
    } else {
      setTimeout(() => {
        this.addBondTradeForm.controls['quantity'].setValue('-' + (parseInt(this.addBondTradeForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'));
      }, 100);
    }













    // if (this.buySellVal == 'buy') {
    //   if (this.addBondTradeForm.value.quantity.includes(',')) {
    //     //   console.log('but includes' + this.instrumentForm.value.quantity)
    //     // console.log('buy includes' + this.instrumentForm.value.quantity)
    //     this.addBondTradeForm.controls['quantity'].setValue(this.addBondTradeForm.value.quantity.split('-')[1])
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.addBondTradeForm.controls['quantity'].setValue(this.formatMoney(Math.abs(this.addBondTradeForm.value.quantity)))
    //   }
    // } else {
    //   if (this.addBondTradeForm.value.quantity.includes(',')) {
    //     // console.log(this.instrumentForm.value.quantity.replace (/^/,'-'))
    //     this.addBondTradeForm.controls['quantity'].setValue(this.addBondTradeForm.value.quantity.replace(/^/, '-'));
    //   } else {
    //     // console.log(this.instrumentForm.value.quantity)
    //     this.addBondTradeForm.controls['quantity'].setValue(this.formatMoney(-Math.abs(this.addBondTradeForm.value.quantity)));
    //   }
    // }
  }

  cancelBtn(){
    this.selected = 0;
    this.fromGroupDirective.resetForm();
    this.pageType = "new"
    this.feeForm.controls['feeAmount'].reset()
    this.dealForm.controls['clearingHouse'].reset()
  }

  search() {
    if (this.searchid) {
      this.recoveryService.getBrondTrade(this.searchid).subscribe(res => {
        if (res) {
          this.viewDeal = res.deal;
          this.pageType = 'edit';

          if (this.viewDeal.buySell == 'buy') {
            this.buyValue = this.formatMoney(Math.abs(this.viewDeal.instrument.quantity))
          } else {
            this.buyValue = this.formatMoney(-Math.abs(this.viewDeal.instrument.quantity));
          }

          this.addBondTradeForm = this.formBuilder.group({
            bond_type: [this.viewDeal.instrument.instrumentType, Validators.required],
            bond_desc: [this.viewDeal.instrument.instrumentName],
            security_id_type: [this.viewDeal.instrument.code, Validators.required],
            value: [''],
            trade_currency: [''],
            buysell: [this.viewDeal.buySell],
            quantity: [this.buyValue,Validators.required],
            settled_date: [moment(this.viewDeal.instrument.startDate)],
            yield: [''],
            trade_amount: [''],
            settle_currency: [''],
            dirty_price: [this.viewDeal.instrument.price],
            accural: [''],
            next_coupon: [''],
            coupon_amount: [this.viewDeal.instrument.coupon,Validators.required],
            prem_desc: [''],
            ir01: [''],
            npv: [''],
            cr01: ['']
          })

          this.dealForm = this.formBuilder.group({
            tradeBook: [this.viewDeal.tradeBook, Validators.required],
            counterParty: ['cpt1', Validators.required],
            tradeDate: [this.viewDeal.tradeDate, Validators.required],
            clearingHouse: [this.viewDeal.clearingHouse, Validators.required],
            tradeStatus: [this.viewDeal.tradeStatus, Validators.required]
          })

          this.feeForm = this.formBuilder.group({
            broker: [this.viewDeal.fee.broker, Validators.required],
            currency: [this.viewDeal.fee.currency, Validators.required],
            feeAmount: [this.viewDeal.fee.feeAmount, Validators.required],
            feeDate: [moment(this.viewDeal.fee.feeDate), Validators.required]
          })
        } else {
          this.matSnackBar.open('Please enter valid ID', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter ID', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  gotofee() {
    if (!this.addBondTradeForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.addBondTradeForm.valid && this.dealForm.valid) {
      this.selected = 3;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }


  }

  allBondsData: any = [];
  getAllBonds() {
    this.staticDataService.getBond().subscribe(res => {
      this.allBondsData = res
    })
  }


  onBondSelection(i) {
    console.log(i)
  }

  ngOnInit() {
    this.getAllBonds();
    this.addBondTradeForm = this.formBuilder.group({
      bond_type: ['', Validators.required],
      bond_desc: ['', Validators.required],
      security_id_type: ['', Validators.required],
      value: ['', Validators.required],
      trade_currency: ['', Validators.required],
      buysell: ['', Validators.required],
      quantity: ['', Validators.required],
      settled_date: ['', Validators.required],
      yield: ['', Validators.required],
      trade_amount: ['', Validators.required],
      settle_currency: ['', Validators.required],
      dirty_price: ['', Validators.required],
      accural: ['', Validators.required],
      next_coupon: ['', Validators.required],
      coupon_amount: ['', Validators.required],
      prem_desc: ['', Validators.required],
      ir01: [''],
      npv: [''],
      cr01: ['']
    })
    this.dealForm = this.formBuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formBuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })
  }


  x = function rplaceQuantity() {
    var myquanty = this.addBondTradeForm.value.quantity;
    return myquanty.replace(/,/g, '');
  }



  canceldeal() {
    if (this.searchid) {
      let reqObj =
      {
        "id": this.searchid,
        // "brd": {
        //   "accDaycount": "string",
        //   "accrualRounding": this.addBondTradeForm.value.accural,
        //   "activeDate": "2019-12-24T13:31:59.783Z",
        //   "announceDate": "2019-12-24T13:31:59.783Z",
        //   "assimPrdId": 0,
        //   "assimilationDate": "2019-12-24T13:31:59.783Z",
        //   "auctionDate": "2019-12-24T13:31:59.783Z",
        //   "bondId": "string",
        //   "bondName": "string",
        //   "bondType": this.addBondTradeForm.value.bond_type,
        //   "callDate": "2019-12-24T13:31:59.783Z",
        //   "capStrike": 0,
        //   "cmpFrequency": "string",
        //   "country": "string",
        //   "coupon": this.addBondTradeForm.value.coupon_amount,
        //   "couponCurrency": "string",
        //   "couponDateRoll": "string",
        //   "couponFrequency": "string",
        //   "couponHolidays": "string",
        //   "couponOffset": 0,
        //   "currency": "string",
        //   "datedDate": "2019-12-24T13:31:59.783Z",
        //   "dayCount": "string",
        //   "defaultDate": "2019-12-24T13:31:59.783Z",
        //   "exdividendDays": 0,
        //   "faceValue": 0,
        //   "firstCouponDate": "2019-12-24T13:31:59.783Z",
        //   "fixedBb": true,
        //   "floaterB": true,
        //   "floorStrike": 0,
        //   "inactiveDate": "2019-12-24T13:31:59.783Z",
        //   "issueDate": "2019-12-24T13:31:59.783Z",
        //   "issuePrice": 0,
        //   "issuerCode": "string",
        //   "maturityDate": "2019-12-24T13:31:59.783Z",
        //   "maturityTtenor": 0,
        //   "notionalIndex": "string",
        //   "notlGuaranteed": 0,
        //   "notlIndexValue": 0,
        //   "oddFirstCoupon": true,
        //   "oddLastCoupon": true,
        //   "optionType": "string",
        //   "penultCpnDate": "2019-12-24T13:31:59.783Z",
        //   "prePaidB": true,
        //   "quoteType": "string",
        //   "rateIndex": "string",
        //   "rateIndexSpread": 0,
        //   "reconventionDC": "string",
        //   "reconventionDate": "2019-12-24T13:31:59.783Z",
        //   "redemptionPrice": 0,
        //   "resetBusLagB": true,
        //   "resetDays": 0,
        //   "resetInArrearsB": true,
        //   "rollingDay": 0,
        //   "roundingUnit": 0,
        //   "settlementDays": 0,
        //   "targetId": 0,
        //   "tickSize": "string",
        //   "totalIssued": 0,
        //   "valueDays": this.addBondTradeForm.value.value,
        //   "withholdingTax": 0,
        //   "yieldMethod": this.addBondTradeForm.value.yield
        // }

        "deal": {
          "buySell": this.addBondTradeForm.value.buysell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": this.addBondTradeForm.value.security_id_type,
            "coupon": this.addBondTradeForm.value.coupon_amount,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",
            "instrumentName": this.addBondTradeForm.value.bond_desc,
            "instrumentType": this.addBondTradeForm.value.bond_type,
            "maturityDate": "2020-02-03T14:47:10.147Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": this.addBondTradeForm.value.dirty_price,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.addBondTradeForm.value.settled_date, 'MM-dd-yyyy'),
            "style": "string"
          },
          "otherBook": "string",
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": "cancelled"
        },
      }

      this.recoveryService.editBondTrade(reqObj).subscribe(res => {
        console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'

          })
          this.searchid = '';
          this.pageType = "new";
          this.fromGroupDirective.resetForm();
          this.selected = 0;
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
          // this.ngOnInit();
          
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'snack-error'
      })
    }


  }


  edit() {
    if (!this.addBondTradeForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.addBondTradeForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.addBondTradeForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj =
      {
        "id": this.searchid,
        "deal": {
          "buySell": this.addBondTradeForm.value.buysell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": this.addBondTradeForm.value.security_id_type,
            "coupon": this.addBondTradeForm.value.coupon_amount,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",
            "instrumentName": this.addBondTradeForm.value.bond_desc,
            "instrumentType": this.addBondTradeForm.value.bond_type,
            "maturityDate": "2020-02-03T14:47:10.147Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": this.addBondTradeForm.value.dirty_price,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.addBondTradeForm.value.settled_date, 'MM-dd-yyyy'),
            "style": "string"
          },
          "otherBook": "string",
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },
        // "brd": {
        //   "accDaycount": "string",
        //   "accrualRounding": this.addBondTradeForm.value.accural,
        //   "activeDate": "2019-12-24T13:31:59.783Z",
        //   "announceDate": "2019-12-24T13:31:59.783Z",
        //   "assimPrdId": 0,
        //   "assimilationDate": "2019-12-24T13:31:59.783Z",
        //   "auctionDate": "2019-12-24T13:31:59.783Z",
        //   "bondId": "string",
        //   "bondName": "string",
        //   "bondType": this.addBondTradeForm.value.bond_type,
        //   "callDate": "2019-12-24T13:31:59.783Z",
        //   "capStrike": 0,
        //   "cmpFrequency": "string",
        //   "country": "string",
        //   "coupon": this.addBondTradeForm.value.coupon_amount,
        //   "couponCurrency": "string",
        //   "couponDateRoll": "string",
        //   "couponFrequency": "string",
        //   "couponHolidays": "string",
        //   "couponOffset": 0,
        //   "currency": "string",
        //   "datedDate": "2019-12-24T13:31:59.783Z",
        //   "dayCount": "string",
        //   "defaultDate": "2019-12-24T13:31:59.783Z",
        //   "exdividendDays": 0,
        //   "faceValue": 0,
        //   "firstCouponDate": "2019-12-24T13:31:59.783Z",
        //   "fixedBb": true,
        //   "floaterB": true,
        //   "floorStrike": 0,
        //   "inactiveDate": "2019-12-24T13:31:59.783Z",
        //   "issueDate": "2019-12-24T13:31:59.783Z",
        //   "issuePrice": 0,
        //   "issuerCode": "string",
        //   "maturityDate": "2019-12-24T13:31:59.783Z",
        //   "maturityTtenor": 0,
        //   "notionalIndex": "string",
        //   "notlGuaranteed": 0,
        //   "notlIndexValue": 0,
        //   "oddFirstCoupon": true,
        //   "oddLastCoupon": true,
        //   "optionType": "string",
        //   "penultCpnDate": "2019-12-24T13:31:59.783Z",
        //   "prePaidB": true,
        //   "quoteType": "string",
        //   "rateIndex": "string",
        //   "rateIndexSpread": 0,
        //   "reconventionDC": "string",
        //   "reconventionDate": "2019-12-24T13:31:59.783Z",
        //   "redemptionPrice": 0,
        //   "resetBusLagB": true,
        //   "resetDays": 0,
        //   "resetInArrearsB": true,
        //   "rollingDay": 0,
        //   "roundingUnit": 0,
        //   "settlementDays": 0,
        //   "targetId": 0,
        //   "tickSize": "string",
        //   "totalIssued": 0,
        //   "valueDays": this.addBondTradeForm.value.value,
        //   "withholdingTax": 0,
        //   "yieldMethod": this.addBondTradeForm.value.yield
        // }
      }

      this.recoveryService.editBondTrade(reqObj).subscribe(res => {
        // console.log(res)
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Bond Trade updated successfully.', 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          // this.defFormValues();
          // this.ngOnInit();
          this.fromGroupDirective.resetForm();
          this.selected = 0;
          this.pageType = "new"
          this.searchid = '';
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter Mandatory Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }



  // defFormValues() {
  //   this.addBondTradeForm = this.formBuilder.group({
  //     bond_type: [''],
  //     bond_desc: [''],
  //     security_id_type: [''],
  //     value: [''],
  //     trade_currency: [''],
  //     buysell: [''],
  //     quantity: [''],
  //     settled_date: [''],
  //     yield: [''],
  //     trade_amount: [''],
  //     settle_currency: [''],
  //     dirty_price: [''],
  //     accural: [''],
  //     next_coupon: [''],
  //     coupon_amount: [''],
  //     prem_desc: [''],
  //     ir01: [''],
  //     npv: [''],
  //     cr01: ['']
  //   })
  //   this.dealForm = this.formBuilder.group({
  //     tradeBook: ['book1'],
  //     counterParty: ['cpt1'],
  //     tradeDate: [this.todayDate],
  //     clearingHouse: [''],
  //     tradeStatus: ['booked']
  //   })

  //   this.feeForm = this.formBuilder.group({
  //     broker: ['broker1'],
  //     currency: ['usd'],
  //     feeAmount: [''],
  //     feeDate: [moment(this.startDate)]
  //   })
  // }

  saveBond() {
    if (!this.addBondTradeForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.addBondTradeForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.addBondTradeForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj =
      {
        "deal": {
          "buySell": this.addBondTradeForm.value.buysell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": this.addBondTradeForm.value.security_id_type,
            "coupon": this.addBondTradeForm.value.coupon_amount,
            "csaTerm": "string",
            "currency": "string",
            "dateRole": "string",
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",
            "instrumentName": this.addBondTradeForm.value.bond_desc,
            "instrumentType": this.addBondTradeForm.value.bond_type,
            "maturityDate": "2020-02-03T14:47:10.147Z",
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": this.addBondTradeForm.value.dirty_price,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.addBondTradeForm.value.settled_date, 'MM-dd-yyyy'),
            "style": "string"
          },
          "otherBook": "string",
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },
        // "brd": {
        //   "accDaycount": "string",
        //   "accrualRounding": this.addBondTradeForm.value.accural,
        //   "activeDate": "2019-12-24T13:31:59.783Z",
        //   "announceDate": "2019-12-24T13:31:59.783Z",
        //   "assimPrdId": 0,
        //   "assimilationDate": "2019-12-24T13:31:59.783Z",
        //   "auctionDate": "2019-12-24T13:31:59.783Z",
        //   "bondId": "string",
        //   "bondName": "string",
        //   "bondType": this.addBondTradeForm.value.bond_type,
        //   "callDate": "2019-12-24T13:31:59.783Z",
        //   "capStrike": 0,
        //   "cmpFrequency": "string",
        //   "country": "string",
        //   "coupon": this.addBondTradeForm.value.coupon_amount,
        //   "couponCurrency": "string",
        //   "couponDateRoll": "string",
        //   "couponFrequency": "string",
        //   "couponHolidays": "string",
        //   "couponOffset": 0,
        //   "currency": "string",
        //   "datedDate": "2019-12-24T13:31:59.783Z",
        //   "dayCount": "string",
        //   "defaultDate": "2019-12-24T13:31:59.783Z",
        //   "exdividendDays": 0,
        //   "faceValue": 0,
        //   "firstCouponDate": "2019-12-24T13:31:59.783Z",
        //   "fixedBb": true,
        //   "floaterB": true,
        //   "floorStrike": 0,
        //   "inactiveDate": "2019-12-24T13:31:59.783Z",
        //   "issueDate": "2019-12-24T13:31:59.783Z",
        //   "issuePrice": 0,
        //   "issuerCode": "string",
        //   "maturityDate": "2019-12-24T13:31:59.783Z",
        //   "maturityTtenor": 0,
        //   "notionalIndex": "string",
        //   "notlGuaranteed": 0,
        //   "notlIndexValue": 0,
        //   "oddFirstCoupon": true,
        //   "oddLastCoupon": true,
        //   "optionType": "string",
        //   "penultCpnDate": "2019-12-24T13:31:59.783Z",
        //   "prePaidB": true,
        //   "quoteType": "string",
        //   "rateIndex": "string",
        //   "rateIndexSpread": 0,
        //   "reconventionDC": "string",
        //   "reconventionDate": "2019-12-24T13:31:59.783Z",
        //   "redemptionPrice": 0,
        //   "resetBusLagB": true,
        //   "resetDays": 0,
        //   "resetInArrearsB": true,
        //   "rollingDay": 0,
        //   "roundingUnit": 0,
        //   "settlementDays": 0,
        //   "targetId": 0,
        //   "tickSize": "string",
        //   "totalIssued": 0,
        //   "valueDays": this.addBondTradeForm.value.value,
        //   "withholdingTax": 0,
        //   "yieldMethod": this.addBondTradeForm.value.yield
        // }
      }

      this.recoveryService.addBondTrade(reqObj).subscribe(res => {
        // console.log(res)
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Bond Trade added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',

            panelClass: 'snack-success'
          })
          // this.ngOnInit();
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
          this.selected = 0;
          // this.defFormValues()
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter Mandatory Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

}
