import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginUser: any;
  @Output() closeSidenav: EventEmitter<any> = new EventEmitter();

  @Input('sidenavValue')

  sidenavValue: boolean

  // sidenav:boolean = true;

  constructor(public router: Router) {
    console.log(this.sidenavValue)
  }

  ngOnInit() {
    this.loginUser = localStorage.getItem('email');
  }

  closeNav() {
    this.sidenavValue = !this.sidenavValue;
    this.closeSidenav.emit(this.sidenavValue);
  }


  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/login'])
  }

}
