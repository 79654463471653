import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creditlinknotes',
  templateUrl: './creditlinknotes.component.html',
  styleUrls: ['./creditlinknotes.component.css']
})
export class CreditlinknotesComponent implements OnInit {

  imgstyle="ForImageIcon"
  
  constructor() { }

  ngOnInit() {
  }

}
