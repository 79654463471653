import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {FormGroup, FormBuilder, Validators } from '@angular/forms';

export interface RiskReportData {
 
  	curve_name:string;
  	maturity_date:string;
    tenor:string;
    spread:string;
    upfront_spread:string;
    recovery:string;
    linked_curve:string;

}


@Component({
  selector: 'app-marketdataupload',
  templateUrl: './marketdataupload.component.html',
  styleUrls: ['./marketdataupload.component.css']
})
export class MarketdatauploadComponent implements OnInit {

  marketDataUploadForm:FormGroup;

  displayedColumns: string[] = [ 'curve_name', 'maturity_date',
  'tenor', 'spread',   'upfront_spread', 'recovery', 'linked_curve'];
dataSource: MatTableDataSource<RiskReportData>;

invoice_file_name;

file;

selected= 0;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
riskReportArray = [
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' },
{'linked_curve':'Hedge1',    'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
'tenor':'10',   'spread':'45',   'upfront_spread':'65', 'recovery':'56' }
]
constructor(public formBuilder:FormBuilder) { 
this.dataSource = new MatTableDataSource(this.riskReportArray);
}

ngOnInit() {
  this.marketDataUploadForm = this.formBuilder.group({
    curve_type:['',Validators.required],
    value_date:['',Validators.required],
    file:['', Validators.required]
  })
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;
}
applyFilter(filterValue: string) {
this.dataSource.filter = filterValue.trim().toLowerCase();

if (this.dataSource.paginator) {
  this.dataSource.paginator.firstPage();
}
}



onSelectedFile(event) {
  //  console.log(event)
    this.file = event.target.files[0];
          
    this.invoice_file_name = event.target.files[0].name;
  
      }

}
