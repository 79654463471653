import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';


@Injectable({
  providedIn: 'root'
})
export class CreditIndexoptionService {

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  ceditindexoption(reqObj){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(this.AppConfig.API_ENDPOINT+ 'CreditIndexOption/add', reqObj, httpOptions)
      .pipe(map((res:any)=>res))
    }

  
    ceditindexoptionget(id){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.http.get(this.AppConfig.API_ENDPOINT+ 'CreditIndexOption/' +id, httpOptions)
        .pipe(map((res:any)=>res))
      }


      creditindexoptionUpdate(reqData){
        const httpOptions = {
          headers: new HttpHeaders ({
            'Content-Type': 'application/json'
          })
        }
    
        return this.http.post(this.AppConfig.API_ENDPOINT+ 'CreditIndexOption/update',reqData, httpOptions )
    
        .pipe(map((res:any)=>res))
      }

}
