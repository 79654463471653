import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../providers/user.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  loginForm: FormGroup;

  constructor(public formBuilder: FormBuilder, public userService: UserService,
    public matSnackBar: MatSnackBar, public router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    })
  }

  password = new FormControl('', [Validators.required, Validators.email]);

  // getErrorMessage() {
  //   return this.password.hasError('required') ? 'Minimum 8 Characters 1 Upper 1 Lower 1 Numeric 1 Special Character' :
  //     this.password.hasError('email') ? 'Not a valid email' :
  //       '';
  // }

  reset() {
    if (this.loginForm.valid) {
      if (this.loginForm.value.password !== this.loginForm.value.confirmpassword) {
        this.matSnackBar.open('Password & Confirm Password Not Matches', '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'snack-error'
        })
      } else {
        let reqObj = {
          active: true,
          changePassword: true,
          confirmPassword: this.loginForm.value.confirmpassword,
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,

        }
        this.userService.resetPassword(reqObj).subscribe(res => {
          // console.log(res)
          if (res.status == 'ok') {
            this.matSnackBar.open('Password changed successfully', 'OK', {
              verticalPosition: 'top',
              duration: 3000,
              panelClass: 'snack-success'
            })
            this.router.navigate(['/login'])
            localStorage.clear();
          } else {
            this.matSnackBar.open(res.message, 'OK', {
              verticalPosition: 'top',
              duration: 3000,
              panelClass: 'snack-error'
            })
          }
        })
      }
    } else {
      this.matSnackBar.open('Please Enter Mandatory Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

}
