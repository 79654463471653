import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { CreditReferenceService } from '../../providers/credit-reference.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialog } from '../addmember/addmember.component';


export interface RefEntityData {
  "acutionDate": string;
  "currency": string;
  "dateRoll": string;
  "dayCount": string;
  "notificaitonDate": string;
  "redCode": string;
  "refEntityDesc": string;
  "refEntityId": string;
  "refEntityName": string;
  "refEntityType": string;
  "restructuring": string;
  "seniority": string;
}



@Component({
  selector: 'app-refentity',
  templateUrl: './refentity.component.html',
  styleUrls: ['./refentity.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class RefentityComponent implements OnInit {

  refObj;

  pageType = "new";

  displayedColumns: string[] = ['refEntityId', 'refEntityName', 'refEntityType', 'restructuring', 'seniority', 'currency', 'action'];
  dataSource: MatTableDataSource<RefEntityData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  daterole = "option1";

  daycount = "option1";

  // AuctionDate=new Date();

  // NotificationDate=new Date();

  refEntityForm: FormGroup;

  ref_entity_data = [];


  referenceEntity = [
    { view: '3M Co', value: '3m_co' },
    { view: 'American Express Co', value: 'american_express_co' },
    { view: 'Apple Inc', value: 'apple_inc' },
    { view: 'Boeing Co', value: 'boeing_co' },
    { view: 'Caterpillar Inc', value: 'caterpillar_inc' },
    { view: 'Chevron Corp', value: 'chevron_corp' },
    { view: 'Cisco Systems Inc', value: 'cisco_systems_inc' },
    { view: 'Coca-Cola Co', value: 'coca_cola_co' },
    { view: 'E I du Pont de Nemours and Co', value: 'e_i_du_pont_de_nemours_and_co' },
    { view: 'Exxon Mobil Corp', value: 'exxon_mobil_corp' },
    { view: 'General Electric Co', value: 'general_electric_co' },
    { view: 'Goldman Sachs Group Inc', value: 'goldman_sachs_group_inc' },
    { view: 'Home Depot Inc', value: 'home_depot_inc' },
    { view: 'Intel Corp', value: 'intel_corp' },
    { view: 'International Business Machines Corp', value: 'international_business_machines_corp' },
    { view: 'Johnson & Johnson', value: 'johnson_and_johnson' },
    { view: 'JPMorgan Chase & Co', value: 'jpmorgan_chase_and_co' },
    { view: "McDonald's Corp", value: 'mcdonalds_corp' },
    { view: 'Merck & Co Inc', value: 'merck_and_co_inc' },
    { view: 'Microsoft Corp', value: 'microsoft_corp' },
    { view: 'Nike Inc', value: 'nike_inc' },
    { view: 'Pfizer Inc', value: 'pfizer_inc' },
    { view: 'Procter & Gamble Co', value: 'procter_and_gamble_co' },
    { view: 'Travelers Companies Inc', value: 'travelers_companies_inc' },
    { view: 'United Technologies Corp', value: 'united_technologies_corp' },
    { view: 'Verizon Communications Inc', value: 'verizon_communications_inc' },
    { view: 'Wal-Mart Stores Inc', value: 'walmart_stores_inc' },
    { view: 'Walt Disney Co', value: 'walt_disney_co' }
  ]
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  selected = 0;
  constructor(public formBuilder: FormBuilder, public dialog: MatDialog, public matSnackBar: MatSnackBar, public creditReferenceService: CreditReferenceService, public datePipe: DatePipe) { }

  ngOnInit() {
    this.refEntityForm = this.formBuilder.group({
      curve_type: ['', Validators.required],
      ref_entity_name: ['', Validators.required],
      ref_entity_id: ['', Validators.required],
      restructuring: ['', Validators.required],
      seniority: ['', Validators.required],
      day_count: ['', Validators.required],
      date_role: ['', Validators.required],
      red_code: ['', Validators.required],
      currency: ['', Validators.required],
      noti_date: [new Date(), Validators.required],
      auction_date: [new Date(), Validators.required]
    })
    this.getentityData();
  }

  deleterefentity(credit) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.creditReferenceService.deleterefEntity(credit.refEntityId).subscribe(res => {

        })
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getentityData() {
    this.creditReferenceService.getRefEntity().subscribe(res => {
      if (res.length > 0) {
        this.ref_entity_data = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.ref_entity_data = [];
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  changeTab(ev) {
    // console.log(ev)
    this.selected = ev;
  }

  update() {
    if (this.refEntityForm.valid) {
      let reqObj = {
        curve_type: this.refEntityForm.value.curve_type,
        refEntityName: this.refEntityForm.value.ref_entity_name,
        refEntityId: this.refEntityForm.value.ref_entity_id,
        restructuring: this.refEntityForm.value.restructuring,
        seniority: this.refEntityForm.value.seniority,
        dayCount: this.refEntityForm.value.day_count,
        dateRoll: this.refEntityForm.value.date_role,
        redCode: this.refEntityForm.value.red_code,
        currency: this.refEntityForm.value.currency,
        notificaitonDate: this.datePipe.transform(this.refEntityForm.value.noti_date, 'MM-dd-yyyy'),
        acutionDate: this.datePipe.transform(this.refEntityForm.value.auction_date, 'MM-dd-yyyy'),
      }
      this.creditReferenceService.editRefEntity(reqObj).subscribe(res => {
        if (res.status == 'ok') {
          this.matSnackBar.open('RefEntity Updated successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.getentityData();
          this.formGroupDirective.resetForm();
          this.pageType ="new"
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  saveref() {
    if (this.refEntityForm.valid) {
      let reqObj = {
        curve_type: this.refEntityForm.value.curve_type,
        refEntityName: this.refEntityForm.value.ref_entity_name,
        refEntityId: this.refEntityForm.value.ref_entity_id,
        restructuring: this.refEntityForm.value.restructuring,
        seniority: this.refEntityForm.value.seniority,
        dayCount: this.refEntityForm.value.day_count,
        dateRoll: this.refEntityForm.value.date_role,
        redCode: this.refEntityForm.value.red_code,
        currency: this.refEntityForm.value.currency,
        notificaitonDate: this.datePipe.transform(this.refEntityForm.value.noti_date, 'MM-dd-yyyy'),
        acutionDate: this.datePipe.transform(this.refEntityForm.value.auction_date, 'MM-dd-yyyy')
      }
      this.creditReferenceService.addRefEntity(reqObj).subscribe(res => {
        if (res.status == 'ok') {
          this.matSnackBar.open('RefEntity added successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.formGroupDirective.resetForm();

          this.getentityData()
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Mandatory fields', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }


  editrefEntity(refObj) {
    this.selected = 0;
    this.pageType = 'edit';
    this.refObj = refObj;
    this.refEntityForm = this.formBuilder.group({
      curve_type: ['', Validators.required],
      ref_entity_name: [refObj.refEntityName, Validators.required],
      ref_entity_id: [refObj.refEntityId, Validators.required],
      restructuring: [refObj.restructuring, Validators.required],
      seniority: [refObj.seniority, Validators.required],
      day_count: [refObj.dayCount],
      date_role: [refObj.dateRoll],
      red_code: [refObj.redCode],
      currency: [refObj.currency],
      auction_date: [moment(refObj.acutionDate)],
      noti_date: [moment(refObj.notificaitonDate)]
      // auction_date: new FormControl(new Date(refObj.acutionDate)),
      // noti_date: new FormControl(new Date(refObj.notificaitonDate)),
    })
  }

}
