import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material';


export interface RiskReportData {

  curve_name: string;
  maturity_date: string;
  tenor: string;
  spread: string;
  upfront_spread: string;
  recovery: string;
  linked_curve: string;

}

@Component({
  selector: 'app-marketdatareport',
  templateUrl: './marketdatareport.component.html',
  styleUrls: ['./marketdatareport.component.css']
})
export class MarketdatareportComponent implements OnInit {

  marketDataReport: FormGroup;
  searchReport: boolean = false;


  displayedColumns: string[] = ['curve_name', 'maturity_date',
    'tenor', 'spread', 'upfront_spread', 'recovery', 'linked_curve'];
  dataSource: MatTableDataSource<RiskReportData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  riskReportArray = [
    {
      'linked_curve': 'Hedge1', 'currency': 'USD', 'curve_name': 'GolManSach', 'maturity_date': '05/03/2019',
      'tenor': '3', 'spread': '45', 'upfront_spread': '65', 'recovery': '56','action':''
    },
    {
      'linked_curve': 'Hedge1', 'currency': 'USD', 'curve_name': 'GolManSach', 'maturity_date': '05/03/2019',
      'tenor': '6', 'spread': '45', 'upfront_spread': '65', 'recovery': '56','action':''
    },
    {
      'linked_curve': 'Hedge1', 'currency': 'USD', 'curve_name': 'GolManSach', 'maturity_date': '05/03/2019',
      'tenor': '9', 'spread': '45', 'upfront_spread': '65', 'recovery': '56','action':''
    },
 
  ]
  constructor(public matSnackBar: MatSnackBar, public formbuilder: FormBuilder) {
    this.dataSource = new MatTableDataSource(this.riskReportArray);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.marketDataReport = this.formbuilder.group({
      curveName: ['', Validators.required],
      valuedate: ['', Validators.required],
    })
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  selectCurveName(ev) {
    if (this.marketDataReport.valid) {
      this.searchReport = true;
    }
  }

  OnDateChange(event) {
    if (this.marketDataReport.valid) {
      this.searchReport = true;
    }
  }

}
