import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '../pages/login/login.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AnalyticsComponent } from '../pages/analytics/analytics.component';

import {MatPaginatorModule} from '@angular/material/paginator';
import { MatSidenavModule, MatIconModule } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { ReportsComponent } from '../pages/reports/reports.component';
import { HeaderComponent } from '../pages/header/header.component';
import {MatFormFieldModule, MatCardModule, MatSelectModule, MatTabsModule, MatInputModule, MatButtonModule, MatCheckboxModule,MatTooltipModule,
   MatAutocompleteModule, MatMenuModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatSnackBarModule, MatExpansionModule} from '@angular/material';
import { CdsComponent } from '../pages/cds/cds.component';
import { QuantocdsComponent } from '../pages/quantocds/quantocds.component';
import { CdssingleComponent } from '../pages/cdssingle/cdssingle.component';
import { CreditindexoptionComponent } from '../pages/creditindexoption/creditindexoption.component';
import { ReturnswapComponent } from '../pages/returnswap/returnswap.component';
import { CreditlinknotesComponent } from '../pages/creditlinknotes/creditlinknotes.component';
import { FooterComponent } from '../pages/footer/footer.component';
import { RecoverylockComponent } from '../pages/recoverylock/recoverylock.component';
import { BondtradeComponent } from '../pages/bondtrade/bondtrade.component';
import { BondbasisComponent } from '../pages/bondbasis/bondbasis.component';
import { BondalgoComponent } from '../pages/bondalgo/bondalgo.component';
import { BondriskComponent } from '../pages/bondrisk/bondrisk.component';
import { MidviewerComponent } from '../pages/midviewer/midviewer.component';
import { CurvetradeComponent } from '../pages/curvetrade/curvetrade.component';
import { PlbuilderComponent } from '../pages/plbuilder/plbuilder.component';
import { SlidereportComponent } from '../pages/slidereport/slidereport.component';
import { SinglenameComponent } from '../pages/singlename/singlename.component';
import { BlotterComponent } from '../pages/blotter/blotter.component';
import { UploaderComponent } from '../pages/uploader/uploader.component';
import { RestructuringComponent } from '../pages/restructuring/restructuring.component';
import { FtdComponent } from '../pages/ftd/ftd.component';
import { RefentityComponent } from '../pages/refentity/refentity.component';
import { TranchesComponent } from '../pages/tranches/tranches.component';
import { SeniorityComponent } from '../pages/seniority/seniority.component';
import { CreditindexComponent } from '../pages/creditindex/creditindex.component';
import { AddcurrencyComponent } from '../pages/addcurrency/addcurrency.component';
import { AdduserComponent } from '../pages/adduser/adduser.component';
import { AddbooksComponent } from '../pages/addbooks/addbooks.component';
import { AddlegalentityComponent } from '../pages/addlegalentity/addlegalentity.component';
import { AdduserdefaultComponent } from '../pages/adduserdefault/adduserdefault.component';
import { AddbondComponent } from '../pages/addbond/addbond.component';
import { PlreportComponent } from '../pages/plreport/plreport.component';
import { TradereportComponent } from '../pages/tradereport/tradereport.component';
import { MaindashboardComponent } from '../pages/maindashboard/maindashboard.component';


import { ChartsModule } from 'ng2-charts';

import { AppConfig } from '../providers/app.config';
import { AddmemberComponent, ConfirmationDialog } from '../pages/addmember/addmember.component';

import { DatePipe } from '@angular/common';
import { ResetPasswordComponent } from '../pages/reset-password/reset-password.component';


import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChangepasswordComponent } from '../pages/changepassword/changepassword.component';
import { MarketdatareportComponent } from '../pages/marketdatareport/marketdatareport.component';
import { MarketdatauploadComponent } from '../pages/marketdataupload/marketdataupload.component';
import { BondoptionComponent } from '../pages/bondoption/bondoption.component';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ForgotpswddialogComponent } from 'src/pages/forgotpswddialog/forgotpswddialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AnalyticsComponent,
    ReportsComponent,
    HeaderComponent,
    CdsComponent,
    QuantocdsComponent,
    CdssingleComponent,
    CreditindexoptionComponent,
    ReturnswapComponent,
    CreditlinknotesComponent,
    FooterComponent,
    RecoverylockComponent,
    BondtradeComponent,
    BondbasisComponent,
    BondalgoComponent,
    BondriskComponent,
    MidviewerComponent,
    CurvetradeComponent,
    PlbuilderComponent,
    SlidereportComponent,
    SinglenameComponent,
    BlotterComponent,
    UploaderComponent,
    RestructuringComponent,
    FtdComponent,
    RefentityComponent,
    TranchesComponent,
    SeniorityComponent,
    CreditindexComponent,
    AddcurrencyComponent,
    AdduserComponent,
    AddbooksComponent,
    AddlegalentityComponent,
    AdduserdefaultComponent,
    AddbondComponent,
    PlreportComponent,
    TradereportComponent,
    MaindashboardComponent,
    AddmemberComponent,
    ResetPasswordComponent,
    ConfirmationDialog,
    ChangepasswordComponent,
    MarketdatareportComponent,
    MarketdatauploadComponent,
    BondoptionComponent,
    ForgotpswddialogComponent
  ],
  imports: [
    BrowserModule,ChartsModule,FormsModule,HttpClientModule,ReactiveFormsModule,
    AppRoutingModule, NgMultiSelectDropDownModule.forRoot(),
    MatSidenavModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatIconModule,
    MatSnackBarModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule
  ],
  providers: [AppConfig, DatePipe],
  bootstrap: [AppComponent],
  entryComponents:[ConfirmationDialog,ForgotpswddialogComponent]
})
export class AppModule { }
