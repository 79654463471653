import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  throwError, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class QuantocdsService {

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  quantacds(reqObj){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'QuantaCDS/add', reqObj, httpOptions)
    .pipe(map((res:any)=>res))
  }

  quantcdsget(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT+ 'QuantaCDS/' +id ,httpOptions)
    .pipe(map((res:any)=>res))
  }

  quantcdsUpdate(reqData){
    const httpOptions = {
      headers: new HttpHeaders ({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT+ 'QuantaCDS/update',reqData, httpOptions )

    .pipe(map((res:any)=>res))
  }

}
