import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';


@Injectable({
  providedIn: 'root'
})
export class RecoverylockService {

  constructor(public http: HttpClient, public AppConfig: AppConfig) { }

  addrecoverylock(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'recoverylock/add', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getRecoveryLock(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'recoverylock/' + id, httpOptions)
      .pipe(map((res: any) => res))
  }


  updateRecoveryLock(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'recoverylock/update', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }



  getUpdateRecoveryById(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'recoverylock/' + reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  addBondTrade(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'BondTrade/add', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getBrondTrade(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'BondTrade/' + id, httpOptions)
      .pipe(map((res: any) => res))
  }

  editBondTrade(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'BondTrade/update', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  insertReturnSwap(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'TRS/add', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getTrs(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'TRS/' + id, httpOptions)
      .pipe(map((res: any) => res))
  }

  editTrs(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'TRS/update', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  insertBondOption(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'BondOption/add', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getBondOption(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'BondOption/' + id, httpOptions)
      .pipe(map((res: any) => res))
  }

  updateBondOption(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'BondOption/update', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

}
