import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';


export interface RiskReportData {
  	book:string;
  seniority:string;
  	restructuring:string;
  	currency:string;
  	curve_name:string;
  	maturity_date:string;
  	position:string;
  	buy_sell:string;
  	cr01:string;
  	mtm:string;
  trade_spread:string;
  	market_spread:string;
  	refEntity:string;
	trade_date:string;
  	valuation_date:string;
  
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = [	'book', 'seniority', 'restructuring', 'currency', 'curve_name', 'maturity_date',
      'position', 'buy_sell', 'cr01', 'mtm', 'trade_spread',   'market_spread', 'refEntity', 'trade_date',  'valuation_date'];
  dataSource: MatTableDataSource<RiskReportData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  riskReportArray = [
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'},
    {'book':'Hedge1', 'seniority':'SNRFOR', 'restructuring':'MR', 'currency':'USD', 'curve_name':'GolManSach', 'maturity_date':'05/03/2019',
    'position':'10', 'buy_sell':'Buy', 'cr01':'2', 'mtm':'12', 'trade_spread':'45',   'market_spread':'65', 'refEntity':'56', 'trade_date':'05/03/2019',  'valuation_date':'05/03/2019'}
  ]
  constructor() { 
    this.dataSource = new MatTableDataSource(this.riskReportArray);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
