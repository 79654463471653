import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RecoverylockService } from '../../providers/recoverylock.service';
import { MatSnackBar } from '@angular/material';
import { CurrencyPipe } from '@angular/common';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

import { default as _rollupMoment } from 'moment';
import { CreditReferenceService } from 'src/providers/credit-reference.service';

const moment = _rollupMoment || _moment;

export interface RecoveryData {
  id: string;
  memberId: string;
  instrument: any;
  risk: any;
  deal: any;
  fee: any;
}

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  providers: [
    // {CurrencyPipe},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AnalyticsComponent implements OnInit {

  imgstyle="ForImageIcon"


  displayedColumns: string[] = ['dealid', 'creditSeniority', 'paymentDelay', 'buySell', 'action'];
  dataSource: MatTableDataSource<RecoveryData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  instrumentForm: FormGroup;

  dealForm: FormGroup;

  feeForm: FormGroup;

  coupon = 0;

  tradespread = 0;

  qty = 0;


  currency = 'usd';

  selected: number = 0;

  startDate = new Date();

  recoveryLockList = [];

  viewDeal;

  view_more: boolean = false;

  showForm: boolean = true;

  todayDate = new Date();

  searchid;

  refentitiyData: any = [];
  // referenceEntity = [
  //   { view: '3M Co', value: '3m_co' },
  //   { view: 'American Express Co', value: 'american_express_co' },
  //   { view: 'Apple Inc', value: 'apple_inc' },
  //   { view: 'Boeing Co', value: 'boeing_co' },
  //   { view: 'Caterpillar Inc', value: 'caterpillar_inc' },
  //   { view: 'Chevron Corp', value: 'chevron_corp' },
  //   { view: 'Cisco Systems Inc', value: 'cisco_systems_inc' },
  //   { view: 'Coca-Cola Co', value: 'coca_cola_co' },
  //   { view: 'E I du Pont de Nemours and Co', value: 'e_i_du_pont_de_nemours_and_co' },
  //   { view: 'Exxon Mobil Corp', value: 'exxon_mobil_corp' },
  //   { view: 'General Electric Co', value: 'general_electric_co' },
  //   { view: 'Goldman Sachs Group Inc', value: 'goldman_sachs_group_inc' },
  //   { view: 'Home Depot Inc', value: 'home_depot_inc' },
  //   { view: 'Intel Corp', value: 'intel_corp' },
  //   { view: 'International Business Machines Corp', value: 'international_business_machines_corp' },
  //   { view: 'Johnson & Johnson', value: 'johnson_and_johnson' },
  //   { view: 'JPMorgan Chase & Co', value: 'jpmorgan_chase_and_co' },
  //   { view: "McDonald's Corp", value: 'mcdonalds_corp' },
  //   { view: 'Merck & Co Inc', value: 'merck_and_co_inc' },
  //   { view: 'Microsoft Corp', value: 'microsoft_corp' },
  //   { view: 'Nike Inc', value: 'nike_inc' },
  //   { view: 'Pfizer Inc', value: 'pfizer_inc' },
  //   { view: 'Procter & Gamble Co', value: 'procter_and_gamble_co' },
  //   { view: 'Travelers Companies Inc', value: 'travelers_companies_inc' },
  //   { view: 'United Technologies Corp', value: 'united_technologies_corp' },
  //   { view: 'Verizon Communications Inc', value: 'verizon_communications_inc' },
  //   { view: 'Wal-Mart Stores Inc', value: 'walmart_stores_inc' },
  //   { view: 'Walt Disney Co', value: 'walt_disney_co' }
  // ]

  pageType = 'new';

  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  constructor(public formbuilder: FormBuilder, public recoveryService: RecoverylockService, public matSnackBar: MatSnackBar, public datePipe: DatePipe, public creditrefenceService: CreditReferenceService) { }

  ngOnInit() {
    this.getReferencenties();

    // console.log(this.startDate.getFullYear())
    var valuationDate;

    valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.startDate.setDate(this.startDate.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 2)
    }




    let maturity_date = this.startDate.getFullYear() + 5;


    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;

    this.instrumentForm = this.formbuilder.group({
      buySell: ['buy', Validators.required],
      coupon: ['500', Validators.required],
      creditTemplate: ['snac', Validators.required],
      referenceEntity: ['', Validators.required],
      seniority: ['senior_unsecure', Validators.required],
      restructuring: ['mr', Validators.required],
      currency: ['usd', Validators.required],
      startDate: [moment(this.startDate), Validators.required],
      maturity: [moment(finaldate), Validators.required],
      paymentDelay: ['quaterly', Validators.required],
      dateRole: ['modified_following', Validators.required],
      dayCount: ['act/360', Validators.required],
      quantity: ['5000000', Validators.required],
      hasFixedRec: ['40%', Validators.required],
      tradespread: ['500', Validators.required],
      upfrontfee: ['', Validators.required],
      upfrontamount: ['', Validators.required],
      valuationDate: [this.todayDate, Validators.required],
      cr01: [''],
      ir01: [''],
      mtm: [''],
      parRate: ['']
    })

    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })



    this.getrecovery();
    this.quantityadded('');

    this.selectbuysell({ value: 'buy' });

  }



  defFormValues() {
    var valuationDate;

    valuationDate = this.datePipe.transform(this.startDate, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.startDate.setDate(this.startDate.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.startDate.setDate(this.startDate.getDate() + 2)
    }

    let maturity_date = this.startDate.getFullYear() + 5;


    let finaldate = this.datePipe.transform(this.startDate, 'MM/dd') + '/' + maturity_date;
    this.instrumentForm = this.formbuilder.group({
      buySell: ['buy'],
      coupon: ['500'],
      creditTemplate: ['snac'],
      referenceEntity: [''],
      seniority: ['senior_unsecure'],
      restructuring: ['mr'],
      currency: ['usd'],
      startDate: [moment(this.startDate)],
      maturity: [moment(finaldate)],
      paymentDelay: ['quaterly'],
      dateRole: ['modified_following'],
      dayCount: ['act/360'],
      quantity: ['5000000'],
      hasFixedRec: ['40%'],
      tradespread: ['500'],
      upfrontfee: [''],
      upfrontamount: [''],
      valuationDate: [this.todayDate],
      cr01: [''],
      ir01: [''],
      mtm: [''],
      parRate: ['']
    })

    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1'],
      counterParty: ['cpt1'],
      tradeDate: [this.todayDate],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked']
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1'],
      currency: ['usd'],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate)]
    })

    this.selectbuysell({ value: 'buy' })
  }






  getReferencenties() {
    this.creditrefenceService.getRefEntity().subscribe(res => {
      // console.log(res.refEntityName.sort())
      res.sort(function (a, b) {
        return a.refEntityName == b.refEntityName ? 0 : +(a.refEntityName > b.refEntityName) || -1;
      });
      this.refentitiyData = res
      // console.log(this.refentitiyData)
    })
  }


  couponChange(ev) {
    this.instrumentForm.controls['tradespread'].setValue(ev.value);
    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
  }

  x = function rplaceQuantity() {
    var myquanty = this.instrumentForm.value.quantity;
    return myquanty.replace(/,/g, '');
  }



  canceldeal() {
    if (this.searchid) {
      let reqObj = {
        "creditDefaultSwap":
        {
          "creditAccruedStyle": "string",
          "creditId": "123",
          "creditName": this.instrumentForm.value.referenceEntity,
          "creditRestructuring": this.instrumentForm.value.restructuring,
          "creditSeniority": this.instrumentForm.value.seniority,
          "hasFixedRec": this.instrumentForm.value.hasFixedRec,
          "protectionEndDate": "2019-11-18",
          "protectionStartDate": "2019-11-18",
          "upfrontFee": this.instrumentForm.value.upfrontfee,
          "upfrontAmount": this.instrumentForm.value.upfrontamount,
        },
        "deal":
        {
          "buySell": this.instrumentForm.value.buySell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee":
          {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument":
          {
            "code": "string",
            "coupon": this.instrumentForm.value.coupon,
            "csaTerm": "string",
            "currency": this.instrumentForm.value.currency,
            "dayCount": this.instrumentForm.value.dayCount,
            "dateRole": this.instrumentForm.value.dateRole,
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",

            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
            "paymentDelay": "string",
            "pmtFrequency": this.instrumentForm.value.paymentDelay,
            "price": this.instrumentForm.value.tradespread,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
            "style": this.instrumentForm.value.creditTemplate,
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": 'cancelled'
        },
        "id": this.searchid,
        "memberId": "123"
      }

      this.recoveryService.updateRecoveryLock(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.searchid = '';
          this.pageType = 'new'
          this.fromGroupDirective.resetForm();
          this.ngOnInit();
          // this.recoveryService.getUpdateRecoveryById(this.searchid).subscribe(res => {
          //   // console.log(res)
          //   this.viewDeal = res;
          //   this.instrumentForm = this.formbuilder.group({
          //     buySell: [this.viewDeal.deal.buySell],
          //     coupon: [JSON.stringify(this.viewDeal.deal.instrument.coupon)],
          //     creditTemplate: [this.viewDeal.deal.instrument.style],
          //     referenceEntity: [this.viewDeal.creditDefaultSwap.creditName],
          //     seniority: [this.viewDeal.creditDefaultSwap.creditSeniority],
          //     restructuring: [this.viewDeal.creditDefaultSwap.creditRestructuring],
          //     currency: [this.viewDeal.deal.instrument.currency],
          //     startDate: [moment(this.viewDeal.deal.instrument.startDate)],
          //     maturity: [moment(this.viewDeal.deal.instrument.maturityDate)],
          //     paymentDelay: [this.viewDeal.deal.instrument.pmtFrequency],
          //     dateRole: [this.viewDeal.deal.instrument.dateRole],
          //     dayCount: [this.viewDeal.deal.instrument.dayCount],
          //     quantity: [this.viewDeal.deal.instrument.quantity, Validators.required],
          //     hasFixedRec: [this.viewDeal.creditDefaultSwap.hasFixedRec, Validators.required],
          //     tradespread: [this.viewDeal.deal.instrument.price, Validators.required],
          //     upfrontfee: [this.viewDeal.creditDefaultSwap.upfrontFee, Validators.required],
          //     upfrontamount: [this.viewDeal.creditDefaultSwap.upfrontAmount, Validators.required],
          //     valuationDate: [this.todayDate],
          //     cr01: [''],
          //     ir01: [''],
          //     mtm: [''],
          //     parRate: ['']
          //   })

          //   this.dealForm = this.formbuilder.group({
          //     tradeBook: [this.viewDeal.deal.tradeBook],
          //     counterParty: [''],
          //     tradeDate: [moment(this.viewDeal.deal.tradeDate)],
          //     clearingHouse: [this.viewDeal.deal.clearingHouse],
          //     tradeStatus: [this.viewDeal.deal.tradeStatus]
          //   })

          //   this.feeForm = this.formbuilder.group({
          //     broker: [this.viewDeal.deal.fee.broker],
          //     currency: [this.viewDeal.deal.fee.currency],
          //     feeAmount: [this.viewDeal.deal.fee.feeAmount, Validators.required],
          //     feeDate: [moment(this.viewDeal.deal.fee.feeDate)]
          //   })
          // })
        }
      })
      // this.ngOnInit();
    } else {
      this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'snack-error'
      })
    }
  }



  cancelBtn() {
    this.selected = 0;
    this.ngOnInit();
  }




  alphakeyPress(ev) {
    // ev.target.value = ev.target.value.toLocaleString()
    if (this.instrumentForm.value.buySell == 'sell') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);

    }
    // console.log(ev)

    // console.log((parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
    // console.log(ev)
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }

  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }

  selectbuysell(ev) {

    if (ev.value == 'buy') {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue((parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'))
      }, 100);
    } else {
      setTimeout(() => {
        this.instrumentForm.controls['quantity'].setValue('-' + (parseInt(this.instrumentForm.value.quantity.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US'));
      }, 100);
    }
  }

  selectCredit(ev) {
    if (ev.value == 'stem') {
      this.instrumentForm.controls['restructuring'].setValue('mm');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'stac') {
      this.instrumentForm.controls['restructuring'].setValue('nr');
      this.instrumentForm.controls['coupon'].setValue('100');
    } else if (ev.value == 'snac') {
      this.instrumentForm.controls['restructuring'].setValue('mr');
      this.instrumentForm.controls['coupon'].setValue('500');
    }
  }

  nextStep() {
    if (this.instrumentForm.valid) {
      this.selected = 2;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
      // this.selected = 2;
    }
  }

  quantityadded(event) {

    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity.replace(/,/g, '')) / 10000)
  }

  quantityadded1(ev) {
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * this.instrumentForm.value.quantity) / 10000)
  }

  gotofee() {
    if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid) {
      this.selected = 3;
    } else {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deals', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  addquantity(event) {
    let val = this.instrumentForm.value.quantity.replace(/,/g, '')
    this.instrumentForm.controls['upfrontfee'].setValue(this.instrumentForm.value.tradespread - this.instrumentForm.value.coupon);
    this.instrumentForm.controls['upfrontamount'].setValue((this.instrumentForm.value.upfrontfee * val) / 10000)
  }

  buyValue: any;

  search() {


    function formatMoney(number) {
      return number.toLocaleString('en-US', { currency: 'USD' });
    }

    if (this.searchid) {
      this.recoveryService.getRecoveryLock(this.searchid).subscribe(res => {
        if (res) {
          this.recoveryLockList = res;
          this.viewDeal = this.recoveryLockList;
          this.pageType = 'edit';

          if (this.viewDeal.deal.buySell == 'buy') {
            this.buyValue = formatMoney(Math.abs(this.viewDeal.deal.instrument.quantity))
          } else {
            this.buyValue = formatMoney(-Math.abs(this.viewDeal.deal.instrument.quantity));
          }

          this.instrumentForm = this.formbuilder.group({
            buySell: [this.viewDeal.deal.buySell],
            coupon: [JSON.stringify(this.viewDeal.deal.instrument.coupon)],
            creditTemplate: [this.viewDeal.deal.instrument.style],
            referenceEntity: [this.viewDeal.creditDefaultSwap.creditName],
            seniority: [this.viewDeal.creditDefaultSwap.creditSeniority],
            restructuring: [this.viewDeal.creditDefaultSwap.creditRestructuring],
            currency: [this.viewDeal.deal.instrument.currency],
            startDate: [moment(this.viewDeal.deal.instrument.startDate)],
            maturity: [moment(this.viewDeal.deal.instrument.maturityDate)],
            paymentDelay: [this.viewDeal.deal.instrument.pmtFrequency],
            dateRole: [this.viewDeal.deal.instrument.dateRole],
            dayCount: [this.viewDeal.deal.instrument.dayCount],
            quantity: [this.buyValue, Validators.required],
            hasFixedRec: [this.viewDeal.creditDefaultSwap.hasFixedRec, Validators.required],
            tradespread: [this.viewDeal.deal.instrument.price, Validators.required],
            upfrontfee: [this.viewDeal.creditDefaultSwap.upfrontFee, Validators.required],
            upfrontamount: [this.viewDeal.creditDefaultSwap.upfrontAmount, Validators.required],
            valuationDate: [this.todayDate],
            cr01: [''],
            ir01: [''],
            mtm: [''],
            parRate: ['']
          })

          this.dealForm = this.formbuilder.group({
            tradeBook: [this.viewDeal.deal.tradeBook],
            counterParty: [this.viewDeal.deal.otherBook],
            tradeDate: [moment(this.viewDeal.deal.tradeDate)],
            clearingHouse: [this.viewDeal.deal.clearingHouse, Validators.required],
            tradeStatus: [this.viewDeal.deal.tradeStatus]
          })

          this.feeForm = this.formbuilder.group({
            broker: [this.viewDeal.deal.fee.broker],
            currency: [this.viewDeal.deal.fee.currency],
            feeAmount: [this.viewDeal.deal.fee.feeAmount, Validators.required],
            feeDate: [moment(this.viewDeal.deal.fee.feeDate)]
          })
        } else {
          this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }

  showAll() {
    this.viewDeal = '';
    this.showForm = false;
  }

  showform() {
    this.showForm = true;
  }

  view(dealObj) {
    this.viewDeal = dealObj;
  }

  getrecovery() {
    // if(this.searchid){
    // this.recoveryService.getRecoveryLock(this.searchid).subscribe(res=>{
    //   if(res){
    //     this.recoveryLockList = res;
    //     this.dataSource = new MatTableDataSource(res);
    //     this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    //   }
    // })
    // }

  }

  newStr: any;
  saveRecovery() {
    if (!this.instrumentForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deals', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj = {
        "creditDefaultSwap":
        {
          "creditAccruedStyle": "string",
          "creditId": "123",
          "creditName": this.instrumentForm.value.referenceEntity,
          "creditRestructuring": this.instrumentForm.value.restructuring,
          "creditSeniority": this.instrumentForm.value.seniority,
          "hasFixedRec": this.instrumentForm.value.hasFixedRec,
          "protectionEndDate": "2019-11-18",
          "protectionStartDate": "2019-11-18",
          "upfrontFee": this.instrumentForm.value.upfrontfee,
          "upfrontAmount": this.instrumentForm.value.upfrontamount,
        },
        "deal":
        {
          "buySell": this.instrumentForm.value.buySell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee":
          {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument":
          {
            "code": "string",
            "coupon": this.instrumentForm.value.coupon,
            "csaTerm": "string",
            "currency": this.instrumentForm.value.currency,
            "dayCount": this.instrumentForm.value.dayCount,
            "dateRole": this.instrumentForm.value.dateRole,
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",

            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
            "paymentDelay": "string",
            "pmtFrequency": this.instrumentForm.value.paymentDelay,
            "price": this.instrumentForm.value.tradespread,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
            "style": this.instrumentForm.value.creditTemplate,
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },

        "memberId": "123"
      }
      this.recoveryService.addrecoverylock(reqObj).subscribe(res => {
        // console.log(res)
        if (res || res.status == 'ok') {
          this.matSnackBar.open('Recovery Lock added successfully. Deal ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',
            panelClass: 'snack-success'
          })
          this.selected = 0;
          this.fromGroupDirective.resetForm();
          this.ngOnInit();
          // this.defFormValues();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }

  }


  update() {
    if (!this.instrumentForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument & Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.instrumentForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Instrument', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open('Please Enter All Fields In Deal', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.instrumentForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj = {
        "creditDefaultSwap":
        {
          "creditAccruedStyle": "string",
          "creditId": "123",
          "creditName": this.instrumentForm.value.referenceEntity,
          "creditRestructuring": this.instrumentForm.value.restructuring,
          "creditSeniority": this.instrumentForm.value.seniority,
          "hasFixedRec": this.instrumentForm.value.hasFixedRec,
          "protectionEndDate": "2019-11-18",
          "protectionStartDate": "2019-11-18",
          "upfrontFee": this.instrumentForm.value.upfrontfee,
          "upfrontAmount": this.instrumentForm.value.upfrontamount,
        },
        "deal":
        {
          "buySell": this.instrumentForm.value.buySell,
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee":
          {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument":
          {
            "code": "string",
            "coupon": this.instrumentForm.value.coupon,
            "csaTerm": "string",
            "currency": this.instrumentForm.value.currency,
            "dayCount": this.instrumentForm.value.dayCount,
            "dateRole": this.instrumentForm.value.dateRole,
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": "string",

            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.instrumentForm.value.maturity, 'MM-dd-yyyy'),
            "paymentDelay": "string",
            "pmtFrequency": this.instrumentForm.value.paymentDelay,
            "price": this.instrumentForm.value.tradespread,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.instrumentForm.value.startDate, 'MM-dd-yyyy'),
            "style": this.instrumentForm.value.creditTemplate,
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },
        "id": this.searchid,
        "memberId": "123"
      }

      this.recoveryService.updateRecoveryLock(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Recovery Lock Updated successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.pageType = "new"
          this.selected = 0;
          this.searchid = '';
          this.fromGroupDirective.resetForm();
          this.ngOnInit();
          // this.defFormValues();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }
}
