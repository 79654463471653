import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';

import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';

import { RecoverylockService } from '../../providers/recoverylock.service';
import { MatSnackBar } from '@angular/material';




@Component({
  selector: 'app-returnswap',
  templateUrl: './returnswap.component.html',
  styleUrls: ['./returnswap.component.css'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ReturnswapComponent implements OnInit {

  searchid;
  imgstyle="ForImageIcon"

  pageType = 'new';
  ReturnSwapForm: FormGroup;

  refComponents = [
    { view: 'Holiday 1', value: 'senior_secure' },
    { view: 'Holiday 2', value: 'senior_unsecure' },
    { view: 'Holiday 3', value: 'subordinated' }
  ];

  dropdownSettings = {};
  selectedItems = [];
  selectedItems1 = [];
  selectedItems2 = [];

  selected = 0;

  dealForm: FormGroup;

  feeForm: FormGroup;
  start_date = new Date();
  startDate = new Date();

  todayDate = new Date();

  @ViewChild(FormGroupDirective) fromGroupDirective: FormGroupDirective;

  TrsData;

  constructor(public formbuilder: FormBuilder, public recoveryService: RecoverylockService,
    public matSnackBar: MatSnackBar, public datePipe: DatePipe) {

  }

  nextStep() {

    this.selected = 0;
    if (this.ReturnSwapForm.valid) {
      this.selected = 2;

    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  x = function rplaceQuantity() {
    var myquanty = this.ReturnSwapForm.value.notional;
    return myquanty.replace(/,/g, '');
  }


  gotofee() {
    if (!this.ReturnSwapForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Instrument", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open("Please Enter All Fields", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.ReturnSwapForm.valid && this.dealForm.valid) {
      this.selected = 3;
    } else {
      this.matSnackBar.open('Please Enter All Fields', '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  formatMoney(number) {
    return number.toLocaleString('en-US', { currency: 'USD' });
  }

  search() {
    this.recoveryService.getTrs(this.searchid).subscribe(res => {
      console.log(res)
      if (res) {
        this.TrsData = res;
        this.pageType = 'edit';
        let holiday = JSON.parse(this.TrsData.deal.instrument.holidayCalendars)
        let resetHoliday = JSON.parse(this.TrsData.trs.resetHolidays)
        let cpnHoliday = JSON.parse(this.TrsData.trs.cpnHolidays)
        this.ReturnSwapForm = this.formbuilder.group({
          locked_date: [moment(this.TrsData.trs.lockOutDate)],
          locked_spread: [this.TrsData.trs.lockoutSpread, Validators.required],
          locked_amount: [this.TrsData.trs.lockOutAmount, Validators.required],
          refObligType: [''],
          termination_date: [moment(this.TrsData.trs.teminationDate)],
          premium_type: [''],
          prem_notl_type: [''],
          is_terminated: [false],
          start_date: [moment(this.TrsData.deal.instrument.startDate)],
          maturityDate: [moment(this.TrsData.deal.instrument.maturityDate)],
          currency: [this.TrsData.deal.instrument.currency],
          notional: [this.formatMoney(this.TrsData.deal.instrument.quantity), Validators.required],
          pmt_type: [this.TrsData.trs.flPmtType],
          frequency: [''],
          // holidays:[this.TrsData.trs.resetHolidays],
          holidays: [this.ReturnSwapForm.get('holidays').setValue(holiday)],
          resetholidays:[this.ReturnSwapForm.get('resetholidays').setValue(resetHoliday)],
          cpnholidays:[this.ReturnSwapForm.get('cpnholidays').setValue(cpnHoliday)],
          date_roll: [this.TrsData.deal.instrument.dateRole],
          reset_frequency: [this.TrsData.trs.resetFreq],
          reset_date_roll: [this.TrsData.trs.resetDateRoll],
          cpa_payment_type: [this.TrsData.trs.cpnPmtType],
          cpn_frequency: [this.TrsData.trs.cpnFreq],
          cpn_roll: [this.TrsData.trs.cpnDateRoll],
          reinvestment: [this.TrsData.trs.reinvementRate],
          daycount: [this.TrsData.deal.instrument.dayCount],
          is_offest: [this.TrsData.trs.resetOffSet],
          parspread: [''],
          mtm: [''],
          cr01: [''],
          ir01: ['']
        })
        this.dealForm = this.formbuilder.group({
          tradeBook: [this.TrsData.deal.tradeBook],
          counterParty: [this.TrsData.deal.otherBook],
          tradeDate: [moment(this.TrsData.deal.tradeDate)],
          clearingHouse: [this.TrsData.deal.clearingHouse, Validators.required],
          tradeStatus: [this.TrsData.deal.tradeStatus]
        })

        this.feeForm = this.formbuilder.group({
          broker: [this.TrsData.deal.fee.broker],
          currency: [this.TrsData.deal.fee.currency],
          feeAmount: [this.TrsData.deal.fee.feeAmount, Validators.required],
          feeDate: [moment(this.TrsData.deal.fee.feeDate)]
        })
      }
    })
  }

  ngOnInit() {


    var valuationDate;

    valuationDate = this.datePipe.transform(this.start_date, 'EEEE');

    if (valuationDate == 'Saturday') {
      this.start_date.setDate(this.start_date.getDate() + 3);
      // console.log(this.instrumentForm.value.startDate)
    } else if (valuationDate == 'Friday' || valuationDate == 'Thursday') {
      // console.log(valuationDate)
      this.start_date.setDate(this.start_date.getDate() + 4)
    } else {
      // console.log(valuationDate)
      this.start_date.setDate(this.start_date.getDate() + 2)
    }

    let maturity_date = this.start_date.getFullYear() + 5;


    let finaldate = this.datePipe.transform(this.start_date, 'MM/dd') + '/' + maturity_date;




    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'view',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.ReturnSwapForm = this.formbuilder.group({
      locked_date: ['', Validators.required],
      locked_spread: ['', Validators.required],
      locked_amount: ['', Validators.required],
      refObligType: ['', Validators.required],
      termination_date: ['', Validators.required],
      premium_type: ['', Validators.required],
      prem_notl_type: ['', Validators.required],
      is_terminated: [false],
      holidays: [[], Validators.required],
      resetholidays:[[],Validators.required],
      cpnholidays:[[],Validators.required],
      start_date: [moment(this.start_date), Validators.required],
      maturityDate: [moment(finaldate), Validators.required],
      currency: ['', Validators.required],
      notional: ['', Validators.required],
      pmt_type: ['', Validators.required],
      frequency: ['', Validators.required],
      date_roll: ['', Validators.required],
      reset_frequency: ['', Validators.required],
      reset_date_roll: ['', Validators.required],
      cpa_payment_type: ['', Validators.required],
      cpn_frequency: ['', Validators.required],
      cpn_roll: ['', Validators.required],
      reinvestment: ['', Validators.required],
      daycount: ['', Validators.required],
      is_offest: [false],
      parspread: [''],
      mtm: [''],
      cr01: [''],
      ir01: ['']
    })
    this.dealForm = this.formbuilder.group({
      tradeBook: ['book1', Validators.required],
      counterParty: ['cpt1', Validators.required],
      tradeDate: [this.todayDate, Validators.required],
      clearingHouse: ['', Validators.required],
      tradeStatus: ['booked', Validators.required]
    })

    this.feeForm = this.formbuilder.group({
      broker: ['broker1', Validators.required],
      currency: ['usd', Validators.required],
      feeAmount: ['', Validators.required],
      feeDate: [moment(this.startDate), Validators.required]
    })



  }

  onItemSelect(ev) {

  }

  onSelectAll(ev) {

  }


  canceldeal() {
    if (this.searchid) {
      let reqObj = {
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": 0,
            "csaTerm": "string",
            "currency": this.ReturnSwapForm.value.currency,
            // "Denomiated": this.ReturnSwapForm.value.currency,
            "dateRole": this.ReturnSwapForm.value.date_roll,
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": JSON.stringify(this.selectedItems),
            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, "MM-dd-yyyy"),
            // "ProtectionEndDate":this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"),
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.ReturnSwapForm.value.start_date, "MM-dd-yyyy"),
            // "ProtectionStartDate":this.datePipe.transform(this.ReturnSwapForm.value.start_date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"),
            "style": "string"
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": 'cancelled'
        },
        "id": this.searchid,
        "memberId": "123",
        "trs": {
          "cpnDateRoll": this.ReturnSwapForm.value.cpn_roll,
          "cpnFreq": this.ReturnSwapForm.value.cpn_frequency,
          "cpnHolidays": JSON.stringify(this.selectedItems2),
          "cpnOffBusDay": true,
          "cpnOffset": 0,
          // "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          "cpnRollingDay": 0,
          "flBusDay": true,
          "flDateRoll": "string",
          "flFreq": "string",
          "flHolidays": "string",
          "flOffSet": 0,
          "flPmtType": this.ReturnSwapForm.value.pmt_type,
          "flRollingDay": 0,
          "lockOutAmount": this.ReturnSwapForm.value.locked_amount,
          "lockOutDate": this.datePipe.transform(this.ReturnSwapForm.value.locked_date, "MM-dd-yyyy"),
          "lockoutSpread": this.ReturnSwapForm.value.locked_spread,
          "reinvementRate": this.ReturnSwapForm.value.reinvestment,
          "resetDateRoll": this.ReturnSwapForm.value.reset_date_roll,
          "resetFreq": this.ReturnSwapForm.value.reset_frequency,
          "resetHolidays": JSON.stringify(this.selectedItems1),
          "resetOffSet": this.ReturnSwapForm.value.is_offest,
          "resetRollDay": 0,
          "teminationDate": this.datePipe.transform(this.ReturnSwapForm.value.termination_date, "MM-dd-yyyy"),
        }
      }
      this.recoveryService.editTrs(reqObj).subscribe(res => {
        // console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Deal Cancelled successfully', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.pageType = 'new';
          this.selected = 0;
          this.searchid = ''
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
          // this.recoveryService.getTrs(this.searchid).subscribe(res => {
          //   this.TrsData = res;
          //   this.ReturnSwapForm = this.formbuilder.group({
          //     locked_date: [moment(this.TrsData.trs.locked_date)],
          //     locked_spread: [this.TrsData.trs.lockoutSpread],
          //     locked_amount: [this.TrsData.trs.lockOutAmount],
          //     refObligType: [''],
          //     termination_date: [moment(this.TrsData.trs.teminationDate)],
          //     premium_type: [''],
          //     prem_notl_type: [''],
          //     is_terminated: [false],
          //     start_date: [moment(this.TrsData.deal.instrument.startDate)],
          //     maturityDate: [moment(this.TrsData.deal.instrument.maturityDate)],
          //     currency: [this.TrsData.deal.instrument.currency],
          //     notional: [this.TrsData.deal.instrument.quantity],
          //     pmt_type: [this.TrsData.trs.flPmtType],
          //     frequency: [''],
          //     date_roll: [this.TrsData.deal.instrument.dateRole],
          //     reset_frequency: [this.TrsData.trs.resetFreq],
          //     reset_date_roll: [this.TrsData.trs.resetDateRoll],
          //     cpa_payment_type: [this.TrsData.trs.cpnPmtType],
          //     cpn_frequency: [this.TrsData.trs.cpnFreq],
          //     cpn_roll: [this.TrsData.trs.cpnDateRoll],
          //     reinvestment: [this.TrsData.trs.reinvementRate],
          //     daycount: [this.TrsData.deal.instrument.dayCount],
          //     is_offest: [this.TrsData.trs.resetOffSet],
          //     parspread: [''],
          //     mtm: [''],
          //     cr01: [''],
          //     ir01: ['']
          //   })
          //   this.dealForm = this.formbuilder.group({
          //     tradeBook: [this.TrsData.deal.tradeBook],
          //     counterParty: [''],
          //     tradeDate: [moment(this.TrsData.deal.tradeDate)],
          //     clearingHouse: [this.TrsData.deal.clearingHouse],
          //     tradeStatus: [this.TrsData.deal.tradeStatus]
          //   })

          //   this.feeForm = this.formbuilder.group({
          //     broker: [this.TrsData.deal.fee.broker],
          //     currency: [this.TrsData.deal.fee.currency],
          //     feeAmount: [this.TrsData.deal.fee.feeAmount, Validators.required],
          //     feeDate: [moment(this.TrsData.deal.fee.feeDate)]
          //   })
          // })
          // this.ngOnInit();
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open('Please enter valid Deal ID', 'OK', {
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'snack-error'
      })
    }


  }


  update() {
    if (!this.ReturnSwapForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Instrument & Deal", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.ReturnSwapForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Instrument", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Deal", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.ReturnSwapForm.valid && this.dealForm.valid && this.feeForm.valid) {
      let reqObj = {
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": 0,
            "csaTerm": "string",
            "currency": this.ReturnSwapForm.value.currency,
            // "Denomiated": this.ReturnSwapForm.value.currency,
            "dateRole": this.ReturnSwapForm.value.date_roll,
            "dayCount": "string",
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": JSON.stringify(this.selectedItems),
            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, "MM-dd-yyyy"),
            // "ProtectionEndDate": this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"),
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.ReturnSwapForm.value.start_date, "MM-dd-yyyy"),
            // "ProtectionStartDate": this.datePipe.transform(this.ReturnSwapForm.value.start_date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"),
            "style": "string"
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },
        "id": this.searchid,
        "memberId": "123",
        "trs": {
          "cpnDateRoll": this.ReturnSwapForm.value.cpn_roll,
          "cpnFreq": this.ReturnSwapForm.value.cpn_frequency,
          "cpnHolidays": JSON.stringify(this.selectedItems2),
          "cpnOffBusDay": true,
          "cpnOffset": 0,
          "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          // "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          "cpnRollingDay": 0,
          "flBusDay": true,
          "flDateRoll": "string",
          "flFreq": "string",
          "flHolidays": "string",
          "flOffSet": 0,
          "flPmtType": this.ReturnSwapForm.value.pmt_type,
          "flRollingDay": 0,
          "lockOutAmount": this.ReturnSwapForm.value.locked_amount,
          "lockOutDate": this.datePipe.transform(this.ReturnSwapForm.value.locked_date, "MM-dd-yyyy"),
          "lockoutSpread": this.ReturnSwapForm.value.locked_spread,
          "reinvementRate": this.ReturnSwapForm.value.reinvestment,
          "resetDateRoll": this.ReturnSwapForm.value.reset_date_roll,
          "resetFreq": this.ReturnSwapForm.value.reset_frequency,
          "resetHolidays": JSON.stringify(this.selectedItems1),
          "resetOffSet": this.ReturnSwapForm.value.is_offest,
          "resetRollDay": 0,
          "teminationDate": this.datePipe.transform(this.ReturnSwapForm.value.termination_date, "MM-dd-yyyy"),
        }
      }
      this.recoveryService.editTrs(reqObj).subscribe(res => {
        console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Total Return Swap updated successfully.', 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-success'
          })
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
          this.searchid = '';
          this.selected = 0;
          this.pageType = 'new';
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open("Please Enter All Fields", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }

  insert() {
    if (!this.ReturnSwapForm.valid && !this.dealForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Instrument & Deal", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.ReturnSwapForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Instrument", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (!this.dealForm.valid) {
      this.matSnackBar.open("Please Enter All Fields In Deal", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    } else if (this.ReturnSwapForm.valid && this.dealForm.valid && this.feeForm.valid) {

      let reqObj = {
        "deal": {
          "buySell": "string",
          "clearingHouse": this.dealForm.value.clearingHouse,
          "fee": {
            "broker": this.feeForm.value.broker,
            "currency": this.feeForm.value.currency,
            "feeAmount": this.feeForm.value.feeAmount,
            "feeDate": this.feeForm.value.feeDate
          },
          "instrument": {
            "code": "string",
            "coupon": 0,
            "csaTerm": "string",
            "currency": this.ReturnSwapForm.value.currency,
            // "Denomiated": this.ReturnSwapForm.value.currency,
            "dateRole": this.ReturnSwapForm.value.date_roll,
            "dayCount": this.ReturnSwapForm.value.daycount,
            "hasCustomCouponSchedule": "string",
            "holidayCalendars": JSON.stringify(this.selectedItems),
            // "holidayCalendars": "string",
            "instrumentName": "string",
            "instrumentType": "string",
            "maturityDate": this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, 'MM-dd-yyyy'),
            // "ProtectionEndDate":this.datePipe.transform(this.ReturnSwapForm.value.maturityDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ'),
            "paymentDelay": "string",
            "pmtFrequency": "string",
            "price": 0,
            // "Qty":this.x(),
            "quantity": this.x(),
            "startDate": this.datePipe.transform(this.ReturnSwapForm.value.start_date, "MM-dd-yyyy"),
            // "ProtectionStartDate":this.datePipe.transform(this.ReturnSwapForm.value.start_date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"),
            "style": "string"
          },
          "otherBook": this.dealForm.value.counterParty,
          "settlementLocation": "string",
          "tradeBook": this.dealForm.value.tradeBook,
          "tradeDate": this.dealForm.value.tradeDate,
          "tradeStatus": this.dealForm.value.tradeStatus
        },

        "memberId": "123",
        "trs": {
          "cpnDateRoll": this.ReturnSwapForm.value.cpn_roll,
          "cpnFreq": this.ReturnSwapForm.value.cpn_frequency,
          // "cpnHolidays": this.selectedItems2,
          "cpnHolidays": JSON.stringify(this.selectedItems2),
          "cpnOffBusDay": true,
          "cpnOffset": 0,
          "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          // "cpnPmtType": this.ReturnSwapForm.value.cpa_payment_type,
          "cpnRollingDay": 0,
          "flBusDay": true,
          "flDateRoll": "string",
          "flFreq": "string",
          "flHolidays": "string",
          "flOffSet": 0,
          "flPmtType": this.ReturnSwapForm.value.pmt_type,
          "flRollingDay": 0,
          "lockOutAmount": this.ReturnSwapForm.value.locked_amount,
          "lockOutDate": this.datePipe.transform(this.ReturnSwapForm.value.locked_date, "MM-dd-yyyy"),
          // "lockOutDate": lockeddate + 'T00:00:00.000Z',
          "lockoutSpread": this.ReturnSwapForm.value.locked_spread,
          "reinvementRate": this.ReturnSwapForm.value.reinvestment,
          "resetDateRoll": this.ReturnSwapForm.value.reset_date_roll,
          "resetFreq": this.ReturnSwapForm.value.reset_frequency,
          // "resetHolidays": this.selectedItems1,
          "resetHolidays": JSON.stringify(this.selectedItems1),
          "resetOffSet": this.ReturnSwapForm.value.is_offest,
          "resetRollDay": 0,
          // "teminationDate": teminationdate + 'T00:00:00.000Z',
          "teminationDate": this.datePipe.transform(this.ReturnSwapForm.value.termination_date, "MM-dd-yyyy")
        }
      }
      this.recoveryService.insertReturnSwap(reqObj).subscribe(res => {
        console.log(res)
        if (res.status == 'ok') {
          this.matSnackBar.open('Total Return Swap added successfully. ID is ' + res.ID, 'OK', {
            verticalPosition: 'top',
            panelClass: 'snack-success'
          })
          this.fromGroupDirective.resetForm();
          this.feeForm.controls['feeAmount'].reset()
          this.dealForm.controls['clearingHouse'].reset()
          this.selected = 0;
        } else {
          this.matSnackBar.open(res.errors[0].defaultMessage, 'OK', {
            verticalPosition: 'top',
            duration: 2000,
            panelClass: 'snack-error'
          })
        }
      })
    } else {
      this.matSnackBar.open("Please Enter Mandatory Fields", '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'snack-error'
      })
    }
  }


  cancelBtn() {
    this.selected = 0;
    this.fromGroupDirective.resetForm();
    this.feeForm.controls['feeAmount'].reset()
    this.dealForm.controls['clearingHouse'].reset()
    // this.ngOnInit();
  }


  // onKeydown(evtd){
  //   console.log(evtd)
  //   setTimeout(() => {
  //     evtd.target.value = '-' + (parseInt(evtd.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')

  //   }, 100);
  // }


  alphakeyPress(ev) {
    if (ev.key == '-' || ev.target.value.charAt(0) == '-') {
      setTimeout(() => {
        ev.target.value = '-' + (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    } else {
      setTimeout(() => {
        ev.target.value = (parseInt(ev.target.value.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
      }, 100);
    }

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(ev.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      ev.preventDefault();
    }
  }

}
