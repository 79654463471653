import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addcurrency',
  templateUrl: './addcurrency.component.html',
  styleUrls: ['./addcurrency.component.css']
})
export class AddcurrencyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
